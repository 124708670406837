import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreator from "../../store/actions/authActions";
import Translate from "react-translate-component";
import counterpart from "counterpart";

class Authentification extends React.Component {
  _handleLogout = () => {
    this.props.logoutUser();
  };

  flash = (code) => {
    return counterpart.translate(code);
  };

  render() {
    const isConnected = localStorage.getItem("isConnected") === "true";
    const token = localStorage.getItem("access_token");
    const { user } = this.props;
    return (
      <>
        {isConnected && user && token && user.role === "user" && (
          <>
            <li data-title={this.flash("authentication.requests")}>
              <Link to="/user_panel/folder_requests">
                <i className="fa fa-paper-plane navigation-icon"></i>
                <Translate content="authentication.requests" className="navigation-text" />
              </Link>
            </li>
          </>
        )}
        {isConnected && user && token && user.role === "agency" && (
          <>
            <li data-title={this.flash("forms.agency.insights")}>
              <Link to="/agency/insights">
                <i className="fa fa-list navigation-icon"></i>
                <Translate content="forms.agency.insights" className="navigation-text" />
              </Link>
            </li>

            <li data-title={this.flash("navbar.account.folders")}>
              <Link to="/account/folders">
                <i className="fa fa-folder-open navigation-icon"></i>
                <Translate content="navbar.account.folders" className="navigation-text" />
              </Link>
            </li>

            <li data-title={this.flash("navbar.account.custom_files")}>
              <Link to="/account/custom_files">
                <i className="fa fa-file navigation-icon"></i>
                <Translate content="navbar.account.custom_files" className="navigation-text" />
              </Link>
            </li>

            <li data-title={this.flash("authentication.account")}>
              <Link to="/agency/Dashboard">
                <i className="fa fa-address-card navigation-icon"></i>
                <Translate content="authentication.account" className="navigation-text" />
              </Link>
            </li>

            <li data-title={this.flash("navbar.subscription")}>
              <Link to="/agency/subscriptions">
                <i className="fa fa-shopping-cart navigation-icon"></i>
                <Translate content="navbar.subscription" className="navigation-text" />
              </Link>
            </li>
          </>
        )}
        {isConnected && user && token && (
          <li data-title={this.flash("authentication.logout")}>
            <a onClick={this._handleLogout}>
              <i className="fa fa-sign-out navigation-icon"></i>
              <Translate content="authentication.logout" className="navigation-text" />
            </a>
          </li>
        )}
        {(!isConnected || !user || !token) && (
          <div>
            <li data-title={this.flash("authentication.signin")}>
              <Link to="/sign_in">
                <i className="fa fa-sign-in navigation-icon"></i>
                <Translate content="authentication.signin" className="navigation-text" />
              </Link>
            </li>

            <li data-title={this.flash("authentication.signup")}>
              <Link to="/sign_up">
                <i className="fa fa-user-plus navigation-icon"></i>
                <Translate content="authentication.signup" className="navigation-text" />
              </Link>
            </li>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(actionCreator.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentification);
