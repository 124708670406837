import React, { useState } from "react";
import useForm from "react-hook-form";
import Translate from "react-translate-component";
import counterpart from "counterpart";

function ForgotForm({ resetForgetenPassword, closeModal, forgotError }) {
  const { handleSubmit, register, errors, setError } = useForm();
  const [values, setValues] = useState({ email: "" });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = () => {
    resetForgetenPassword(values);
  };
  const placeholder = counterpart.translate("authentication.forgot.placeholder");

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 mb-2 py-5">
          <div className="flex-row">
            <label className="lf--label pb-3" htmlFor="email">
              <Translate content="authentication.forgot.label" />
            </label>
            <input
              id="email"
              className="lf--input"
              placeholder={placeholder}
              type="text"
              name="email"
              onChange={handleInputChange}
              ref={register({
                required: "forms.signin.validations.email_req",
                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "forms.signin.validations.email" },
              })}
            />
            {errors.email && <Translate content={errors.email.message} component="p" className="text-danger xs-text pt-1" />}
            {forgotError && <Translate content="authentication.forgot.error" component="p" className="text-danger xs-text pt-1" />}
          </div>
        </div>

        <div className="col-6 mb-2">
          <button className="direction float-right" type="submit">
            <Translate content="authentication.forgot.submit" />
          </button>
        </div>
        <div className="col-6 mb-2">
          <button className="direction float-right" onClick={() => closeModal()}>
            <Translate content="custom_files.modal.close" />
          </button>
        </div>
      </div>
    </form>
  );
}

export default ForgotForm;
