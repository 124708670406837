import React from "react";
import Moment from "moment";

function Conversations({ conversations, conversation, getConversation }) {
  const activeConversation = (conversationId) => {
    return conversation && conversation.conversationId === conversationId ? "message--active" : "";
  };
  const newConversation = (readen) => {
    return readen ? "" : "message--new";
  };
  return (
    <div className="scrollable__target">
      {conversations.map((conversation) => (
        <div
          className={`message ${newConversation(conversation.readen)} ${activeConversation(conversation.id)}`}
          key={conversation.id}
          onClick={() => getConversation(conversation.id)}
        >
          <div className="message-tags">
            {conversation.participants.map((p) => (
              <span key={p.id} className={`dot dot--${p.section}`}></span>
            ))}
          </div>
          <div className="message__actions">
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div className="message__content">
            <div className="message__exp">
              <div>{conversation.authorEmail}</div>
              <div className="date">{Moment(conversation.lastMessageDate).fromNow()}</div>
            </div>
            <div className="message__title">{conversation.subject}</div>
            <div className="message__expr">« {conversation.lastMessageBody} »</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Conversations;
