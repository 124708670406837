import React from "react";
import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import CardSection from "./cardSection";
import * as actionCreator from "../../../../../store/actions/stripeActions";
import Translate from "react-translate-component";
import counterpart from "counterpart";

class CheckoutForm extends React.Component {
  state = {
    name: "",
    promo: "",
    nameError: false,
  };
  handleSubmit = (ev) => {
    ev.preventDefault();
    const card = this.props.elements.getElement("card");
    if (this.state.name !== "") {
      this.props.createSubscription(this.props.stripe, this.props.subscription, card, this.state.name, this.state.promo);
    } else {
      this.setState({ nameError: true });
    }
  };

  changeinput = (e) => {
    var { name, value } = e.target;
    value = name === "promo" ? value.toLowerCase() : value;
    this.setState({ [name]: value });
    if (name === "name" && this.state.nameError) {
      this.setState({ nameError: false });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="col-sm-12 row text-center mt-4 mb-4">
          <div className="col-sm-12 text-center">
            <Translate content="subscription_form.title" component="h6" className="mb-4 text-center" />

            <input type="test" placeholder={counterpart.translate("subscription_form.name_placeholder")} name="name" className="MyCardElement" onChange={this.changeinput} />
            {this.state.nameError && <Translate content="subscription_form.name_error" component="h6" />}
          </div>
          <div className="col-sm-12 text-center ">
            <CardSection />
          </div>
          <div className="col-sm-12 text-center ">
            <input
              type="test"
              placeholder={counterpart.translate("subscription_form.promo_placeholder")}
              value={this.state.promo}
              name="promo"
              className="MyCardElement"
              onChange={this.changeinput}
            />
          </div>
          <div className="col-sm-12">
            <button className="card-bottom">
              <Translate content="subscription_form.confirm" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createSubscription: (stripe, subscription, card, name, promo) => dispatch(actionCreator.createSubscription(stripe, subscription, card, name, promo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(CheckoutForm));
