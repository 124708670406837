const initialState = { agencyCountry: false, folders: [], folder: false, loading: false, documentFile: false };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FOLDERS":
      return {
        ...state,
        folders: action.folders,
      };
    case "GET_FOLDER":
      return {
        ...state,
        folder: action.folder,
      };
    case "LOADING_DOCUMENT":
      return {
        ...state,
        loading: action.loading,
      };
    case "GENERATED_DOCUMENT":
      return {
        ...state,
        documentFile: action.documentFile,
      };
    case "SET_AGENCY_COUNTRY":
      return {
        ...state,
        agencyCountry: action.agencyCountry,
      };

    default:
      return state;
  }
};

export default authReducer;
