import React from "react";
import { Route, Redirect } from "react-router-dom";

const isConnected = localStorage.getItem('isConnected') === 'true';
const token = localStorage.getItem('access_token');
var user = JSON.parse( localStorage.getItem('user') );

export const UserRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isConnected && token && user.role === 'user') {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
