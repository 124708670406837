import { authService } from "../services/authService";
import { agencyService } from "../services/agencyService";
import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";
import history from "./history";

const flash = (name) => {
  return counterpart.translate("flash.auth." + name);
};

export function registerUser(values) {
  return (dispatch) => {
    authService.register(values).then(
      () => {
        dispatch(successRegister());
        dispatch(Notifications.info({ message: flash("registred"), position: "tc", title: flash("authTitle") }));
        dispatch(loginUser(values));
      },
      (error) => {
        dispatch(failureRegister(true));
      }
    );
  };
}

export function loginUser(values) {
  return (dispatch) => {
    authService.login(values).then(
      (response) => {
        localStorage.setItem("access_token", response.data.token);
        localStorage.setItem("isConnected", true);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        dispatch(success(response.data.user));
        dispatch(Notifications.info({ message: flash("connected"), position: "tc", title: flash("authTitle") }));

        if (response.data.user.role === "agency") {
          agencyService.getAgency().then((resp) => {
            if (resp.data.agency.id === 0) {
              history.push("/agency/dashboard");
              window.location.reload();
            } else {
              history.push("/agency/insights");
              window.location.reload();
            }
          });
        } else {
          history.push("/user_panel/folder_requests");
          window.location.reload();
        }
      },
      (error) => {
        dispatch(failure(true));
      }
    );
  };
}

export function resetForgetenPassword(values) {
  return (dispatch) => {
    authService.resetForgetenPassword(values).then(
      (response) => {
        dispatch(setForgotSuccess());
      },
      (error) => {
        if (error.response.data.message === "not_found") {
          dispatch(setForgotError());
        } else {
          dispatch(Notifications.error({ message: counterpart.translate("flash.error.general") }));
        }
      }
    );
  };
}

export function resetPassword(values) {
  return (dispatch) => {
    authService.resetPassword(values).then(
      (response) => {
        dispatch(Notifications.info({ message: flash("reset"), position: "tc" }));
        history.push("/sign_in");
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: counterpart.translate("flash.error.general") }));
      }
    );
  };
}

export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem("user");
    localStorage.removeItem("isConnected");
    localStorage.removeItem("access_token");

    dispatch(Notifications.info({ message: flash("disconected"), position: "tc", title: flash("authTitle") }));
    setTimeout(() => {
      window.location.reload();
    }, 750);
  };
}

export function success(user) {
  return {
    type: "LOGIN_SUCCESS",
    user,
  };
}
export function setForgotError() {
  return {
    type: "SET_FORGOT_ERROR",
  };
}
export function setForgotSuccess() {
  return {
    type: "SET_FORGOT_SUCCESS",
  };
}

export function failure(errors) {
  return {
    type: "LOGIN_FAILURE",
    errors,
  };
}

export function failureRegister(error) {
  return {
    type: "REGISTER_FAILURE",
    error,
  };
}

export function successRegister() {
  return {
    type: "REGISTER_SUCCESS",
  };
}
