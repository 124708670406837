import React from "react";
import Translate from 'react-translate-component';

function Navigation({activeTab, changeTab, children}) {

  return(
    <div>
      {
        children
      }
    </div>
  )
}

export default Navigation;
