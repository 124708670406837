import React from "react";
import { IbanElement } from "@stripe/react-stripe-js";
import "./IbanFormStyles.css";
import Translate from "react-translate-component";
import counterpart from "counterpart";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  placeholderCountry: "LU",
  style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled }) {
  const translator = (key) => {
    return counterpart.translate(key);
  };
  return (
    <form onSubmit={onSubmit} className="sepaForm">
      <div className="form-row inline mt-4">
        <div className="col-12 mb-4">
          {/* <Translate content="sepa.name" component="label" /> */}

          <input name="accountholder-name" placeholder={translator("sepa.name_placeholder")} required className="StripeElement" />
        </div>

        <div className="col-12 mb-4">
          {/* <Translate content="sepa.email" component="label" /> */}

          <input name="email" type="email" placeholder={translator("sepa.email_placeholder")} required className="StripeElement" />
        </div>
      </div>

      <div className="form-row ">
        <div className="col-12 mb-4">
          {/* <label>IBAN</label> */}

          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </div>
        <div className="col-12 mb-4">
          {/* <Translate content="sepa.promo" component="label" /> */}

          <input name="promo" type="text" placeholder={translator("sepa.promo_placeholder")} className="StripeElement" />
        </div>
      </div>

      <button type="submit" disabled={disabled} className="mb-4 sepaBtn">
        {translator("sepa.button")}
      </button>

      {/* Display mandate acceptance text. */}
      <Translate content="sepa.acceptance" component="div" className="mandate-acceptance" />
    </form>
  );
}
