import React from 'react';
import Router from './routes/routes';
import counterpart from 'counterpart';
import en from './locales/en';
import fr from './locales/fr';
import de from './locales/de';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('fr', fr);
counterpart.registerTranslations('de', de);

counterpart.setLocale(localStorage.getItem('lang')  || 'en');

function App() {
  return (   <Router />   );
}

export default App;
