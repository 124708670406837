import React from "react";
import Navigation from '../../../account/files/navigation';
import Tabs from '../../../account/files/tabs';
import FilesForm from '../../../account/files/files_form';
import Translate from 'react-translate-component';

import AccessAgencyMedia from '../access.agency.media';

class CustomFiles extends React.Component {

  state = {
    lang: 'fr'
  }

  changeTab = (lang) => {
    this.setState({lang: lang});
  }

  render(){
    return (
      <div className='Agency'>
        <div className='container custom-files'>
          <AccessAgencyMedia>

            <div className='row custom-box'>
              <div className='col-12'>
                <Translate content={`custom_files.types.${this.props.fileType}`} component="h5" className='mb-3'/>
              </div>


              <div className='col-12'>
                <Navigation >
                  <div className='navigation mb-5'>
                    <Tabs lang={this.state.lang} changeTab={this.changeTab}/>

                    { this.state.lang === 'fr' && <FilesForm lang='fr' fileType={this.props.fileType} />}
                    { this.state.lang === 'en' && <FilesForm lang='en' fileType={this.props.fileType} />}
                    { this.state.lang === 'de' && <FilesForm lang='de' fileType={this.props.fileType} />}
                  </div>
                </Navigation>

              </div>
            </div>
          </AccessAgencyMedia>
        </div>
      </div>
    );
  }
}

export default CustomFiles;
