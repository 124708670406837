import React from "react";

class Cgv extends React.Component {
  render() {
    return (
      <div className="cgv">
        <p>
          <strong>CONDITIONS G&Eacute;N&Eacute;RALES DE VENTE ET DE PRESTATION DES SERVICES</strong>
        </p>
        <p>
          Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente (CGV) r&eacute;gissent l&rsquo;ensemble des relations entre Linc SA
          (&laquo;&nbsp;l&rsquo;exploitant&nbsp;&raquo;) et le Client dans le cadre de l&rsquo;utilisation de la plateforme orea.immo. L&rsquo;exploitant se r&eacute;serve le droit
          de changer &agrave; tout moment les CGV ainsi que les conditions tarifaires futures, moyennant mise &agrave; jour sur le site Internet orea.immo (&laquo;&nbsp;le
          site&nbsp;&raquo; ou &laquo;&nbsp;la plateforme&nbsp;&raquo;). Les CGV en cours telles que communiqu&eacute;es &agrave; tout moment sur le site s&rsquo;appliquent
          valablement et automatiquement en cas de reconduction d&rsquo;un contrat et/ou pour toute nouvelle commande pass&eacute;e par le Client, m&ecirc;me si celui-ci n&rsquo;a
          pas resign&eacute; les CGV/CPV lors de la nouvelle commande. La nullit&eacute; de l&rsquo;une ou l&rsquo;autre clause n&rsquo;entraine en aucun cas
          l&rsquo;invalidit&eacute; g&eacute;n&eacute;rale des CGV. Sauf convention expresse contraire, les pr&eacute;sentes CGV pr&eacute;valent sur toutes autres dispositions
          convenues oralement ou &eacute;manant de documents du client, qui ne sont en principe pas opposable &agrave; l&rsquo;exploitant.
        </p>
        <p>
          <strong>DUREE DE VALIDITE ET RECONDUCTION</strong> - Chaque contrat d&rsquo;abonnement est conclu pour la dur&eacute;e initiale choisie par le client parmi les
          dur&eacute;es disponibles et est, &agrave; chaque &eacute;ch&eacute;ance, reconduit automatiquement et de mani&egrave;re tacite pour la m&ecirc;me dur&eacute;e et ce
          jusqu&rsquo;&agrave; modification ou r&eacute;siliation de l&rsquo;abonnement selon les dispositions en vigueur dans les pr&eacute;sentes CGV. La dur&eacute;e initiale
          prend effet le jour m&ecirc;me de la conclusion du contrat, c&agrave;d de la commande. Toute commande valid&eacute;e par le Client en ligne ou par messagerie
          &eacute;lectronique, fax ou signature &eacute;lectronique est valablement reconnue au m&ecirc;me titre qu&rsquo;une signature manuscrite et entraine implicitement
          l&rsquo;acceptation par le Client des CGV telles que communiqu&eacute;es en ligne sur le Support. L&rsquo;exploitant se r&eacute;serve le droit d&rsquo;annuler ou
          d&rsquo;interrompre tout contrat sans que le Client ne pourra pr&eacute;tendre &agrave; aucun d&eacute;dommagement quelconque, total ou partiel. Toute prestation
          command&eacute;e est payable dans son int&eacute;gralit&eacute;, m&ecirc;me si une ou plusieurs prestation(s) command&eacute;e(s) n&rsquo;ont pas &eacute;t&eacute;
          utilis&eacute;e(s).
        </p>
        <p>
          <strong>MODALITES DE RESILIATION</strong> - Toute commande est ferme et ne peut faire l&rsquo;objet d&rsquo;une annulation ni donner lieu &agrave; une quelconque
          compensation financi&egrave;re ou d&eacute;charge post&eacute;rieurement &agrave; sa signature. Le Client peut r&eacute;silier le contrat en ligne &agrave; tout moment
          via son acc&egrave;s professionnel &agrave; la plateforme. La r&eacute;siliation prendra automatiquement effet &agrave; la prochaine date d&rsquo;&eacute;ch&eacute;ance
          contractuelle. Linc se r&eacute;serve le droit de r&eacute;silier le contrat &agrave; tout moment en cas d&rsquo;impay&eacute;s 10 jours ouvrables apr&egrave;s mise en
          demeure rest&eacute;e infructueuse. Le client ne pourra dans ce cas pr&eacute;tendre &agrave; aucune indemnit&eacute; et devra proc&eacute;der au r&egrave;glement de
          l&rsquo;int&eacute;gralit&eacute; du solde restant d&ucirc;.
        </p>
        <p>
          <strong>MODIFICATION DU CONTRAT </strong>&ndash; Le client peut &agrave; tout moment modifier son type d&rsquo;abonnement. Une modification vers un abonnement
          sup&eacute;rieur prend effet le jour m&ecirc;me du paiement du prorata manquant pour couvrir la diff&eacute;rence, pour la p&eacute;riode concern&eacute;e, entre le
          contrat de base et le contrat sup&eacute;rieur. Une modification vers un abonnement inf&eacute;rieur prend effet &agrave; la prochaine date d&rsquo;&eacute;ch&eacute;ance
          du contrat en cours. Aucun remboursement ne sera dans ce cas octroy&eacute; au Client.
        </p>
        <p>
          <strong>ENGAGEMENTS DU CLIENT </strong>&ndash; Le Client s&rsquo;engage &agrave; utiliser les services fournis en bon p&egrave;re de famille et uniquement selon
          l&rsquo;utilit&eacute; et les fins pr&eacute;vues par le service. Il s&rsquo;engage &agrave; ne pas saisir de donn&eacute;es personnelles d&rsquo;utilisateurs dans la
          plateforme pour lesquelles il n&rsquo;aurait pas pr&eacute;alablement re&ccedil;u l&rsquo;autorisation respective expresse desdits utilisateurs, notamment eu &eacute;gard
          aux l&eacute;gislations relatives &agrave; la protection des donn&eacute;es priv&eacute;es et &agrave; la RGPD. Le client tient l&rsquo;exploitant quitte et indemne
          &agrave; cet &eacute;gard. Le Client d&eacute;clare avoir lu et accepter les Menstions L&eacute;gales et les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation telles
          que sp&eacute;cifi&eacute;es sur le site orea.immo, qui sont d&egrave;s lors pleinement opposables au client et r&eacute;put&eacute;es faire partie int&eacute;grante des
          CGV et accept&eacute;es par le Client.&nbsp;
        </p>
        <p>
          <strong>COMPTE CLIENT</strong> - Le Client dispose d&rsquo;un espace d&rsquo;administration d&eacute;di&eacute; &agrave; orea.immo. L&rsquo;acc&egrave;s &agrave; cet
          espace est s&eacute;curis&eacute; par un code d&rsquo;acc&egrave;s personnalis&eacute;. Le Client s&rsquo;engage &agrave; ne pas divulguer ses acc&egrave;s &agrave; des
          personnes tierces &agrave; son entreprise et &agrave; utilise l&rsquo;acc&egrave;s et les services sous sa propre responsabilit&eacute; et ses propres risques.
        </p>
        <p>
          <strong>TARIF, FACTURATION ET PAIEMENT</strong> - Tous les tarifs indiqu&eacute;s dans les offres sont en euros hors TVA &agrave; l&rsquo;indice des salaires en vigueur
          au moment de la conclusion du contrat. En cas d&rsquo;&eacute;volution de l&rsquo;indice, les tarifs sont adapt&eacute;s automatiquement &agrave; la prochaine
          &eacute;ch&eacute;ance contractuelle. Sauf en cas de paiement en ligne, toute facture est exigible dans son int&eacute;gralit&eacute; dans un d&eacute;lai de 30 jours
          calendrier &agrave; compter de sa date d&rsquo;&eacute;missions.
        </p>
        <p>
          <strong>D&Eacute;FAUT DE PAIEMENT</strong> - En cas de non-paiement dans les d&eacute;lais prescrits, la prestation peut &ecirc;tre mise en suspens en attendant la
          r&eacute;gularisation des montants exigibles. Des frais administratifs de recouvrement peuvent &ecirc;tre factur&eacute;s au client &agrave; concurrence de 15 euros htva
          &agrave; partir de l&rsquo;envoi d&rsquo;un deuxi&egrave;me rappel. Toute somme impay&eacute;e g&eacute;n&egrave;re automatiquement des int&eacute;r&ecirc;ts de retard au
          taux l&eacute;gal &agrave; compter de la date d&rsquo;envoi de la mise en demeure.
        </p>
        <p>
          <strong>CLAUSES LIMITATIVES DE RESPONSABILITE</strong> &ndash; L&rsquo;utilisation de l&rsquo;application n'est soumise &agrave; aucune garantie de la part de
          l&rsquo;exploitant. L&rsquo;exploitant ne peut &ecirc;tre tenu responsable pour d&rsquo;&eacute;ventuelles inaccessibilit&eacute;s partielles ou totales des Supports,
          pour la non-ex&eacute;cution de certaines prestations ou pour la perte ou le vol de donn&eacute;es saisies et stock&eacute;es par le client dans orea.immo.
          L&rsquo;exploitant ne peut &ecirc;tre tenu responsable de dommages directs ou indirects, ni de la perte de chance de r&eacute;aliser des b&eacute;n&eacute;fices en
          relation avec l&rsquo;ex&eacute;cution des prestations du contrat. Le Client tient Linc quitte et indemne en cas de revendications de tiers. L&rsquo;exploitant ne peut
          &ecirc;tre tenu responsable pour tout &eacute;v&egrave;nement de force majeure, ext&eacute;rieur, impr&eacute;visible ou irr&eacute;sistible comme de tout acte
          &eacute;manant d&rsquo;une autorit&eacute; civile ou militaire, de fait ou de droit de gr&egrave;ve, incendie, inondation, d&eacute;g&acirc;t des eaux, temp&ecirc;te et
          foudre, accident, &eacute;meute, attentat, cyber-attaque, de tout fait imputable &agrave; un tiers, ou autre circonstance ayant une cause externe l&rsquo;emp&ecirc;chant,
          directement ou indirectement de r&eacute;pondre &agrave; ses obligations. Malgr&eacute; les meilleurs efforts consacr&eacute;s, L&rsquo;exploitant ne peut garantir ni la
          confidentialit&eacute; des &eacute;changes, ni l'interception &eacute;ventuelle des informations &eacute;chang&eacute;es via le site ou contenues dans la base de
          donn&eacute;es. L&rsquo;exploitant consacre ses meilleurs efforts &agrave; assurer la qualit&eacute; juridique des diff&eacute;rents documents et mod&egrave;les de
          documents d&eacute;pos&eacute;s dans la plateforme et servant comme mod&egrave;les de base que le Client peut utiliser. L&rsquo;exploitant ne peut en aucun cas &ecirc;tre
          tenu responsable pour d&rsquo;&eacute;ventuelles erreurs, lacunes, imperfections ou quelconque autre d&eacute;faut de ces documents et/ou toute cons&eacute;quence que
          l&rsquo;utilisation de ces documents entraine pour le Client. Le Client est seul responsable des documents qu&rsquo;il utilise et reconna&icirc;t qu&rsquo;il a la
          possibilit&eacute; de remplacer chaque document fourni par la plateforme par ses propres documents personnalis&eacute;s. De m&ecirc;me, L&rsquo;exploitant ne peut pas
          &ecirc;tre tenu responsable pour quelconque avertissement, p&eacute;nalit&eacute; (&hellip;) inflig&eacute;e au Client par une quelconque administration ou
          autorit&eacute; dans le cadre de son utilisation de la plateforme. Le Client tient L&rsquo;exploitant quitte et indemne &agrave; tous ces &eacute;gards.
        </p>
        <p>
          <strong>PROTECTION DES DONNEES RELATIVES A LA VIE PRIVEE </strong>&ndash; L&rsquo;exploitant garantit au Client des droits d&rsquo;acc&egrave;s, d&rsquo;opposition et de
          rectification de ses informations au titre de la loi modifi&eacute;e du 02 ao&ucirc;t 2002 qui transpose en droit luxembourgeois la directive europ&eacute;enne relative
          &agrave; la protection des personnes &agrave; l&rsquo;&eacute;gard du traitement des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; la libre circulation
          des donn&eacute;es.
        </p>
        <p>
          Les dispositions relatives &agrave; la protection de la vie priv&eacute;e telles qu&rsquo;expos&eacute;es dans les conditions g&eacute;n&eacute;rales d&rsquo;utilisation
          du site orea.immo sont applicables &agrave; tout moment.
        </p>
        <p>
          <strong>RESOLUTION DES LITIGES ET LOI APPLICABLE </strong>&ndash; Les parties s&rsquo;accordent &agrave; trouver en premier lieu une solution &agrave; l&rsquo;amiable
          &agrave; tout litige &eacute;ventuel et en font leur affaire. Le pr&eacute;sent contrat ainsi que les CGV sont soumis au droit luxembourgeois. Toutes contestations
          susceptibles de na&icirc;tre entre l&rsquo;exploitant et le Client du chef de l&rsquo;ex&eacute;cution du pr&eacute;sent contrat seront soumises Tribunal de Commerce de
          Luxembourg, m&ecirc;me en cas de pluralit&eacute; de d&eacute;fendeurs ou d&rsquo;appel en garantie.
        </p>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default Cgv;
