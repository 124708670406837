import React from "react";
import { Route, Redirect } from "react-router-dom";

const isConnected = localStorage.getItem("isConnected") === "true";
const token = localStorage.getItem("access_token");

export const MailboxRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isConnected && token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
