import React from "react";
import SubscriptionsContainer from '../../agency/subscriptionsContainer';


class Subscriptions extends React.Component {

  render(){
    return (
      <div className='Agency'>
        <div className='container'>
          <div className='row'>
            <SubscriptionsContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default Subscriptions;
