import React from 'react';
import Translate from 'react-translate-component';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';


function Comparison(props) {
  return(
    <div className='row'>
      <div className='row col-12'>

        <div className='col-xs-12 col-sm-12 col-md-6'>
          <Translate content='homepage.comparison.before' component='h5' />
          <Fade cascade top >
          <ul className='negatif'>
              <Translate component='li' content='homepage.comparison.negatif.first' />
              <Translate component='div' className='sub' content='homepage.comparison.negatif.sub_first' />
              <Translate component='li' content='homepage.comparison.negatif.second' />
              <Translate component='div' className='sub' content='homepage.comparison.negatif.sub_second' />
              <Translate component='li' content='homepage.comparison.negatif.third' />
          </ul>
          </Fade>
        </div>

        <div className='col-xs-12 col-sm-12 col-md-6'>
          <Translate content='homepage.comparison.now' component='h5' />
          <Fade cascade top >
          <ul className='positif'>
              <Translate component='li' content='homepage.comparison.positif.first' />
              <Translate component='li' content='homepage.comparison.positif.second' />
              <Translate component='li' content='homepage.comparison.positif.third' />
              <Translate component='div' content='homepage.comparison.positif.more' />
          </ul>
          </Fade>
        </div>

        <div className='col-12 my-4 text-center'>
          <Translate className='placeholder' content='homepage.comparison.placeholder' />
        </div>

        <div className='offset-md-3 col-md-6 offset-sm-3 col-sm-6'>
          <div className='comparison-action'>
            <Link to='/' className='link'>
              <Translate content='homepage.comparison.action_call'/>
            </Link>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Comparison;
