import React from "react";
import { connect } from 'react-redux';
import AgencyForm from './forms/agencyForm';
import * as actionCreator from '../../store/actions/agencyActions';

class AgencyContainer extends React.Component {
  componentDidMount(){
    this.props.getAgency();
  }

  submitAgency = (values) => {
    const {agency, updateAgency, createAgency} = this.props;
    agency.id ? updateAgency(values) : createAgency(values);
  }

  render(){
    return (
      <div className='agency-container col-12'>
        {this.props.agency && <AgencyForm agency={this.props.agency} submitAgency={this.submitAgency}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agency: state.agencyReducer.agency,
});

const mapDispatchToProps = (dispatch) => ({
  getAgency: () => dispatch(actionCreator.getAgency()),

  createAgency: (values) => dispatch(actionCreator.createAgency(values)),
  updateAgency: (values) => dispatch(actionCreator.updateAgency(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyContainer);
