import React from 'react';
import Translate from 'react-translate-component';
import Zoom from 'react-reveal/Zoom';



function Advantages(props) {
  return(
    <div className='row'>
      <div className='col-12'>
        <Translate content='homepage.advantages.title' className='title' unsafe/>
        <div className="color_border"></div>
        <Translate content='homepage.advantages.placeholder' component='p' className='mt-3 mb-4'unsafe/>
      </div>

      <div className='row col-12'>

        <div className='col-sm-6 col-md-6'>
          <Translate content='homepage.advantages.sell' component='h5' />
          <Zoom cascade top >
          <ul>
              <Translate component='li' content='documents.types.pb' />
              <Translate component='li' content='documents.types.pf' />
              <Translate component='li' content='documents.types.prv' />
              <Translate component='li' content='documents.types.ara' />
              <Translate component='li' content='documents.types.c' />
              <Translate component='li' content='documents.types.an' />
              <Translate component='li' content='documents.types.f' />
          </ul>
          </Zoom>
        </div>

        <div className='col-sm-6 col-md-6'>
          <Translate content='homepage.advantages.rent' component='h5' />
          <Zoom cascade top >
          <ul>
              <Translate component='li' content='documents.types.pb' />
              <Translate component='li' content='documents.types.pf' />
              <Translate component='li' content='documents.types.prv' />
              <Translate component='li' content='documents.types.ara' />
              <Translate component='li' content='documents.types.c' />
              <Translate component='li' content='documents.types.an' />
              <Translate component='li' content='documents.types.f' />
          </ul>
          </Zoom>
        </div>

      </div>

    </div>
  )
}

export default Advantages;
