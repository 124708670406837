import React, { useState } from "react";
import File from "./file";
import AgencyFile from "./agency_file";
import Translate from "react-translate-component";

function CustomFiles({ lang, fileType }) {
  const [extend, setExtend] = useState(false);
  return (
    <div className="mb-5 nav-content">
      <div className="row">
        <File lang={lang} name="pb" fileType={fileType} />
        <File lang={lang} name="pf" fileType={fileType} />
        <File lang={lang} name="prv" fileType={fileType} />
        <File lang={lang} name="ara" fileType={fileType} />
        <File lang={lang} name="c" fileType={fileType} />
        {fileType === "sell" && <File lang={lang} name="an" fileType={fileType} />}
        <File lang={lang} name="f" fileType={fileType} />
        <File lang={lang} name="rgpdp" fileType={fileType} />
        <File lang={lang} name="rgpdal" fileType={fileType} />
      </div>

      <div className="row">
        <Translate
          content="custom_files.agency_files"
          className={`col-12 custom_file-title ${extend ? "extended" : "not-extended"}`}
          component="div"
          onClick={() => setExtend(!extend)}
        />
      </div>

      <div className={`row ${extend ? "extended-files" : "not-extended-files"}`}>
        <AgencyFile lang={lang} name="file_one" fileType={fileType} />
        <AgencyFile lang={lang} name="file_two" fileType={fileType} />
        <AgencyFile lang={lang} name="file_three" fileType={fileType} />
        <AgencyFile lang={lang} name="file_four" fileType={fileType} />
        <AgencyFile lang={lang} name="file_five" fileType={fileType} />
        <AgencyFile lang={lang} name="file_six" fileType={fileType} />
        <AgencyFile lang={lang} name="file_seven" fileType={fileType} />
      </div>
    </div>
  );
}

export default CustomFiles;
