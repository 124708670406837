import React from "react";
import Translate from "react-translate-component";
import DatePicker from "react-datepicker";
import counterpart from "counterpart";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext } from "react-hook-form";

function CustomInput({ agencyCountry, setRequirements, data, showLot, folderType, handleInputChange, setDate, prefix, value, conditionValue, index, docIndex, setValidation }) {
  const methods = useFormContext();

  const badge = (val) => {
    return val === 0 ? "feelfree" : "required";
  };
  const selectOption = (code) => {
    return (
      <option value={code} key={code}>
        {counterpart.translate("forms.folder.options." + code)}{" "}
      </option>
    );
  };

  const selectedDate = (value) => {
    if (value === "" || value === null) return "";

    var date = new Date(value);
    return date;
  };

  const sendDate = (value) => {
    if (value === "" || value === null) return "";
    var date = new Date(value.setHours(15));

    return date;
  };

  return (
    <>
      {data.countries.includes(agencyCountry) &&
        (!data.condition || (data.condition && data.conditionValue === conditionValue)) &&
        (data.name !== "propertyLot" || (data.name === "propertyLot" && showLot)) && (
          <>
            <div className="custom-input">
              <div className="input">
                <Translate content={`forms.folder.labels.${data.name}${data.labelType ? `.${folderType}` : ""}`} className="label" />
                {(data.type === "text" || data.type === "number") && (
                  <input
                    onFocus={() => setRequirements(data.requirements)}
                    type={data.type}
                    step="0.01"
                    name={data.name}
                    ref={methods.register({ required: data.required })}
                    onChange={handleInputChange}
                    defaultValue={value}
                  />
                )}
                {data.type === "select" && (
                  <select
                    onFocus={() => setRequirements(data.requirements)}
                    name={data.name}
                    className="select"
                    defaultValue={value}
                    ref={methods.register({ required: data.required })}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      {" "}
                    </option>
                    {data.choices.map((choice) => selectOption(choice.code))}
                  </select>
                )}
                {data.type === "radio" && (
                  <div className="radio">
                    {data.choices.map((choice) => (
                      <label className="radio-container" key={choice.code} htmlFor={`${data.name}-${index}-${choice.code}`}>
                        <Translate content={`forms.folder.options.${choice.code}`} />
                        <input
                          type="radio"
                          name={data.name}
                          defaultValue={choice.code}
                          defaultChecked={choice.code === value}
                          ref={methods.register({ required: data.required })}
                          onChange={handleInputChange}
                          id={`${data.name}-${index}-${choice.code}`}
                        />
                        <span className={`checkmark ${choice.code === value ? "checked" : "unchecked"}`}></span>
                      </label>
                    ))}
                  </div>
                )}
                {data.type === "date" && (
                  <DatePicker
                    onFocus={() => setRequirements(data.requirements)}
                    onChange={(date) => setDate(sendDate(date), data.name, prefix, index)}
                    dateFormat={["dd-MM-yyyy", "dd.MM.yyyy", "dd/MM/yyyy"]}
                    selected={selectedDate(value)}
                    showYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    dropdownMode="select"
                  />
                )}
                {data.requirements.length > 0 && (
                  <div className="requirements">
                    <div>
                      <Translate className={`badge ${badge(data.requirements[0])}`} content={`badge.${badge(data.requirements[0])}`} />
                      <Translate content="documents.types.pb" />
                    </div>
                    <div>
                      <Translate className={`badge ${badge(data.requirements[1])}`} content={`badge.${badge(data.requirements[1])}`} />
                      <Translate content="documents.types.pf" />
                    </div>

                    <div>
                      <Translate className={`badge ${badge(data.requirements[2])}`} content={`badge.${badge(data.requirements[2])}`} />
                      <Translate content="documents.types.prv" />
                    </div>

                    <div>
                      <Translate className={`badge ${badge(data.requirements[3])}`} content={`badge.${badge(data.requirements[3])}`} />
                      <Translate content="documents.types.ara" />
                    </div>
                    {folderType === "sell" && (
                      <div>
                        <Translate className={`badge ${badge(data.requirements[4])}`} content={`badge.${badge(data.requirements[4])}`} />
                        <Translate content="documents.types.c" />
                      </div>
                    )}
                    <div>
                      <Translate className={`badge ${badge(data.requirements[5])}`} content={`badge.${badge(data.requirements[5])}`} />
                      <Translate content="documents.types.an" />
                    </div>
                    <div>
                      <Translate className={`badge ${badge(data.requirements[6])}`} content={`badge.${badge(data.requirements[6])}`} />
                      <Translate content="documents.types.f" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {docIndex > -1 && data.requirements[docIndex] === 2 && value === "" && (
              <div className="c-validation">
                <Translate content="forms.folder.errors.required" />
              </div>
            )}
            {docIndex > -1 && data.requirements[docIndex] === 2 && value === "" && setValidation()}
          </>
        )}
    </>
  );
}

export default CustomInput;
