import React from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/actions/agencyActions";
import * as stripeCreator from "../../store/actions/stripeActions";
import Translate from "react-translate-component";
import SubscriptionsForm from "./forms/subscriptionsForm";
import AgencyOrders from "./tables/agencyOrders";
import UpdateCurrentOrder from "./subscription/updateCurrentOrder";
import AccessAgency from "../layouts/account/access.agency";

class SubscriptionsContainer extends React.Component {
  componentDidMount() {
    this.props.getAgency();
    this.props.getAgencyOrders();
    this.props.getActiveOrder();
    this.props.getCurrentOrder();
    this.props.getPublicKey();
  }

  upgradeSubscription = (with_media) => {
    this.props.upgradeSubscription(with_media);
  };

  render() {
    return (
      <div className="agency-container subscription-container col-12">
        <AccessAgency>
          {this.props.agencyOrders.length > 0 && (
            <>
              <Translate content="subscriptions.title" component="h3" />
              <AgencyOrders
                orders={this.props.agencyOrders}
                reactivate={this.props.reactivate}
                endSubscription={this.props.endSubscription}
                loadingUnsubscribe={this.props.loadingUnsubscribe}
              />
            </>
          )}
          {!this.props.activeOrder && (
            <>
              <Translate content="subscriptions.new" component="h3" />
              <SubscriptionsForm agencyPanel={true} publicKey={this.props.publicKey} />
            </>
          )}

          {this.props.activeOrder && this.props.currentOrder && (!this.props.currentOrder.sell || !this.props.currentOrder.rent || !this.props.currentOrder.media) && (
            <div className="mt-5">
              <Translate content="subscriptions.upgrade" component="h3" className="mb-3" />
              <div className="row">
                {(!this.props.currentOrder.sell || !this.props.currentOrder.rent) && <UpdateCurrentOrder upgradeSubscription={this.upgradeSubscription} subscription="sell_rent" />}
                {!this.props.currentOrder.media && <UpdateCurrentOrder upgradeSubscription={this.upgradeSubscription} subscription="sell_rent_media" />}
              </div>
            </div>
          )}
        </AccessAgency>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agency: state.agencyReducer.agency,
  agencyOrders: state.agencyReducer.agencyOrders,
  activeOrder: state.agencyReducer.activeOrder,
  currentOrder: state.agencyReducer.currentOrder,
  loadingUnsubscribe: state.agencyReducer.loadingUnsubscribe,
  publicKey: state.agencyReducer.publicKey,
});

const mapDispatchToProps = (dispatch) => ({
  getAgency: () => dispatch(actionCreator.getAgency()),
  getAgencyOrders: () => dispatch(actionCreator.getAgencyOrders()),
  upgradeSubscription: (with_media) => dispatch(actionCreator.upgradeSubscription(with_media)),

  getPublicKey: () => dispatch(stripeCreator.getPublicKey()),

  getActiveOrder: () => dispatch(actionCreator.getActiveOrder()),
  getCurrentOrder: () => dispatch(actionCreator.getCurrentOrder()),
  endSubscription: (id) => dispatch(actionCreator.endSubscription(id)),
  reactivate: (id) => dispatch(actionCreator.reactivate(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsContainer);
