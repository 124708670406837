import React from "react";
import { Route, Redirect } from "react-router-dom";

const isConnected = localStorage.getItem("isConnected") === "true";
const token = localStorage.getItem("access_token");
var user = JSON.parse(localStorage.getItem("user"));

export const HpRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isConnected && token && user.role === "agency") {
          return (
            <Redirect
              to={{
                pathname: "/account/folders",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else if (isConnected && token && user.role === "user") {
          return (
            <Redirect
              to={{
                pathname: "/user_panel/folder_requests",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
