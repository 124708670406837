import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/actions/authActions";
import SignInForm from "./forms/sign_in_form";
import ForgotForm from "./forms/forgot_form";
import Translate from "react-translate-component";
import { Modal } from "react-bootstrap";

class SignIn extends React.Component {
  state = {
    showModal: false,
  };
  submitLogIn = (values) => {
    this.props.loginUser(values);
  };
  render() {
    return (
      <div className="authentification">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <Translate content="authentication.signin_title" className="auth-title mt-3 mb-2" component="div" />
            <div className="blue-line" />
            <SignInForm submitLogIn={this.submitLogIn} errorLogin={this.props.errorLogin} openModal={() => this.setState({ showModal: true })} />
            <Link to="/sign_up" className="lf--forgot" href="#">
              <Translate content="redirect.to_signup_txt" className="redirect-text" />
              <Translate content="redirect.to_signup" />
            </Link>
          </div>

          <div className="col-md-6 col-sm-12 bg-auth">
            <div className="img signin"></div>
            <div className="img-bg"></div>
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content="authentication.forgot.link" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.props.forgotSuccess && (
              <ForgotForm
                closeModal={() => this.setState({ showModal: false })}
                resetForgetenPassword={(vals) => this.props.resetForgetenPassword(vals)}
                forgotError={this.props.forgotError}
              />
            )}
            {this.props.forgotSuccess && <Translate content="authentication.forgot.success" component="p" className="text-success xs-text py-5" />}
            {this.props.forgotSuccess && (
              <div className="col-6 mb-2">
                <button className="direction float-right" onClick={() => this.setState({ showModal: false })}>
                  <Translate content="subscriptions.modal.close" />
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorLogin: state.authReducer.errorLogin,
  forgotError: state.authReducer.forgotError,
  forgotSuccess: state.authReducer.forgotSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (values) => dispatch(actionCreator.loginUser(values)),
  resetForgetenPassword: (values) => dispatch(actionCreator.resetForgetenPassword(values)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
