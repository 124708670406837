const initialState = {
  publicKey: false,
  agency: false,
  agencyOrders: false,
  activeOrder: false,
  currentOrder: false,
  loadingOrder: false,
  loadingAgency: false,
  loadingUnsubscribe: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AGENCY":
      return {
        ...state,
        agency: action.agency,
      };
    case "LOADING_AGENCY":
      return {
        ...state,
        loadingAgency: action.loadingAgency,
      };
    case "LOADING_UNSUBCRIBE":
      return {
        ...state,
        loadingUnsubscribe: action.loadingUnsubscribe,
      };
    case "GET_AGENCY_ORDERS":
      return {
        ...state,
        agencyOrders: action.agencyOrders,
      };
    case "GET_ACTIVE_ORDER":
      return {
        ...state,
        activeOrder: action.activeOrder,
      };
    case "GET_CURRENT_ORDER":
      return {
        ...state,
        currentOrder: action.currentOrder,
      };
    case "LOADING_CURRENT_ORDER":
      return {
        ...state,
        loadingOrder: action.loadingOrder,
      };
    case "PUBLIC_KEY":
      return {
        ...state,
        publicKey: action.publicKey,
      };

    default:
      return state;
  }
};

export default authReducer;
