const initialState = {loading: false}

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case "ACTIVATE_LOADING":
      return {
        ...state,
        loading: true
      }

      case "DESACTIVATE_LOADING":
        return {
          ...state,
          loading: false
        }

    default:
      return state
  }
};

export default authReducer;
