import React, {useState} from "react";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';

function Header(props) {
  const [collapseMenu, setCollapseMenu] = useState(false);

  const collapseMenuKlass = () => {
    return collapseMenu ? 'show' : ''
  }
  const toggleCollapseMenu = () => {
    setCollapseMenu(!collapseMenu)
  }

  const handleChange = (lang) => {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
  return(
    <div className='Header'>
      <nav className="navbar navbar-expand-lg navbar-dark no-shadow">
          <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={() => props.changeSideBar()}>
            <i className="fa fa-bars"></i>
          </button>

          <div className={`collapse navbar-collapse`}  id="navbarSupportedContent-4">
              <ul className="navbar-nav ml-auto">

                  <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle country-flag" onClick={toggleCollapseMenu}>
                        <img src={`https://www.countryflags.io/${counterpart.getLocale() === 'en' ? 'us' : counterpart.getLocale()}/shiny/64.png`} alt="flag" />
                        <Translate content={`languages.${counterpart.getLocale()}`} />
                      </a>
                      <div className={`dropdown-menu ${collapseMenuKlass()}`} aria-labelledby="navbarDropdown">
                          <div className='dropdown-item lang-menu' onClick={() => handleChange('fr')}>
                            <div>
                              <img src="https://www.countryflags.io/fr/shiny/64.png" alt="flag" />
                              <Translate content="languages.fr" />
                            </div>
                          </div>
                          <div className='dropdown-item lang-menu' onClick={() => handleChange('en')}>
                            <div>
                              <img src="https://www.countryflags.io/us/shiny/64.png" alt="flag" />
                              <Translate content="languages.en" />
                            </div>
                          </div>
                          <div className='dropdown-item lang-menu' onClick={() => handleChange('de')}>
                            <div>
                              <img src="https://www.countryflags.io/de/shiny/64.png" alt="flag" />
                              <Translate content="languages.de" />
                            </div>
                          </div>
                      </div>

                  </li>

              </ul>
          </div>
      </nav>
    </div>
  )
}

export default Header;
