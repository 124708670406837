import React, {useState} from "react";
import useForm from 'react-hook-form';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';

function ResetPasswordForm({submitReset, reset_token}) {
  const { handleSubmit, register, errors, setError } = useForm();
  const [values, setValues] = useState({password: '', reset_token: reset_token});
  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const onSubmit = () => {
    submitReset(values)
  }
  const ph_password = counterpart.translate('forms.reset.placeholders.password');
  const ph_password_confirmation = counterpart.translate('forms.reset.placeholders.password_confirmation');

  return(
    <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-row">
            <label className="lf--label" htmlFor="email">
              <svg x="0px" y="0px" width="15px" height="5px">
                <path fill="#B1B7C4" d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"/>
              </svg>
            </label>
            <input className='lf--input' type='text' defaultValue={`Reset Token : ${reset_token}`} disabled/>
          </div>

          <div className="flex-row mt-4">
            <label className="lf--label" htmlFor="password">
              <svg x="0px" y="0px" width="15px" height="5px">
                <g>
                  <path fill="#B1B7C4" d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"/>
                </g>
              </svg>
            </label>
            <input id="password" className='lf--input' placeholder={ph_password} type='password'
                   name='password' onChange={handleInputChange}
                   ref={register({ required: 'forms.signup.validations.password_req',
                                   pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&*/.])(?=.{6,})/,
                                    message: "forms.signup.validations.password"
                                  }
            })}/>
          </div>
          {errors.password && <Translate content={errors.password.message} component='p' className='text-warning xs-text' />}

          <div className="flex-row mt-4">
            <label className="lf--label" htmlFor="password-confirmation">
              <svg x="0px" y="0px" width="15px" height="5px">
                <g>
                  <path fill="#B1B7C4" d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"/>
                </g>
              </svg>
            </label>
            <input id="password-confirmation" className='lf--input' placeholder={ph_password_confirmation} type='password'
                   name='passwordConfirmation' ref={register({ required: 'forms.signup.validations.password_confirmation_req' })}
                   onChange={async e => {
                    const value = e.target.value;
                    if (value === values.password) {
                      setError("passwordConfirmation");
                    } else {
                      setError(
                        "passwordConfirmation",
                        "notMatch",
                        "forms.signup.validations.confirmation"
                      );
                    }
                  }}
                    />
          </div>
          {errors.passwordConfirmation && <Translate content={errors.passwordConfirmation.message} component='p' className='text-warning xs-text' />}

          <Translate component='input' attributes={{value: 'forms.reset.submit'}} type='submit'  className='lf--submit mt-4' />

    </form>
  )
}

export default ResetPasswordForm;
