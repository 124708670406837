import React from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import useAxios from "axios-hooks";
import IbanForm from "./IbanForm";
import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";
import Loader from "react-loader-spinner";

const flash = (code) => {
  return counterpart.translate(code);
};

export default function PaymentSetupForm({ subscription }) {
  const stripe = useStripe();
  const elements = useElements();

  const [{ data, loading }, fetchClientSecret] = useAxios(
    {
      url: "/subscriptions/clientSecret",
      method: "GET",
    },
    { manual: true }
  );
  const [{ sepaData, sepaLoading }, sepaSubscription] = useAxios(
    {
      url: "/subscriptions/sepa_subscribe",
      method: "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const iban = elements.getElement(IbanElement);
    const accountholderName = event.target["accountholder-name"];
    const email = event.target.email;
    const promo = event.target.promo;

    fetchClientSecret().then(async (response) => {
      const { customer, clientSecret } = response.data.sepaIntent;

      const result = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: accountholderName.value,
            email: email.value,
          },
        },
      });

      if (result.error) {
        Notifications.error({ message: counterpart.translate("flash.error.general") });
      } else {
        sepaSubscription({
          data: {
            customer: customer,
            paymentMethod: result.setupIntent.payment_method,
            promo: promo.value,
            orderName: subscription.code,
          },
        }).then(
          (response) => {
            window.location.reload();
          },
          (error) => {
            Notifications.error({ message: counterpart.translate("flash.error.general") });
          }
        );
      }
    });
  };

  return (
    <>
      <IbanForm onSubmit={handleSubmit} disabled={!stripe} />
      <Loader className="subscriptionLoader" type="Grid" color="#fff" height={100} width={100} visible={loading === true} />
      <Loader className="subscriptionLoader" type="Grid" color="#fff" height={100} width={100} visible={sepaLoading === true} />
    </>
  );
}
