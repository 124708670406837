import React from "react";
import Translate from "react-translate-component";

function ActionButtons({ prevTab, nextTab, changeTab, edit, onSubmit }) {
  const changeTabUrl = (tab) => {
    edit && onSubmit();
    !edit && changeTab(tab);
    window.location.hash = tab;
  };

  return (
    <div>
      <div className="row mt-5 direction-container">
        <div className="col-6"></div>

        {prevTab && (
          <div className="col-md-3 offset-md-9 col-sm-6 offset-md-6 no-padding">
            <div className="direction" onClick={() => changeTabUrl(prevTab)}>
              <Translate content="forms.folder.buttons.prev" unsafe />
            </div>
          </div>
        )}

        {nextTab && (
          <div className="col-md-3 offset-md-9 col-sm-6 offset-md-6 no-padding">
            <div className="direction" onClick={() => changeTabUrl(nextTab)}>
              <Translate content="forms.folder.buttons.next" unsafe />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionButtons;
