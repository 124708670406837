import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentSetupForm from "./PaymentSetupForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function Sepa({ apiKey, subscription }) {
  const stripePromise = loadStripe(apiKey);

  return (
    <Elements stripe={stripePromise}>
      <PaymentSetupForm subscription={subscription} />
    </Elements>
  );
}
