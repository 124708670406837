import React from "react";
import Translate from "react-translate-component";

class DownloadDocument extends React.Component {
  render() {
    const { documentFile, docIndex, type } = this.props;
    const fileName = {
      sell: {
        0: "provenance_du_bien.docx",
        1: "provenance_des_fonds.docx",
        2: "analyse_risque_vendeur.docx",
        3: "analyse_risque_acheteur.docx",
        4: "compromis.docx",
        5: "attestation_notaire.docx",
        6: "facture.xlsx",
        7: "Formulaire_RGPD_proprietaire.docx",
        8: "Formulaire_RGPD_acheteur.docx",
        9: "file_one.docx",
        10: "file_two.docx",
        11: "file_three.docx",
        12: "file_four.docx",
        13: "file_five.docx",
        14: "file_six.docx",
        15: "file_seven.docx",
      },
      rent: {
        0: "provenance_du_bien.docx",
        1: "provenance_des_fonds.docx",
        2: "analyse_risque_proprietaire.docx",
        3: "analyse_risque_locataire.docx",
        4: "bail.docx",
        5: "attestation_notaire.docx",
        6: "facture.xlsx",
        7: "Formulaire_RGPD_proprietaire.docx",
        8: "Formulaire_RGPD_locataire.docx",
        9: "file_one.docx",
        10: "file_two.docx",
        11: "file_three.docx",
        12: "file_four.docx",
        13: "file_five.docx",
        14: "file_six.docx",
        15: "file_seven.docx",
      },
    };
    return (
      <div className="row mb-4">
        <div className="col-md-3 col-sm-4 col-xs-6">
          {
            <a className="download-btn" href={window.URL.createObjectURL(new Blob([documentFile]))} download={fileName[type][docIndex]}>
              <i className="fa fa-download"></i>
              <Translate content="forms.folder.download.title" />
            </a>
          }
        </div>
      </div>
    );
  }
}

export default DownloadDocument;
