const { normalizeUnits } = require("moment");

var lang = {
  frensh: {
    single_digits: ["zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"],
    teens: ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"],
    double_digits: ["zéro", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"],
    crore_lakhs: ["cent", "mille", "lakh", "crore"],
    million_billions: ["cent", "mille", "million", "billion", "billion", "quadrillion"],
    and_currency: ["et", "roupies", "paise", "point"],
    special: {
      71: "soixante-et-onze",
      72: "soixante-douze",
      73: "soixante-treize",
      74: "soixante-quatorze",
      75: "soixante-quinze",
      76: "soixante-seize",
      77: "soixante-dix-sept",
      78: "soixante-dix-huit",
      79: "soixante-dix-neuf",
      91: "quatre-vingt-onze",
      92: "quatre-vingt-douze",
      93: "quatre-vingt-treize",
      94: "quatre-vingt-quatorze",
      95: "quatre-vingt-quinze",
      96: "quatre-vingt-seize",
      97: "quatre-vingt-dix-sept",
      98: "quatre-vingt-dix-huit",
      99: "quatre-vingt-dix-neuf",
    },
  },
};
function between(x, min, max) {
  return x >= min && x <= max;
}
var millonComma = (cNumber) => {
  // extract decimal
  cNumber = Math.abs(cNumber); //conver to positive number
  var stDecimal = (cNumber - Math.floor(cNumber)).toFixed(2);
  stDecimal = stDecimal.substr(1, stDecimal.length); //remove zero be
  var stTruncatedNumber = Math.floor(cNumber) + stDecimal;
  var stWholeNumber = Math.floor(cNumber).toString();
  var lastThree = stWholeNumber.substr(stWholeNumber.length - 3);
  var otherNumbers = stWholeNumber.substring(0, stWholeNumber.length - 3);
  var strCommaNumber = "";

  if (otherNumbers != "") {
    lastThree = "," + lastThree;
    strCommaNumber = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
  } else {
    strCommaNumber = stWholeNumber;
  }
  return strCommaNumber + stDecimal;
};
var wordHundred = (hunNumber, lan) => {
  /*
    this function returns the number from 999 to 1 in the form of hundredWords
          this is boiler plate for higher number
  */

  var lNumber = hunNumber;
  var tNumber = 0;
  var returnWord = "";
  if (hunNumber > 999) {
    throw "hundreds more than 999 not allowed";
  }

  if (lNumber > 99) {
    tNumber = Math.floor(lNumber / 100);

    var pre = tNumber === 1 ? "" : lang[lan]["single_digits"][tNumber];
    returnWord = pre + " " + lang[lan]["crore_lakhs"][0] + (tNumber > 1 ? "s" : "");

    lNumber = lNumber - tNumber * 100;

    if (lNumber == 0) {
      return returnWord;
    }
  }

  if (lNumber >= 20 && !between(lNumber, 71, 79) && !between(lNumber, 91, 99)) {
    tNumber = Math.floor(lNumber / 10);

    returnWord += " " + lang[lan]["double_digits"][tNumber];

    var before = lNumber;
    lNumber = lNumber - tNumber * 10;
    if (lNumber == 0) {
      if ([10, 20, 30, 40, 50, 60, 70, 90].includes(before)) {
        return returnWord;
      } else return returnWord + "s";
    }
  }

  if (lNumber >= 20 && (between(lNumber, 71, 79) || between(lNumber, 91, 99))) {
    console.log("hun", hunNumber);
    console.log("lnum", lNumber);

    tNumber = Math.floor(lNumber / 10);

    console.log("tnum", tNumber);

    returnWord += " " + lang[lan]["special"][lNumber];

    return returnWord;
  }

  if (lNumber >= 10) {
    tNumber = lNumber - 10;
    returnWord += " " + lang[lan]["teens"][tNumber];
    if (lNumber == 0) {
      return returnWord;
    }
  }

  if (lNumber >= 0 && lNumber < 10) {
    if (lNumber === 1 && hunNumber > 1 && ![81, 101, 201, 301, 401, 501, 601, 701, 801, 901].includes(hunNumber)) {
      returnWord += " et " + lang[lan]["single_digits"][lNumber];
    } else {
      returnWord += " " + lang[lan]["single_digits"][lNumber];
    }
  }

  return returnWord;
};

var millionWord = (cNumber, lan = "frensh") => {
  var letters = numberToWord(cNumber, lan, "million");
  letters = letters.substring(0, letters.length - 1);
  return letters.replace(/ /g, "-");
};

module.exports.millionWord = millionWord;

var numberToWord = (cNumber, lan = "frensh", lakhOrMillion = "lakh") => {
  var lakhMillion = "";
  var commaNumber = "";

  if (lakhOrMillion == "million") {
    if (cNumber > 999999999999998) {
      throw cNumber + " number is too large to handle";
    }
    lakhMillion = "million_billions";
    commaNumber = millonComma(cNumber);
  } else {
    throw "Invalid lakh or million argument";
  }
  var curArray = commaNumber.split(",");
  curArray = curArray.reverse();

  var firstItem = curArray[0];
  var stDecimal = firstItem.substr(firstItem.length - 3, firstItem.length);
  curArray[0] = firstItem.substr(0, firstItem.length - 3);

  var curArrayLength = curArray.length;
  var lanArray = lang[lan][lakhMillion];
  var lanArrayLength = lanArray.length;

  var returnArray = [];
  var returnWholeNumber = "";
  var words = [];

  for (let i = 0; i < curArrayLength; i++) {
    // code for words
    if (parseInt(curArray[i]) === 0) {
      words[i] = "";
    } else {
      if (parseInt(curArray[i]) === 1 && lanArray[i] === "mille") {
        words[i] = lanArray[i];
      } else {
        words[i] = wordHundred(parseInt(curArray[i]), lan) + (i === 0 ? "" : " " + lanArray[i]);
      }
    }
  }

  words = words.reverse();

  for (let j = 0; j < words.length; j++) {
    returnWholeNumber += words[j] + " ";
  }

  returnWholeNumber = returnWholeNumber.trim();

  var returnDecimal = "";
  var returnDecimalCurrency = "";
  var returnDecimalClassic = "";
  if (stDecimal != ".00") {
    var firtDecimal = lang[lan]["single_digits"][parseInt(stDecimal.substr(1, 1))];
    var secondDecimal = lang[lan]["single_digits"][parseInt(stDecimal.substr(2, 2))];

    returnDecimalClassic = lang[lan]["and_currency"][3] + " " + firtDecimal + " " + secondDecimal;

    if (stDecimal.substr(1, 1) === "0") {
      returnDecimal = returnDecimalClassic;
      returnDecimalCurrency = returnDecimalClassic + " " + lang[lan]["and_currency"][2];
    } else {
      returnDecimal = lang[lan]["and_currency"][0] + " " + wordHundred(parseInt(stDecimal.substr(1, 2)), lan).trim();

      returnDecimalCurrency = lang[lan]["and_currency"][0] + " " + wordHundred(parseInt(stDecimal.substr(1, 2)), lan).trim() + " " + lang[lan]["and_currency"][2];
    }
  }

  returnArray[0] = returnWholeNumber.trim() + " " + returnDecimal.trim();
  returnArray[1] = ((lang[lan]["and_currency"][1] + " " + returnWholeNumber.trim()).trim() + " " + returnDecimalCurrency.trim()).trim();
  returnArray[2] = (returnWholeNumber.trim() + " " + returnDecimalClassic.trim()).trim();

  return returnWholeNumber.trim() + " " + returnDecimal.trim();
};
