import React from "react";
import { connect } from "react-redux";
import Header from "../../shared/header";
import EditSellContainer from "../../../components/account/sell/editSellContainer";
import AccessAgency from "./access.agency";

import * as actionCreator from "../../../store/actions/accountActions";

class EditSell extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id.split("-")[0];
    this.props.getFolder(id);
    this.props.getAgencyCountry();
  }

  render() {
    return (
      <div className="Agency">
        <div className="container agency-sell">
          <AccessAgency>{this.props.folder && <EditSellContainer initValues={this.props.folder} agencyCountry={this.props.agencyCountry} />}</AccessAgency>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  folder: state.accountReducer.folder,
  agencyCountry: state.accountReducer.agencyCountry,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (id) => dispatch(actionCreator.getFolder(id)),
  getAgencyCountry: () => dispatch(actionCreator.getAgencyCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSell);
