import React, { useState } from "react";
import Loader from "../components/shared/loader";

import HomePage from "../components/layouts/frontend/homepage";
import Cgu from "../components/layouts/frontend/cgu";
import Cgv from "../components/layouts/frontend/cgv";

import AgencyDashboard from "../components/layouts/agency/dashboard";
import AgencySubscriptions from "../components/layouts/agency/subscriptions";
import AgencyInsights from "../components/layouts/agency/insights";

import AccountFolders from "../components/layouts/account/folders";
import AccountNewSell from "../components/layouts/account/newSell";
import AccountEditSell from "../components/layouts/account/editSell";
import AccountNewRent from "../components/layouts/account/newRent";
import AccountEditRent from "../components/layouts/account/editRent";

import AccountMailBox from "../components/layouts/mailbox/mailBox";

import FilesTypes from "../components/layouts/account/customFiles/filesType";
import CustomSellFiles from "../components/layouts/account/customFiles/customSellFiles";
import CustomRentFiles from "../components/layouts/account/customFiles/customRentFiles";

import Footer from "../components/shared/footer";
import Flash from "../components/shared/flash";
import Header from "../components/shared/header";
import SideBar from "../components/shared/sidebar";

import SignIn from "../components/layouts/authentification/sign_in";
import SignUp from "../components/layouts/authentification/sign_up";
import ResetPassword from "../components/layouts/authentification/reset_password";

import FolderRequests from "../components/layouts/user_panel/folder_requests";
import FolderRequest from "../components/layouts/user_panel/folder_request";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AgengyRoute } from "./agency.routes";
import { MailboxRoute } from "./mailbox.routes";
import { UserRoute } from "./user.routes";
import { AuthRoute } from "./auth.routes";
import { HpRoute } from "./hp.routes";

function Router(props) {
  const [extendSideBar, setExtendSideBar] = useState(window.innerWidth > 1500);
  const changeSideBar = () => {
    setExtendSideBar(!extendSideBar);
  };

  return (
    <BrowserRouter>
      <Flash />
      <Loader />

      <div className="content">
        <div className={`left-content ${extendSideBar ? "extend" : ""}`}>
          <SideBar />
        </div>

        <div className="right-content">
          <Header changeSideBar={changeSideBar} />

          <div className="body">
            <Switch>
              <AuthRoute exact path="/sign_in" component={SignIn} />
              <AuthRoute exact path="/sign_up" component={SignUp} />
              <AuthRoute exact path="/reset_password/:reset_token" component={ResetPassword} />

              <HpRoute
                exact
                path="/"
                component={() => {
                  window.location.href = "https://orea.immo";
                }}
              />

              <Route exact path="/cgu" component={Cgu} />
              <Route exact path="/cgv" component={Cgv} />

              <UserRoute exact path="/user_panel/folder_requests" component={FolderRequests} />
              <UserRoute exact path="/user_panel/folder_request/:id/edit" component={FolderRequest} />

              <AgengyRoute exact path="/agency/Dashboard" component={AgencyDashboard} />
              <AgengyRoute exact path="/agency/subscriptions" component={AgencySubscriptions} />
              <AgengyRoute exact path="/agency/insights" component={AgencyInsights} />

              <MailboxRoute exact path="/mailbox/:folderId" component={AccountMailBox} />

              <AgengyRoute exact path="/account/folders" component={AccountFolders} />
              <AgengyRoute exact path="/account/custom_files" component={FilesTypes} />
              <AgengyRoute exact path="/account/custom_files/sell" component={CustomSellFiles} />
              <AgengyRoute exact path="/account/custom_files/rent" component={CustomRentFiles} />

              <AgengyRoute exact path="/account/sell/new" component={AccountNewSell} />
              <AgengyRoute exact path="/account/sell/edit/:id" component={AccountEditSell} />
              <AgengyRoute exact path="/account/rent/new" component={AccountNewRent} />
              <AgengyRoute exact path="/account/rent/edit/:id" component={AccountEditRent} />
              <Route path="*" component={() => "404 NOT FOUND"} />
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
