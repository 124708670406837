import React from "react";
import InsightsContainer from "../../agency/insightsContainer";

class Subscriptions extends React.Component {
  render() {
    return (
      <div className="Agency">
        <div className="container">
          <div className="row">
            <InsightsContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default Subscriptions;
