export const emptyPropertyData = {
  propertyType: "",
  propertyAddressStreet: "",
  propertyAddressNumber: "",
  propertyAddressCountry: "Luxembourg",
  propertyAddressZipCode: "",
  propertyAddressLocality: "",
  propertyAddressMunicipal: "",
  propertyAddressSection: "",
  propertyAddressFrom: "",
  propertyNumCad: "",
  propertyLot: "",
  propertyAreTer: "",
  propertyNrbBedrom: "",
  propertySurHab: "",
  propertyBasement: "",
  propertyTerBal: "",
  propertyConstructionYear: "",
  propertyCpe: "",
  propertyEmpInt: "",
  propertyEmpExt: "",
  propertyHabSousBail: "",
  propertyBailEmp: "",
  propertyServ: "",
  propertySitHyp: "",
  propertyEmail: "",
  propertyPlaceName: "",
  propertyNature: "",
  propertyOccupation: "",
};

export const emptySellerData = {
  remove: false,
  validated: false,
  sellerType: "",
  sellerLastName: "",
  sellerFirstName: "",
  sellerLabel: "",
  sellerAddressStreet: "",
  sellerAddressNumber: "",
  sellerAddressCountry: "",
  sellerAddressZipCode: "",
  sellerAddressLocality: "",
  sellerPhone: "",
  sellerEMail: "",
  sellerIdNum: "",
  sellerMatSecSoc: "",
  sellerCivilStatus: "",
  sellerProf: "",
  sellerBirthDate: "",
  sellerNationality: "",
  sellerCompanyName: "",
  sellerCompanyForm: "",
  sellerLegalRep: "",
  sellerRepAddressStreet: "",
  sellerRepAddressNumber: "",
  sellerRepAddressCountry: "",
  sellerRepAddressZipCode: "",
  sellerRepAddressLocality: "",
  sellerNumRc: "",
  sellerNumTva: "",
  sellerEcoBen: "",
  sellerBeneficiaries: [],
  sellerRepEmail: "",
};

export const emptyBuyerData = {
  remove: false,
  validated: false,
  buyerLastName: "",
  buyerFirstName: "",
  buyerLabel: "",
  buyerAddressStreet: "",
  buyerAddressNumber: "",
  buyerAddressCountry: "",
  buyerAddressZipCode: "",
  buyerAddressLocality: "",
  buyerPhone: "",
  buyerEMail: "",
  buyerIdNum: "",
  buyerMatSecSoc: "",
  buyerCivilStatus: "",
  buyerProf: "",
  buyerBirthDate: "",
  buyerNationality: "",
  buyerCompanyName: "",
  buyerCompanyForm: "",
  buyerLegalRep: "",
  buyerRepAddressStreet: "",
  buyerRepAddressNumber: "",
  buyerRepAddressCountry: "",
  buyerRepAddressZipCode: "",
  buyerRepAddressLocality: "",
  buyerNumRc: "",
  buyerNumTva: "",
  buyerEcoBen: "",
  buyerBeneficiaries: [],
  buyerRepEmail: "",
};

export const emptyActData = {
  actSellingPrice: "",
  actSellingPriceLetters: "",
  actCharge: "",
  actNotaryLastName: "",
  actLocality: "",
  actDateAtTheLeast: "",
  actDate: "",
  actEffectiveDate: "",
  actDeadlineBank: "",
  actAcquisitionResale: "",
  actSellCommission: "",
  actRentCommission: "",
  actBankguaranteeType: "",
  actBankguaranteeMonths: "",
  actBankguaranteeAmunt: "",
  actEmail: "",
};

export const emptyBeneficiary = {
  remove: false,
  zipCode: "",
  firstName: "",
  lastName: "",
  streetNumber: "",
  streetName: "",
  city: "",
  country: "",
  profession: "",
  secSoc: "",
  numId: "",
  birthLocality: "",
  civilStatus: "",
  validated: false,
  refAedmandat: "",
  refAedcompromis: "",
  label: "",
};
