import React, { useState } from "react";
import Translate from "react-translate-component";
import InputGroup from "./inputGroup";

import { emptyBeneficiary } from "../../../../account/shared/data/emptyData";
import { millionWord } from "../../../../account/shared/helpers";

import useForm, { FormContext } from "react-hook-form";
import counterpart from "counterpart";

function Form({ agencyCountry, submitFolder, initValues, type, propertyData, sellerData, buyerData, actData, emptySellerData, emptyBuyerData, editSection }) {
  const [values, setValues] = useState(initValues);
  const [beneficiary] = useState(emptyBeneficiary);

  const methods = useForm();
  const { handleSubmit } = methods;
  const selectOption = (code) => {
    return (
      <option value={code} key={code}>
        {counterpart.translate("forms.folder.options." + code)}{" "}
      </option>
    );
  };
  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    var vals = { ...values };

    const prevObj = () => {
      vals[section][name] = value;
      if (type === "sell" && name === "propertyAddressLocality") {
        vals[section]["propertyAddressFrom"] = value;
      }
      if (name === "actSellingPrice") {
        vals.act.actSellingPriceLetters = millionWord(vals.act.actSellingPrice);
      }
      if (name === "actBankguaranteeAmount") {
        vals.act.actBankguaranteeAmountLetters = millionWord(vals.act.actBankguaranteeAmount);
      }
      if (name === "actCharge") {
        vals.act.actChargeLettres = millionWord(vals.act.actCharge);
      }
      if (
        type === "rent" &&
        section === "act" &&
        (name === "actSellingPrice" || name === "actBankguaranteeMonths") &&
        vals.act.actSellingPrice !== null &&
        vals.act.actBankguaranteeMonths !== null
      ) {
        vals.act.actBankguaranteeAmount = vals.act.actSellingPrice * vals.act.actBankguaranteeMonths;
        vals.act.actBankguaranteeAmountLetters = millionWord(vals.act.actSellingPrice * vals.act.actBankguaranteeMonths);
      }
      return vals;
    };

    setValues(prevObj);
  };
  const handleSellerInputChange = (e, index, section) => {
    const { name, value } = e.target;
    var vals = { ...values };

    const prevObj = () => {
      vals[section] = vals[section].map((s, i) => {
        if (i === index) {
          s[name] = value;
          if (name === "sellerMatSecSoc" && s.sellerMatSecSoc.length > 7) {
            var d = s.sellerMatSecSoc;
            var yyyy = d.substring(0, 4);
            var mm = d.substring(4, 6);
            var dd = d.substring(6, 8);
            s.sellerBirthDate = new Date(yyyy + "-" + mm + "-" + dd);
          }
          if (name === "buyerMatSecSoc" && s.buyerMatSecSoc.length > 7) {
            var d = s.buyerMatSecSoc;
            var yyyy = d.substring(0, 4);
            var mm = d.substring(4, 6);
            var dd = d.substring(6, 8);
            s.buyerBirthDate = new Date(yyyy + "-" + mm + "-" + dd);
          }
          return s;
        }
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };
  const setDate = (date, name, section) => {
    setValues({ ...values, [section]: { ...values[section], [name]: date } });
  };
  const setDateWithIndex = (date, name, section, index) => {
    var vals = values[section];
    vals[index][name] = date;
    setValues({ ...values, [section]: [...vals] });
  };
  const addSeller = () => {
    setValues({ ...values, sellers: [...values.sellers, emptySellerData] });
  };
  const addBuyer = () => {
    setValues({ ...values, buyers: [...values.buyers, emptyBuyerData] });
  };
  const remove = (index, section) => {
    var vals = { ...values };

    const prevObj = () => {
      vals[section] = vals[section].map((s, i) => {
        if (i === index) return { ...s, remove: true };
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };

  const onSubmit = (data) => {
    submitFolder(values);
  };
  const addSellerBeneficiary = (index, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var copyBeneficiary = { ...beneficiary };

    seller[benefSection] = [...seller[benefSection], copyBeneficiary];
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };
  const handleBenefChange = (e, index, benefIndex, section, benefSection) => {
    const { name, value } = e.target;

    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef[name] = value;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };
  const handleBenefValidation = (validation, index, benefIndex, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef.validated = validation;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };

  const handleBenefRemove = (index, benefIndex, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef.remove = true;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="blue-border p-4">
          <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {editSection === "seller" && (
                <div className={`content-tab`}>
                  {values.sellers.length > 0 &&
                    values.sellers.map((item, index) => {
                      return (
                        <>
                          {!item.remove && (
                            <div key={index}>
                              <InputGroup
                                agencyCountry={agencyCountry}
                                formData={sellerData}
                                prefix="sellers"
                                folderType={type}
                                handleInputChange={(e, i, s) => handleSellerInputChange(e, index, "sellers")}
                                setDate={setDateWithIndex}
                                values={item}
                                conditionValue={item["sellerType"]}
                                index={index}
                              />

                              {item["sellerType"] === "CompanySeller" && (
                                <>
                                  <Translate content={`forms.folder.labels.EcoBen`} className="label" component="h3" />
                                  {item.sellerBeneficiaries.length > 0 &&
                                    item.sellerBeneficiaries.map((benefItem, benefIndex) => {
                                      return (
                                        <div className="row" key={benefIndex}>
                                          {!benefItem.validated && !benefItem.remove && (
                                            <>
                                              <div className="col-md-12  no-bg col-sm-12 custom-input ">
                                                <div className="input">
                                                  <div className="radio">
                                                    <label className="radio-container">
                                                      <Translate content="forms.folder.options.mr" />
                                                      <input
                                                        type="radio"
                                                        name="label"
                                                        value="mr"
                                                        ref={methods.register()}
                                                        onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                        defaultChecked={benefItem.label === "mr"}
                                                      />
                                                      <span className={`checkmark ${benefItem.label === "mr" ? "" : "un"}checked`}></span>
                                                    </label>

                                                    <label className="radio-container">
                                                      <Translate content="forms.folder.options.mrs" />
                                                      <input
                                                        type="radio"
                                                        name="label"
                                                        value="mrs"
                                                        ref={methods.register()}
                                                        onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                        defaultChecked={benefItem.label === "mrs"}
                                                      />
                                                      <span className={`checkmark ${benefItem.label === "mrs" ? "" : "un"}checked`}></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.benefFirstName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="firstName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.firstName}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.benefLastName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="lastName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.lastName}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.streetNumber`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="streetNumber"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.streetNumber}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.streetName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="streetName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.streetName}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.zipCode`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="zipCode"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.zipCode}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.city`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="city"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.city}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.country`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="country"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.country}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.profession`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="profession"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.profession}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.secSoc`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="secSoc"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.secSoc}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.numId`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="numId"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.numId}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.birthLocality`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="birthLocality"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.birthLocality}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.civilStatus`} className="label" />
                                                  <select
                                                    name="civilStatus"
                                                    className="select"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    defaultValue={benefItem.civilStatus}
                                                  >
                                                    <option disabled value="">
                                                      {" "}
                                                    </option>
                                                    {["single", "married", "divorced", "widowed"].map((choice) => selectOption(choice))}
                                                  </select>
                                                </div>
                                              </div>

                                              <div
                                                className="col-md-12 validate-benef"
                                                onClick={() => handleBenefValidation(true, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                              >
                                                <i className="fa fa-check"></i>
                                              </div>
                                            </>
                                          )}
                                          {benefItem.validated && !benefItem.remove && (
                                            <>
                                              <div className="mb-2 benef col-md-8 col-xs-8">
                                                {benefItem.firstName} {benefItem.lastName} ...
                                              </div>
                                              <div className="mb-2 col-md-4 col-xs-2 inlineFlex">
                                                <div className="show-benef" onClick={() => handleBenefValidation(false, index, benefIndex, "sellers", "sellerBeneficiaries")}>
                                                  <i className="fa fa-eye"></i>
                                                </div>
                                                <div className="remove-benef" onClick={() => handleBenefRemove(index, benefIndex, "sellers", "sellerBeneficiaries")}>
                                                  <i className="fa fa-trash"></i>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      );
                                    })}
                                  <button type="button" onClick={() => addSellerBeneficiary(index, "sellers", "sellerBeneficiaries")} className="add-ecoBen">
                                    <Translate content={`forms.folder.labels.addEcoBen`} />
                                  </button>
                                </>
                              )}

                              <hr />
                              <div className="row">
                                <div className="col-md-12">
                                  <button type="button" className="remove-btn col-md-3" onClick={() => remove(index, "sellers")}>
                                    <i className="fa fa-user-times"></i>
                                    <Translate content="forms.folder.buttons.remove_seller" />
                                  </button>
                                  <button type="button" className="validate-btn offset-md-6 col-md-3">
                                    <i className="fa fa-check"></i>
                                    <Translate content="forms.folder.buttons.validate" />
                                  </button>
                                </div>
                              </div>

                              <hr />
                            </div>
                          )}
                        </>
                      );
                    })}
                  <button type="button" className="add-btn" onClick={addSeller}>
                    <i className="fa fa-user-plus"></i>
                    <Translate content="forms.folder.buttons.add_seller" />
                  </button>
                </div>
              )}
              {editSection === "buyer" && (
                <div className={`content-tab`}>
                  {values.buyers.length > 0 &&
                    values.buyers.map((item, index) => {
                      return (
                        <>
                          {!item.remove && (
                            <div key={index}>
                              <InputGroup
                                agencyCountry={agencyCountry}
                                formData={buyerData}
                                prefix="buyers"
                                folderType={type}
                                handleInputChange={(e, i, s) => handleSellerInputChange(e, index, "buyers")}
                                setDate={setDateWithIndex}
                                values={item}
                                conditionValue={item["buyerType"]}
                                index={index}
                              />

                              {item["buyerType"] === "CompanySeller" && (
                                <>
                                  <Translate content={`forms.folder.labels.EcoBen`} className="label" component="h3" />
                                  {item.buyerBeneficiaries.length > 0 &&
                                    item.buyerBeneficiaries.map((benefItem, benefIndex) => {
                                      return (
                                        <div className="row" key={benefIndex}>
                                          {!benefItem.validated && !benefItem.remove && (
                                            <>
                                              <div className="col-md-12  no-bg col-sm-12 custom-input ">
                                                <div className="input">
                                                  <div className="radio">
                                                    <label className="radio-container">
                                                      <Translate content="forms.folder.options.mr" />
                                                      <input
                                                        type="radio"
                                                        name="label"
                                                        value="mr"
                                                        ref={methods.register()}
                                                        onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                        defaultChecked={benefItem.label === "mr"}
                                                      />
                                                      <span className={`checkmark ${benefItem.label === "mr" ? "" : "un"}checked`}></span>
                                                    </label>

                                                    <label className="radio-container">
                                                      <Translate content="forms.folder.options.mrs" />
                                                      <input
                                                        type="radio"
                                                        name="label"
                                                        value="mrs"
                                                        ref={methods.register()}
                                                        onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                        defaultChecked={benefItem.label === "mrs"}
                                                      />
                                                      <span className={`checkmark ${benefItem.label === "mrs" ? "" : "un"}checked`}></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.benefFirstName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="firstName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.firstName}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.benefLastName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="lastName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.lastName}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.streetNumber`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="streetNumber"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.streetNumber}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.streetName`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="streetName"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.streetName}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.zipCode`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="zipCode"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.zipCode}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.city`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="city"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.city}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-4 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.country`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="country"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.country}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.profession`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="profession"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.profession}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.secSoc`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="secSoc"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.secSoc}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.numId`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="numId"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.numId}
                                                  />
                                                </div>
                                              </div>

                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.birthLocality`} className="label" />
                                                  <input
                                                    type="text"
                                                    name="birthLocality"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.birthLocality}
                                                  />
                                                </div>
                                              </div>
                                              <div className={`custom-input col-md-6 col-xs-12`}>
                                                <div className="input">
                                                  <Translate content={`forms.folder.labels.civilStatus`} className="label" />
                                                  <select
                                                    name="civilStatus"
                                                    className="select"
                                                    ref={methods.register()}
                                                    onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                    defaultValue={benefItem.civilStatus}
                                                  >
                                                    <option disabled value="">
                                                      {" "}
                                                    </option>
                                                    {["single", "married", "divorced", "widowed"].map((choice) => selectOption(choice))}
                                                  </select>
                                                </div>
                                              </div>
                                              <div
                                                className="col-md-12 validate-benef"
                                                onClick={() => handleBenefValidation(true, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                              >
                                                <i className="fa fa-check"></i>
                                              </div>
                                            </>
                                          )}

                                          {benefItem.validated && !benefItem.remove && (
                                            <>
                                              <div className="mb-2 benef col-md-8 col-xs-8">
                                                {benefItem.firstName} {benefItem.lastName} ...
                                              </div>
                                              <div className="mb-2 col-md-4 col-xs-4 inlineFlex">
                                                <div className="show-benef" onClick={() => handleBenefValidation(false, index, benefIndex, "buyers", "buyerBeneficiaries")}>
                                                  <i className="fa fa-eye"></i>
                                                </div>
                                                <div className="remove-benef" onClick={() => handleBenefRemove(index, benefIndex, "buyers", "buyerBeneficiaries")}>
                                                  <i className="fa fa-trash"></i>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      );
                                    })}
                                  <button type="button" onClick={() => addSellerBeneficiary(index, "buyers", "buyerBeneficiaries")} className="add-ecoBen">
                                    <Translate content={`forms.folder.labels.addEcoBen`} />
                                  </button>
                                </>
                              )}

                              <hr />
                              <div className="row">
                                <div className="col-md-12">
                                  <button type="button" className="remove-btn col-md-3" onClick={() => remove(index, "buyers")}>
                                    <i className="fa fa-user-times"></i>
                                    <Translate content="forms.folder.buttons.remove_buyer" />
                                  </button>
                                  <button type="button" className="validate-btn offset-md-6 col-md-3">
                                    <i className="fa fa-check"></i>
                                    <Translate content="forms.folder.buttons.validate" />
                                  </button>
                                </div>
                              </div>

                              <hr />
                            </div>
                          )}
                        </>
                      );
                    })}
                  <button type="button" className="add-btn" onClick={addBuyer}>
                    <i className="fa fa-user-plus"></i>
                    <Translate content="forms.folder.buttons.add_buyer" />
                  </button>
                </div>
              )}
              {editSection === "act" && (
                <div className={`content-tab`}>
                  <InputGroup
                    agencyCountry={agencyCountry}
                    formData={actData}
                    prefix="act"
                    folderType={type}
                    handleInputChange={(e, section) => handleInputChange(e, "act")}
                    setDate={setDate}
                    values={values.act}
                  />
                </div>
              )}

              <div className="row">
                <div className="col-md-4 offset-md-8 col-sm-12">
                  <button type="submit" className="submit">
                    <Translate content="forms.folder.submit" />
                  </button>
                </div>
              </div>
            </form>
          </FormContext>
        </div>
      </div>
    </div>
  );
}

export default Form;
