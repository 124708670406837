import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as actionACreator from '../../../store/actions/folderRequestActions';
import Table from '../../../components/user_panel/requests/table.js';
import Translate from 'react-translate-component';

class FolderRequests extends Component {
  componentDidMount() {
    this.props.getFolderRequests();
  }
  render() {
    return (
      <div className='Agency'>
        <div className='container agency-folders'>
            <div className='custom-box'>
              <div className='row'>
                <div className='col-12'>
                  <Translate content='folders.table.title' className='table-title' component='div' />
                </div>
                <div className='col-12'>
                  {this.props.folder_requests && this.props.folder_requests.length > 0 && <Table folder_requests={this.props.folder_requests}/>}
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  folder_requests: state.folderRequestReducer.folder_requests,
});

const mapDispatchToProps = (dispatch) => ({
  getFolderRequests: () => dispatch(actionACreator.getFolderRequests()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FolderRequests);
