import { accountService } from "../services/accountService";
import history from "./history";

import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";

const flash = (code) => {
  return counterpart.translate(code);
};

export function generateDocument(docIndex, type, idFolder, documentLanguage) {
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(document(false));

    accountService.generateDocument(docIndex, type, idFolder, documentLanguage).then(
      (response) => {
        dispatch(document(response.data));
        dispatch(loading(false));
      },
      (error) => {
        dispatch(loading(false));
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function createFolder(values) {
  return (dispatch) => {
    accountService.createFolder(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.folder.success.create") }));
        history.push("/account/folders");
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}
export function updateFolder(values) {
  return (dispatch) => {
    accountService.updateFolder(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.folder.success.update") }));
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function removeFolder(id) {
  return (dispatch) => {
    accountService.removeFolder(id).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.folder.success.update") }));
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function getFolders(values) {
  return (dispatch) => {
    accountService.getFolders(values).then(
      (response) => {
        dispatch(folders(response.data.folders));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("access.folder.no_agency_placeholder") }));
      }
    );
  };
}
export function getFolder(id) {
  return (dispatch) => {
    accountService.getFolder(id).then(
      (response) => {
        dispatch(folder(response.data.folder));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}
export function getAgencyCountry() {
  return (dispatch) => {
    accountService.getAgencyCountry().then(
      (response) => {
        dispatch(setAgencyCountry(response.data.agencyCountry));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function sendInvitation(values) {
  return (dispatch) => {
    accountService.sendInvitation(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.invitation.sended") }));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function folders(folders) {
  return {
    type: "GET_FOLDERS",
    folders,
  };
}
export function folder(folder) {
  return {
    type: "GET_FOLDER",
    folder,
  };
}

export function loading(loading) {
  return {
    type: "LOADING_DOCUMENT",
    loading,
  };
}

export function document(documentFile) {
  return {
    type: "GENERATED_DOCUMENT",
    documentFile,
  };
}

export function setAgencyCountry(agencyCountry) {
  return {
    type: "SET_AGENCY_COUNTRY",
    agencyCountry,
  };
}
