import React from "react";
import Translate from "react-translate-component";
import counterpart from "counterpart";

function SubscriptionsCard({ activeSubscription, subscription, changeActiveSubscription, type }) {
  const changeSubscription = (type, period) => {
    changeActiveSubscription(type, period);
  };
  const active = activeSubscription && activeSubscription.type === type;
  const checkOption = (type, option) => {
    return counterpart.translate(`subscriptions.${type}.${option}.valid`) ? "" : "text-white";
  };

  const solde = () => {
    return counterpart.translate(`subscriptions.solde`);
  };

  const price = (code) => {
    return counterpart.translate(code);
  };

  const originPrice = (code) => {
    var oPrice = 0;
    oPrice = active ? price(`subscriptions.${activeSubscription.type}.periods.${activeSubscription.period}.${code}`) : oPrice;
    oPrice = !activeSubscription || (activeSubscription && activeSubscription.type !== type) ? price(`subscriptions.${type}.periods.twelveMonths.${code}`) : oPrice;

    return oPrice;
  };

  const formated_price = (code) => {
    var fPrice = originPrice(code);
    return <span> {fPrice} € </span>;
  };

  const soldePrice = (code) => {
    var soldeVal = solde() ? solde() : 1;
    var oPrice = originPrice(code);

    return <span> {(soldeVal * oPrice).toString()} € </span>;
  };

  return (
    <div className={`col-sm-4 col-${type}`}>
      <div className={`card text-center ${active ? "active" : ""}`}>
        <div className="title">
          <Translate content={`subscriptions.${type}.title`} component="h2" />
        </div>

        <div className={`price ${solde() ? "solde" : ""}`}>
          <h4>
            <span className="origin-price">{formated_price("price")}</span>
            <span className="solde-price">{soldePrice("price")}</span>
            <Translate content="subscription_card.by_month" />
          </h4>

          <div className="total">
            <Translate content="subscriptions.periodPrice" />
            <span className="origin-price">{formated_price("periodPrice")}</span>
            <span className="solde-price">{soldePrice("periodPrice")}</span>
            <Translate content="subscription_card.htva" />
            {solde() && <Translate content={`subscriptions.reduction.${active ? activeSubscription.period : "twelveMonths"}`} component="div" />}
          </div>
        </div>
        <div className="option">
          <ul>
            <li className={checkOption(subscription.type, "option1")}>
              <Translate content={`subscriptions.${subscription.type}.option1.title`} />
            </li>

            <li className={checkOption(subscription.type, "option2")}>
              <Translate content={`subscriptions.${subscription.type}.option2.title`} />
            </li>

            <li className={checkOption(subscription.type, "option3")}>
              <Translate content={`subscriptions.${subscription.type}.option3.title`} />
            </li>

            <li className={checkOption(subscription.type, "option4")}>
              <Translate content={`subscriptions.${subscription.type}.option4.title`} />
            </li>
            <li className={checkOption(subscription.type, "option5")}>
              <Translate content={`subscriptions.${subscription.type}.option5.title`} />
            </li>
            <li className={checkOption(subscription.type, "option6")}>
              <Translate content={`subscriptions.${subscription.type}.option6.title`} />
            </li>
            <li className={checkOption(subscription.type, "option7")}>
              <Translate content={`subscriptions.${subscription.type}.option7.title`} />
            </li>
            <li className={checkOption(subscription.type, "option8")}>
              <Translate content={`subscriptions.${subscription.type}.option8.title`} />
            </li>
            <li className={checkOption(subscription.type, "option9")}>
              <Translate content={`subscriptions.${subscription.type}.option9.title`} />
            </li>
          </ul>
        </div>

        <div className="mt-4">
          <div className={`action-container py-3 ${active && activeSubscription.period === "sixMonths" ? "active" : ""}`}>
            <span className="action" onClick={() => changeSubscription(type, "sixMonths")}>
              <Translate content={`subscriptions.${type}.periods.sixMonths.formula`} />
            </span>
          </div>

          <div className={`action-container py-3 ${active && activeSubscription.period === "twelveMonths" ? "active" : ""}`}>
            <span className="action" onClick={() => changeSubscription(type, "twelveMonths")}>
              <Translate content={`subscriptions.${type}.periods.twelveMonths.formula`} />
            </span>
          </div>

          {/* <div className={`action-container py-3 ${active && activeSubscription.period === "twoyears" ? "active" : ""}`}>
            <span className="action" onClick={() => changeSubscription(type, "twoyears")}>
              <Translate content={`subscriptions.${type}.periods.twoyears.formula`} />
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsCard;
