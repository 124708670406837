import React from "react";
import { connect } from "react-redux";
import Form from "../shared/form";
import * as actionCreator from "../../../store/actions/accountActions";
import { emptyPropertyData, emptySellerData, emptyBuyerData, emptyActData } from "../shared/data/emptyData";
import { propertyData, sellerData, buyerData, actData } from "../shared/data/rentData";

class NewRentContainer extends React.Component {
  submitFolder = (values) => {
    this.props.createFolder(values);
  };
  render() {
    const initValues = {
      type: "rent",
      property: { ...emptyPropertyData },
      sellers: [],
      buyers: [],
      act: { ...emptyActData },
    };
    return (
      <div>
        <Form
          submitFolder={this.submitFolder}
          initValues={initValues}
          type="rent"
          propertyData={propertyData}
          sellerData={sellerData}
          buyerData={buyerData}
          actData={actData}
          emptySellerData={emptySellerData}
          emptyBuyerData={emptyBuyerData}
          agencyCountry={this.props.agencyCountry}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createFolder: (values) => dispatch(actionCreator.createFolder(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRentContainer);
