import { agencyService } from "../services/agencyService";
import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";
import history from "./history";

const flash = (name) => {
  return counterpart.translate(name);
};

export function getAgency() {
  return (dispatch) => {
    dispatch(loadingAgency(true));
    agencyService.getAgency().then(
      (response) => {
        dispatch(agency(response.data.agency));
        dispatch(loadingAgency(false));
      },
      (error) => {
        dispatch(Notifications.error({ message: "flash.error.general" }));
        dispatch(loadingAgency(false));
      }
    );
  };
}

export function createAgency(values) {
  return (dispatch) => {
    agencyService.createAgency(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.agency.success.create") }));
        history.push("/agency/subscriptions");
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function updateAgency(values) {
  return (dispatch) => {
    agencyService.updateAgency(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.agency.success.update") }));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function upgradeSubscription(with_media) {
  return (dispatch) => {
    agencyService.upgradeSubscription(with_media).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.agency.subscription.update") }));
        dispatch(getCurrentOrder());
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function endSubscription(id) {
  return (dispatch) => {
    dispatch(loadingUnsubscribe(true));
    agencyService.endSubscription(id).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.agency.subscription.update") }));
        dispatch(getAgencyOrders());
        dispatch(loadingUnsubscribe(false));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
        dispatch(loadingUnsubscribe(false));
      }
    );
  };
}
export function reactivate(id) {
  return (dispatch) => {
    dispatch(loadingUnsubscribe(true));
    agencyService.reactivate(id).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.agency.subscription.update") }));
        dispatch(getAgencyOrders());
        dispatch(loadingUnsubscribe(false));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
        dispatch(loadingUnsubscribe(false));
      }
    );
  };
}

export function getAgencyOrders() {
  return (dispatch) => {
    agencyService.getAgencyOrders().then(
      (response) => {
        dispatch(agencyOrders(response.data.orders));
      },
      (error) => {
        console.log("error charge : ", error);
      }
    );
  };
}

export function getActiveOrder() {
  // this return a boolean
  return (dispatch) => {
    agencyService.getActiveOrder().then(
      (response) => {
        dispatch(activeOrder(response.data.active_order));
      },
      (error) => {
        console.log("error charge : ", error);
      }
    );
  };
}

// this return the object of the current order
export function getCurrentOrder() {
  return (dispatch) => {
    dispatch(loadingCurrentOrder(true));
    agencyService.getCurrentOrder().then(
      (response) => {
        dispatch(currentOrder(response.data.current_order));
        dispatch(loadingCurrentOrder(false));
      },
      (error) => {
        console.log("error charge : ", error);
        dispatch(loadingCurrentOrder(false));
      }
    );
  };
}

// Reducers
export function agency(agency) {
  return {
    type: "GET_AGENCY",
    agency,
  };
}
export function agencyOrders(agencyOrders) {
  return {
    type: "GET_AGENCY_ORDERS",
    agencyOrders,
  };
}
export function activeOrder(activeOrder) {
  return {
    type: "GET_ACTIVE_ORDER",
    activeOrder,
  };
}

export function currentOrder(currentOrder) {
  return {
    type: "GET_CURRENT_ORDER",
    currentOrder,
  };
}

export function loadingCurrentOrder(loadingOrder) {
  return {
    type: "LOADING_CURRENT_ORDER",
    loadingOrder,
  };
}

export function loadingAgency(loadingAgency) {
  return {
    type: "LOADING_AGENCY",
    loadingAgency,
  };
}

export function loadingUnsubscribe(loadingUnsubscribe) {
  return {
    type: "LOADING_UNSUBCRIBE",
    loadingUnsubscribe,
  };
}
