import React from 'react';
import Translate from 'react-translate-component';
import Fade from 'react-reveal/Fade';


function Intro(props) {
  return(
    <div className='row'>

        <div className='col-md-8 col-sm-6 col-xs-12'>
          <Fade left>
            <Translate content='homepage.intro.title' className='title' unsafe/>
              <div className="color_border"></div>
            </Fade>
            <Translate content='homepage.intro.body' className='body' component='div' unsafe/>
        </div>
        <div className='col-md-4 col-sm-6 col-xs-12'>
          <img src='./images/sliders/slider2.jpg' className='img' alt='intro' />
        </div>
    </div>
  )
}

export default Intro;
