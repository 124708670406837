import React from "react";
import useAxios from "axios-hooks";

function AgencyFile({ lang, name, fileType, index, generateDoc }) {
  const bodyData = { fileName: name, fileLanguage: lang, fileType: fileType };

  const [{ data, loading }] = useAxios({
    url: "/agencyCustomFiles/check_file",
    method: "POST",
    data: bodyData,
  });

  return (
    <>
      {!loading && data.fileResponse.fileExists && (
        <div className="col-md-3 col-sm-4 col-xs-6">
          <div className="generate-btn blue-bg" onClick={() => generateDoc(index)}>
            <span>{data.fileResponse.fileName}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default AgencyFile;
