let user = JSON.parse(localStorage.getItem("user"));
const globalState = {
  forgotSuccess: false,
  errorLogin: false,
  errorFP: false,
  errorRegister: false,
  successRegister: false,
  showRegisterModal: false,
  showLoginModal: false,
  forgotError: false,
};

const initialState = user ? { user, globalState } : globalState;

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        user: action.user,
      };
    case "LOGIN_FAILURE":
      return {
        errorLogin: action.errors,
      };
    case "REGISTER_SUCCESS":
      return {
        successRegister: true,
      };
    case "REGISTER_FAILURE":
      return {
        errorRegister: action.error,
      };
    case "LOGOUT":
      return {};
    case "SET_FORGOT_ERROR":
      return {
        forgotError: true,
      };
    case "SET_FORGOT_SUCCESS":
      return {
        forgotSuccess: true,
      };
    default:
      return state;
  }
};

export default authReducer;
