import { stripeService } from "../services/stripeService";
import { getAgencyOrders, getActiveOrder, getCurrentOrder } from "./agencyActions";

import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";

const flash = (name) => {
  return counterpart.translate("flash." + name);
};

export function createSubscription(stripe, subscription, card, name, promo) {
  return (dispatch) => {
    stripeService.createToken(stripe, name).then(
      (response) => {
        dispatch(createPaymentMethod(stripe, subscription, card, response.token, name, promo));
      },
      (error) => {
        console.log("error token : ", error);
      }
    );
  };
}
export function getPublicKey() {
  return (dispatch) => {
    stripeService.getPublicKey().then(
      (response) => {
        dispatch(setPublicKey(response.data));
      },
      (error) => {
        console.log("error token : ", error);
      }
    );
  };
}
export function createPaymentMethod(stripe, subscription, card, token, name, promo) {
  return (dispatch) => {
    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: name,
        },
      })
      .then((result) => {
        const data = {
          stripeToken: token.id,
          orderName: subscription.code,
          paymentMethod: result.paymentMethod.id,
          promo: promo,
        };
        dispatch(createOrder(data, stripe));
      });
  };
}

export function createOrder(data, stripe) {
  return (dispatch) => {
    dispatch(activateLoading());

    stripeService.createOrder(data, stripe).then(
      (response) => {
        dispatch(desactivateLoading());
        if (response.data.subscription.status === "active") {
          dispatch(getAgencyOrders());
          dispatch(getActiveOrder());
          dispatch(getCurrentOrder());
          dispatch(Notifications.info({ message: flash("subscription.success.message"), position: "tc", title: flash("subscription.success.title") }));
        } else if (response.data.subscription.status === "incomplete") {
          var paymentIntentSecret = response.data.subscription.latest_invoice.payment_intent.client_secret;
          dispatch(confirmCardPayment(stripe, paymentIntentSecret, response.data.subscription.id));
        } else {
          console.log("error");
        }
      },
      (err) => {
        dispatch(desactivateLoading());
        dispatch(Notifications.error({ message: counterpart.translate("flash.error.general") }));
      }
    );
  };
}
export function confirmCardPayment(stripe, paymentIntentSecret, subscriptionId) {
  return (dispatch) => {
    stripe.confirmCardPayment(paymentIntentSecret).then((result) => {
      if (result.error) {
        console.log("error : ", result.error);
      } else {
        dispatch(validateSubsciption(subscriptionId));
      }
    });
  };
}
export function validateSubsciption(subscriptionId) {
  return (dispatch) => {
    stripeService.validateSubsciption(subscriptionId).then((response) => {
      dispatch(getAgencyOrders());
      dispatch(getActiveOrder());
      dispatch(getCurrentOrder());
      dispatch(Notifications.info({ message: flash("subscription.success.message"), position: "tc", title: flash("subscription.success.title") }));
    });
  };
}

// Loading Reducer

export function activateLoading() {
  return {
    type: "ACTIVATE_LOADING",
  };
}
export function desactivateLoading() {
  return {
    type: "DESACTIVATE_LOADING",
  };
}
export function setPublicKey(publicKey) {
  return {
    type: "PUBLIC_KEY",
    publicKey,
  };
}
