export default {
  total: "Insgesamt offen",
  copySellerAddress: {
    sell: "Adresse des ersten Verkäufers benutzen",
    rent: "Adresse des ersten Käufers benutzen",
  },
  copyBuyerAddress: {
    sell: "Adresse des ersten Besitzers benutzen",
    rent: "Adresse des ersten Mieters benutzen",
  },
  insight: {
    count: "Anzahl der Dateien",
    commission: "Agenturprovision",
  },
  sepa: {
    name: "Name",
    email: "Email Address",
    promo: "Gutscheincode",

    card: "Kreditkarte",
    sepa: "Dauerauftrag (SEPA)",
    name_placeholder: "Name des Bankkontoinhabers",
    email_placeholder: "Email Addresse",
    promo_placeholder: "Gutscheincode ...",
    button: "SEPA-Lastschrift einrichten",
    acceptance:
      "Indem Sie Ihre IBAN angeben und diese Zahlung bestätigen, ermächtigen Sie Rocketship Inc. und Stripe, unseren Zahlungsdienstleister, Ihrer Bank Anweisungen zur Belastung Ihres Kontos gemäß diesen Anweisungen zu senden. Sie haben Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den Bestimmungen und Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von acht Wochen ab dem Datum, an dem Ihr Konto belastet wurde, beantragt werden.",
  },
  active: "Aktiv",
  active_folder: "Geöffnete Datei",
  filePond: {
    labelIdle: 'Ziehen Sie Ihre Dateien per Drag & Drop oder  <span class="filepond--label-action"> klicken sie hier um Dateien hochzuladen </span>',
  },
  download_doc: "Klicken Sie hier um ein Benutzerhandbuch herunter zu laden",
  copyAdress: "Adresse der Immobilie verwenden",
  copySBAdress: "Adresse des obigen Besitzers kopieren",
  subscription_card: {
    by_month: "/ monate",
    htva: "Mwst",
  },
  subscription_form: {
    first_title: "Bitte wählen Sie Ihre Zahlungsmethode:",
    title: "Bitte geben Sie Ihre Kreditkartendaten ein",
    confirm: "Bestellung abschliessen",
    name_placeholder: "Name des Kartenbsitzers",
    promo_placeholder: "Gutscheincode ...",
    name_error: "Bitte geben Sie ihren Namen ein.",
  },
  modal: {
    suppression: {
      body: "Möchten Sie diese Daten wirklich löschen?",
      close: "Schliessen",
      confirmation: "Bestätigen",
      title: "Bestätigung",
    },
    folder: {
      body:
        "Wenn Sie die Löschung bestätigen, wird die Datei endgültig gelöscht und alle Daten gehen verloren. Sie können danach nicht mehr darauf zugreifen! Wollen Sie die Löschung wirklich bestätigen?",
      close: "Stornieren",
      confirmation: "Bestätigen",
      title: "Warnung",
    },
  },
  access: {
    folder: {
      no_agency_action: "Bitte klicken",
      no_agency_btn: "Agentur erstellen",
      no_agency_media: "Ihr aktuelles Abonnement erlaubt dies nicht :-(",
      no_agency_media_btn: "Klicken Sie hier, um ein Upgrade durchzuführen",
      no_agency_placeholder: "Sie müssen zuerst Ihre Agentur erstellen !",
      no_subscription_action: "Bitte klicken",
      no_subscription_btn: "Abonnement erstellen",
      no_subscription_placeholder: "Sie brauchen ein Abonnement, um Dokumente zu generieren !",
    },
  },
  authentication: {
    account: "Mein Konto",
    logout: "Ausloggen",
    requests: "Anfragen",
    reset_title: "Passwort zurücksetzen",
    signin: "Einloggen",
    signin_title: "Einloggen",
    signup: "Anmelden",
    signup_title: "Konto anlegen",
    forgot: {
      link: "Passwort vergessen?",
      label: "Bitte geben Sie die mit Ihrem Konto verbundene E-Mail-Adresse ein. Wir senden Ihnen dann eine E-Mail, um Ihr Passwort zurückzusetzen.",
      submit: "Bestätigen",
      placeholder: "Email",
      error: "Kein Konto ist mit dieser E-Mail-Adresse verbunden. Bitte geben Sie die korrekte E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist.",
      success:
        "Wir haben Ihnen eine E-Mail geschickt, um die Rücksetzung Ihres Passworts abzuschließen. Wenn Sie die E-Mail nicht finden können, sehen Sie auch in Ihrem Junk-Mail-Ordner nach.",
    },
  },
  badge: {
    feelfree: "Optional",
    optional: "Optional",
    required: "Erforderlich",
  },
  custom_files: {
    agency_files: "Vorlagen meiner Agentur",
    choose_file: "Datei wählen",
    plz_choose_file: "Bitte wählen Sie eine Datei aus",
    file: {
      added: "Vorlage mit Erfolg hinzugefügt",
    },
    modal: {
      close: "Stornieren",
      delete: "Löschen",
      placeholder: "Sind Sie sicher, diese Datei zu löschen?",
      title: "Bestätigung",
    },
    types: {
      placeholder:
        "Hier können Sie die Standardvorlagen von orea durch Ihre personalisierten Vorlagen ersetzen und (basierend auf Ihrem Abonnement) zusätzliche Vorlagen hinzufügen, z. B. Verkaufsmandate oder jegliche andere Dokumente, die Sie regelmäßig erstellen müssen.",
      rent: "Mietvorlagen",
      sell: "Verkaufsvorlagen",
      title: "Benutzerdefinierte Vorlagen für meine Agentur",
    },
  },
  documents: {
    rent: {
      an: "Bescheinigung für Notar",
      ara: "Risikoanalyse Mieter",
      c: "Mietvertrag",
      f: "Rechnung",
      pb: "Herkunft des Eigentums",
      pf: "Herkunft der Mittel",
      prv: "Risikoanalyse Inhaber",
      rgpdal: "DSGVO Mieter",
      rgpdp: "DSGVO Inhaber",
    },
    sell: {
      an: "Bescheinigung für Notar",
      ara: "Risikoanalyse Käufer",
      c: "Kaufvertrag",
      f: "Rechnung",
      pb: "Herkunft des Eigentums",
      pf: "Herkunft der Mittel",
      prv: "Risikoanalyse Verkäufer",
      rgpdal: "DSGVO Käufer",
      rgpdp: "DSGVO Inhaber",
    },
    types: {
      an: "Bescheinigung für Notar",
      ara: "Risikoanalyse Käufer",
      c: "Kaufvertrag",
      f: "Rechnung",
      pb: "Herkunft des Eigentums",
      pf: "Herkunft der Mittel",
      prv: "Risikoanalyse Verkäufer",
    },
  },
  flash: {
    agency: {
      success: {
        create: "Agenturinformationen mit Erfolg erstellt",
        update: "Agenturinformationen mit Erfolg aktualisiert",
      },
    },
    auth: {
      authTitle: "Authentifizierung",
      connected: "Verbunden mit Erfolg",
      disconected: "Mit Erfolg ausgeloggt",
      registred: "Erfolgreich angemeldet",
      reset: "Passwort erfolgreich zurückgesetzt",
    },
    error: {
      general: "Fehler! Etwas läuft hier schief, sorry :-(",
    },
    folder: {
      success: {
        create: "Neue Datei mit Erfolg erstellt",
        update: "Datei mit Erfolg aktualisiert",
      },
    },
    invitation: {
      sended: "Einladung mit Erfolg verschickt",
    },
    subscription: {
      success: {
        message: "Neues Abonnement",
        title: "Glückwunsch",
      },
    },
  },
  folder_requests: {
    table: {
      agency: "Agentur",
      folder: "Datei",
      folderType: "Typ",
      reference: "#Ref",
      reset_filter: "Filter zurücksetzen",
      section: "Sektion",
      title: "MEINE DATEIEN",
      delete: "Datei löschen",
      mailBox: "Messaging",
    },
  },
  folders: {
    actions: {
      rent: "Neue Mietdatei",
      sell: "Neue Verkaufsdatei",
    },
    table: {
      actions: "Aktionen",
      createdAt: "Erstellt",
      foldertype: "Datei",
      locality: "Ort",
      reference: "#Ref",
      reset_filter: "Filter zurücksetzen",
      seller: "Eigentümer",
      street: "Straße",
      title: "Dateien in Bearbeitung",
      type: "Art",
      step: "Step",
      types: {
        rent: "Miete",
        sell: "Verkauf",
      },
      delete: "Datei löschen",
      mailBox: "Messaging",
    },
  },
  forms: {
    agency: {
      labels: {
        Autorisation: "Handelsgenehmigung",
        Bic: "BIC",
        City: "Ort <span>*</span>",
        CompanyName: "Firmenname <span>*</span>",
        DpofirstName: "Vorname",
        DpolastName: "Nachname",
        Email: "Email",
        Iban: "IBAN",
        ManagerEmail: "Email",
        ManagerFirstName: "Vorname",
        ManagerLastName: "Nachname",
        ManagerLocality: "Wohnort",
        ManagerProfession: "Titel",
        Phone: "Telefonnummer",
        PostalCode: "Postleitzahl <span>*</span>",
        RbfirstName: "Vorname",
        RblastName: "Nachname",
        Rcsl: "Handelsregisternummer",
        StreetName: "Straßenname <span>*</span>",
        StreetNumber: "Nummer <span>*</span>",
        Tva: "Mwst <span>*</span>",
        rgpd: "Ich bin damit einverstanden, dass meine Daten von Linc SA gemäß den <a href='/cgu' target='_blank'>RGPD-Regeln</a> gespeichert werden.",
        Country: "Land <span>*</span>",
        Lu: "Luxembourg",
        Fr: "Frankreich",
      },
      placeholder:
        "Diese Informationen werden verwendet, um die Daten Ihrer Agentur automatisch in die verschiedenen Dokumente einzutragen. Bitte füllen Sie sie sorgfältig aus, damit wir die Dokumente mit allen erforderlichen Informationen vervollständigen können.",
      placeholders: {
        Autorisation: "Genehmigung",
        Bic: "BIC",
        City: "Lokalität",
        CompanyName: "Offizieller Agenturname",
        DpofirstName: "Vorname",
        DpolastName: "Nachname",
        Email: "Email",
        Iban: "IBAN",
        ManagerEmail: "Email",
        ManagerFirstName: "Vorname",
        ManagerLastName: "Nachname",
        ManagerLocality: "Wohnort",
        ManagerProfession: "Titel",
        Phone: "Telefonnummer",
        PostalCode: "Postleitzahl (mit Länderidentifikation, z.B. L-xxxx)",
        RbfirstName: "Vorname",
        RblastName: "Nachname",
        Rcsl: "Handelsregisternummer",
        StreetName: "Straßenname",
        StreetNumber: "Hausnummer",
        Tva: "Mwst-Identifikationsnummer",
      },
      sections: {
        agency: "Grundinformationen",
        dpo: "Daten- / DSGVO-Beauftragter",
        informations: "Hauptinformationen",
        manager: "Büroleiter",
        rb: "AGW-Beauftragter",
      },
      submit: "Bestätigen",
      title: "Informationen zur Agentur",
      insights: "Insights",
      validation: {
        email: "Ungültige E-Mail",
        required: "Dieses Feld wird benötigt !",
      },
    },
    folder: {
      documents: {
        new: {
          title: "Dokument hinzufügen",
          type: {
            label: "Typ",
            sell: {
              buyer: "Käufer",
              seller: "Verkäufer",
              transaction: "Transaktion",
              bank: "Bank",
            },
            rent: {
              buyer: "Mieter",
              seller: "Vermieter",
              transaction: "Miete",
              bank: "Bank",
            },
          },
          select: {
            buyer: "Wählen Sie einen oder mehrere Käufer",
            seller: "Wählen Sie einen oder mehrere Verkäufer",
          },
          file: {
            rgpd: "RGPD",
            pb: "Herkunft des Eigentums",
            pf: "Herkunft der Mittel",
            ar: "Risikoanalyse",
            cid: "ID Karte",
            css: "Sozialversicherungskarte",
            co: "Kaufvertrag",
            fa: "Rechnung",
            at: "Notar. Bescheinigung",
            ma: "Mandat",
            ac: "Urkunde",
            ap: "Grundsätzliche Vereinbarung",
            dp: "Bankanzahlung",
            ba: "Mietvertrag",
            bo: "Stand der Dinge",
            cc: "Lastenheft",
          },
          choose_document: "Welche Art Dokument möchten Sie hinzufügen?",
          add_file: "Dateien hinzufügen",
          summary: "Gespeicherte Dokumente",
        },
      },
      buttons: {
        add_buyer: "Käufer hinzufügen",
        add_seller: "Verkäufer hinzufügen",
        next: "Weiter <i class='fa fa-caret-right'></i>",
        prev: "<i class='fa fa-caret-left'></i> Zurück",
        remove_buyer: "Käufer entfernen",
        remove_seller: "Verkäufer entfernen",
        validate: "Bestätigen",
      },
      download: {
        title: "Dokument herunterladen",
      },
      errors: {
        required: "Dieses Feld ist erforderlich",
      },
      generation: {
        title: "Dokumente generieren in",
        upgrade_action_call: "Abonnementangebote",
        upgrade_placeholder: "Upgraden Sie Ihr Abonnement, um benutzerdefinierte Dateien hinzuzufügen!",
      },
      labels: {
        propertyPlaceName: "Flurname",
        propertyNature: "Natur",
        propertyOccupation: "Bebauung",
        EcoBen: "Wirtschaftlicher Nutznießer",
        actAcquisitionResale: "Erwerb zum Weiterverkauf",
        actBankguaranteeAmount: "Höhe der Bankgarantie",
        actBankguaranteeAmountLetters: "Bankgarantie (ausgeschrieben)",
        actBankguaranteeMonths: "Bankgarantie - Monate",
        actBankguaranteeType: "Bankgarantie - Typ",
        actBeneficiary: "Begünstigter (Bankkonto Inhaber)",
        actBeneficiaryBic: "BIC des Begünstigten",
        actBeneficiaryIban: " IBAN des Begünstigten",
        actCharge: "Gebühren",
        actChargeLettres: "Gebühren (ausgeschrieben)",
        actDate: "Datum der Urkunde",
        actDateAtTheLeast: "Datum der Urkunde (spätestens am)",
        actDeadlineBank: "Frist für die Bankgarantie",
        actEffectiveDate: "Datum der Inbesitznahme",
        actEmail: "E-Mail Sachbearbeiter des Notars",
        actLocality: "Ortschaft des Notars",
        actNotaryLastName: "Familienname des Notars",
        actRentCommission: "Provision (Anzahl der Monate)",
        actSellCommission: "Provision in %",
        refAedmandat: "AED-Referenz Mandat",
        refAedcompromis: "AED-Referenz Kaufvertrag",
        actEstimetedPrice: "GESCHÄTZTER PREIS (PROSPEKTIONSDATEI)",
        actMandatPrice: "MANDATPREIS",
        actSellingPrice: {
          rent: "Monatliche Miete",
          sell: "Verkaufspreis",
        },
        actSellingPriceLetters: {
          rent: "Monatsmiete (in Buchstaben)",
          sell: "Preis (in Buchstaben)",
        },
        sellerType: {
          sell: "Bitte wählen Sie, welche Art von Verkäufer Sie hinzufügen möchten",
          rent: "Bitte wählen Sie, welche Art von Vermieter Sie hinzufügen möchten",
        },
        buyerType: {
          sell: "Bitte wählen Sie, welche Art von Käufer Sie hinzufügen möchten",
          rent: "Bitte wählen Sie, welche Art von Mieter Sie hinzufügen möchten",
        },
        addEcoBen: "Begünstigten hinzufügen",
        benefFirstName: "Vorname",
        benefLastName: "Name",
        birthLocality: "Nationalität",
        buyerAddressCountry: "Land",
        buyerAddressLocality: "Ort",
        buyerAddressNumber: "Nummer",
        buyerAddressStreet: "Straßenname",
        buyerAddressZipCode: "Postleitzahl",
        buyerBirthDate: "Geburtsdatum",
        buyerCivilStatus: "Zivilstand",
        buyerCompanyForm: "Unternehmensform",
        buyerCompanyName: "Name der Firma",
        buyerEMail: "Email",
        buyerEcoBen: "Wirtschaftlicher Nutznießer",
        buyerFirstName: "Vorname",
        buyerIdNum: "Personalausweis- oder Passnummer",
        buyerLastName: "Name",
        buyerLegalRep: "Gesetzlicher Vertreter",
        buyerMatSecSoc: "Sozialversicherungsnummer",
        buyerNationality: "Staatsangehörigkeit",
        buyerNumRc: "Handelsregisternummer",
        buyerNumTva: "Mwst-Identifikationsnummer",
        buyerPhone: "Tel.",
        buyerProf: "Beruf",
        buyerRepAddressCountry: "Land",
        buyerRepAddressLocality: "Ort",
        buyerRepAddressNumber: "Nummer",
        buyerRepAddressStreet: "Straßenname",
        buyerRepAddressZipCode: "Postleitzahl",
        buyerRepEmail: "Email",
        city: "Ort",
        civilStatus: "Zivilstand",
        country: "Land",
        numId: "ID Nummer",
        profession: "Beruf",
        propertyAddressCountry: "Land",
        propertyAddressFrom: "Von",
        propertyAddressLocality: "Ort",
        propertyAddressMunicipal: "Kommune",
        propertyAddressNumber: "Nummer",
        propertyAddressSection: "Sektion",
        propertyAddressStreet: "Straße",
        propertyAddressZipCode: "Postleitzahl",
        propertyAreTer: "Grundstücksgröße (a)",
        propertyBailEmp: "Emphyteotischer Mietvertrag",
        propertyBasement: "Keller (qm)",
        propertyConstructionYear: "Baujahr",
        propertyCpe: "Energiepass",
        propertyEmail: "Email",
        propertyEmpExt: "Anzahl externer Autoplätzen",
        propertyEmpInt: "Anzahl Innenparkplätze",
        propertyHabSousBail: "Objekt unter Mietvertrag",
        propertyLot: "Zuteilung(en)",
        propertyNrbBedrom: "Anzahl Zimmer",
        propertyNumCad: "Katasternummer",
        propertyServ: "Servitut",
        propertySitHyp: "Hypothekenstatus",
        propertySurHab: "Bewohnbare Fläche (qm)",
        propertyTerBal: "Terrasse, Balkon (qm)",
        propertyType: "Typ",
        secSoc: "Sozialversicherungsnummer",
        sellerAddressCountry: "Land",
        sellerAddressLocality: "Ort",
        sellerAddressNumber: "Nummer",
        sellerAddressStreet: "Straße",
        sellerAddressZipCode: "Postleitzahl",
        sellerBirthDate: "Geburtstag (TT-MM-JJJJ)",
        sellerCivilStatus: "Zivilstand",
        sellerCompanyForm: "Unternehmensform",
        sellerCompanyName: "Name der Firma",
        sellerEMail: "Email",
        sellerFirstName: "Vorname",
        sellerIdNum: "Personalausweis- oder Passnummer",
        sellerLastName: "Name",
        sellerLegalRep: "Gesetzlicher Vertreter",
        sellerMatSecSoc: "Sozialversicherungsnummer",
        sellerNationality: "Staatsangehörigkeit",
        sellerNumRc: "Handelsregisternummer",
        sellerNumTva: "Mwst-Identifikationsnummer",
        sellerPhone: "Tel.",
        sellerProf: "Beruf",
        sellerRepAddressCountry: "Land",
        sellerRepAddressLocality: "Ort",
        sellerRepAddressNumber: "Nummer",
        sellerRepAddressStreet: "Straße",
        sellerRepAddressZipCode: "Postleitzahl",
        sellerRepEmail: "Email",
        streetName: "Straßenname",
        streetNumber: "Hausnummer",
        zipCode: "Postleitzahl",
        buyerLabel: "",
        sellerLabel: "",
      },
      modal: {
        continue_button: "Nein, Formular vollständigen",
        generate_button: "Ja, Dokument hertunterladen",
        placeholder: "Achtung, das Formular ist unvollständig. Sind Sie sicher, dass sie schon ein Dokument erstellen wollen?",
        title: "Bestätigung",
      },
      options: {
        status: {
          pr: "Potent. Kunde",
          ma: "Mandat",
          co: "Kompromiss",
          bo: "Bank OK",
          af: "Aktdatum festgelegt",
          ac: "Aktiert",
          pe: "Verloren",
          rent: {
            pr: "Potent. Kunde",
            ma: "Mandat signé",
            co: "Mietvertrag unterz.",
            bo: "Stand der Dinge",
            af: "Abgerechnet",
            ac: "Bezahlt",
            pe: "Verloren",
          },
          sell: {
            pr: "Potent. Kunde",
            ma: "Mandat",
            co: "Kompromiss",
            bo: "Bank OK",
            af: "Aktdatum festgelegt",
            ac: "Aktiert",
            pe: "Verloren",
          },
        },
        CompanySeller: "Unternehmen",
        apartment: "Appartement",
        commerce: "Handel",
        corner_house: "Eckhaus",
        detached_house: "Einzelhaus",
        divorced: "Geschieden",
        duplex: "Duplex",
        garage: "Garage",
        ground: "Grundstück",
        land: "Grundstück",
        loft: "Loft",
        married: "Verheiratet",
        non: "Nein",
        office: "Büro",
        penthouse: "Penthouse",
        privateSeller: "Person",
        row_house: "Reihenhaus",
        semi_detached_house: "Doppelhaushälfte",
        single: "Unverheiratet",
        studio: "Studio",
        triplex: "Triplex",
        widowed: "Verwitwet",
        yes: "Ja",
        mr: "Herr",
        mrs: "Frau",
      },
      submit: "Speichern",
      title: "Kundendateien",
      requirements: "Benötigt für",
      invite: {
        button_action: "Einladen",
        email_placeholder: "Füllen Sie bitte jeweils 1 Ziel-E-Mail aus",
        placeholder:
          "Hier können Sie Kunden und den Notar einladen, auf ihre Registerkarte zuzugreifen, damit sie ihre jeweiligen Daten selbst eingeben können. Jede Person, die Sie einladen, hat nur Zugriff auf die jeweilige Registerkarte, d.H. die Verkäufer haben nur Zugriff auf die Registerkarte aller Verkäufer usw. Nutzen Sie diese großartige Funktionalität, um Ihre Arbeitsbelastung zu reduzieren und die erforderlichen Informationen so schnell wie möglich zu erhalten. Bitte laden Sie die Benutzer einzeln ein und wählen Sie jedes Mal die Registerkarte, auf die sie Zugriff erhalten sollen.",
        title: "Kunden oder Notar einladen um Daten hinzuzufügen",
        give_access: "Auf welche Registerkarte wollen sie Zugriff gewähren?",
        email_label: "E-Mail der Person, die Sie einladen möchten",
      },
    },
    reset: {
      placeholders: {
        password: "Reset Password",
        password_confirmation: "Passwort Bestätigung",
      },
      submit: "Zurücksetzen",
    },
    signin: {
      errors: {
        login: "Bitte überprüfen Sie Ihre E-Mail / Passwort",
      },
      placeholders: {
        email: "Email",
        password: "Passwort",
      },
      submit: "Einloggen",
      validations: {
        email: "E-Mail ungültig",
        email_req: "E-Mail ist erforderlich",
        password: "Passwort wird benötigt",
      },
    },
    signup: {
      errors: {
        register: "Bitte überprüfen Sie Ihre E-Mail / Passwort",
      },
      placeholders: {
        email: "Email",
        password: "Passwort",
        password_confirmation: "Passwort Bestätigung",
      },
      submit: "Benutzerkonto anlegen",
      labels: {
        cgu: "J'ai lu et j'accepte les <a href='/cgu' target='_blank'>CGU</a>",
        cgv: "Ich habe die Allgemeinen Verkaufs- und <a href='/cgv' target='_blank'>Nutzungsbedingungen gelesen und akzeptiere sie.</a>",
      },
      validations: {
        confirmation: "Falsche Bestätigung",
        email: "E-Mail ungültig",
        email_req: "E-Mail ist erforderlich",
        password: "Das Passwort muss mindestens 1 Kleinbuchstabe, 1 Großbuchstabe, 1 numerisches Zeichen, 1 Sonderzeichen enthalten und mindestens 6 Zeichen lang sein.",
        password_confirmation_req: "Passwortbestätigung ist erforderlich",
        password_req: "Passwort wird benötigt",
        cgu: "Cgu is required",
        cgv: "Cgv is required",
      },
    },
  },
  homepage: {
    advantages: {
      placeholder: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      rent: "Location",
      sell: "Vente",
      title: "<span>Documents en</span> <span>2 clicks</span>",
    },
    comparison: {
      action_call: "Découvrez nos formules àpd x EUR/mois",
      before: "Aujourd’hui",
      negatif: {
        first: "Vous perdez du temps inutile à saisir de manière répétitive les mêmes informations…",
        second: "dans tous les documents… ",
        sub_first: "(noms, adresses, professions, nationalité, carte d’identité, matricule des vendeurs et des acheteurs ; prix ; adresse et détails du bien ; notaire etc)",
        sub_second:
          "(déclaration de provenance des fonds acheteur,  déclaration de provenance des fonds vendeur, fiche d’analyse de risque, compromis, attestation de sincérité, facture,…)",
        third: "et à contrôler si vous avez bien rempli toutes les obligations légales pour éviter des amendes.",
      },
      now: "Demain, avec ImmoAML",
      placeholder: "Le temps est ce que vous avez de plus précieux. Ne le gaspillez pas !",
      positif: {
        first: "Saisissez une seule fois toutes les données",
        more: "et soyez sûr d’être irréprochable en cas de contrôle.",
        second: "Générez tous les documents en 2 clicks, au bon moment",
        third: "Accédez facilement à toutes les informations actuelles et historiques",
      },
    },
    concept: {
      body:
        "<p>Conçu en étroite collaboration avec des professionnels de l’immobilier.</p><p>IMMOAML gère tous vos documents administratifs en 2 clicks, en 3 langues, sur base de modèles juridiquement validés.</p>",
      title: "<span>Titel</span> <span>Concept</span>",
    },
    contact: {
      information: "information",
      title: "Contact",
    },
    intro: {
      body:
        "<p>Le travail administratif est le volet le plus agaçant pour tout bon agent immobilier.</p><p>Malheureusement, les contraintes légales deviennent de plus en plus envahissantes et imposent du travail rébarbatif et inutile</p>",
      title: "<span>Title</span> <span>Intro</span>",
    },
    sliders: {
      action_call: "Facilitez-vous la vie !",
      slider1: {
        list:
          "<ul><li>de remplir jusqu’à 7 fois les mêmes données ?</li><li>de perdre inutilement du temps précieux ?</li><li>de vérifier constamment que vous respectez toutes les formalités ?</li></ul>",
        title: "<span>Vous n’en avez </span><span> pas aussi assez…</span>",
      },
      slider2: {
        list:
          "<ul><li>Economisez des heures de travail administratif</li><li>Ne risquez plus d’amende en cas de contrôle</li><li>Garantissez à vos clients des documents de qualité en 3 langues</li></ul>",
        title: "<span>A partir de </span><span> seulement X eur/mois</span>",
      },
      slider3: {
        list:
          "<ul><li>Stop au travail administratif rébarbatif</li><li>Réduisez considérablement votre charge de travail</li><li>Gagnez en productivité et en temps libre</li></ul>",
        title: "<span>Arrêtez de gaspiller </span><span> votre temps !</span>",
      },
      slider4: {
        list:
          "<ul><li>Générez tous les documents administratifs et légaux en 2 clicks</li><li>Assurez une qualité exemplaire à vos clients</li><li>Soyez serein en cas de contrôle</li></ul>",
        title: "<span>Soyez au Top </span><span> de la qualité administrative, sans efforts !</span>",
      },
    },
    subscriptions: {
      placeholder:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
      title: "<span>Nos</span> <span>abonnements</span> ",
    },
    testimonials: {
      testimonials_1: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Bennani Ayoub",
        profession: "Software developper",
      },
      testimonials_2: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Marc Neuen",
        profession: "CEO & Founder, Creativeracer",
      },
      testimonials_3: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Julien Gras",
        profession: "CTO and dakchi",
      },
      title: "<span>What clients</span> <span>say</span>",
    },
    usp: {
      action: "Souscrivez à la formule de votre choix.",
      placeholder: "Evitez les amendes en cas de contrôle. Assurez-vous que vos documents soient irréprochables. ",
      usp_1: {
        body: "Saisissez une seule fois chaque info pour alimenter tous les documents requis.",
        title: "Usp title 1",
      },
      usp_2: {
        body: "Tous les documents sont validés juridiquement et disponibles en 3 langues.",
        title: "Usp title 2",
      },
      usp_3: {
        body: "Adaptez facilement les documents selon les besoins spécifiques du dossier et utilisez vos propres modèles personnalisés.",
        title: "Usp title 3",
      },
      usp_4: {
        body: "Accédez à tout moment à toutes les données et à tous les documents…  en cours et  historiques.",
        title: "Usp title 4",
      },
      usp_5: {
        body: "Toutes les données sensibles sont encryptées. Juste vous y avez accès.",
        title: "Usp title 5",
      },
    },
  },
  languages: {
    de: "Deutsch",
    en: "English",
    fr: "Français",
  },
  mailbox: {
    new: {
      errors: {
        body: "Bitte schreiben Sie eine Nachricht",
        participants: "Adressat(en) auswählen",
        subject: "Bitte geben Sie ein Titel ein",
      },
      participants: "Teilnehmer",
      placeholder: "Schreiben Sie eine neue Nachricht von",
      receivers: {
        agency: "Agence",
        buyers: "Käufer",
        notary: "Notar",
        owner: "Besitzer",
        sellers: "Verkäufer",
      },
      subject: "Titel",
    },
  },
  navbar: {
    account: {
      custom_files: "Vorlagen",
      folders: "Meine Dateien",
    },
    subscription: "Abonnement",
  },
  navigation: {
    tabs: {
      property: "Immobilie",
      rent: {
        act: "Mietvertrag",
        buyer: "Mieter",
        property: "Immobilie",
        seller: "Vermieter",
        documents: "Documents",
      },
      sell: {
        act: "Urkunde",
        buyer: "Käufer",
        property: "Immobilie",
        seller: "Verkäufer",
        documents: "Documents",
      },
    },
  },
  payment: {
    detail: {
      delay: "Delay : ",
      from: "Gültigkeitsbeginn :",
      periodPrice: "Total : ",
      price: "Fakturierter Preis :",
      to: "An:",
      by_month: "/ monate",
      months: "monate",
    },
  },
  redirect: {
    to_signin: "Einloggen",
    to_signin_txt: "Sie haben schon ein Konto ?",
    to_signup: "Anmelden",
    to_signup_txt: "Sie haben noch kein Konto?",
  },
  rent: {
    title: "Neue Mietdatei",
  },
  rgpd: "GDPR",
  sell: {
    title: "Neue Verkaufsdatei",
  },
  subscriptions: {
    end_subscription: "Abonnement beenden",
    reactivate: "Starten Sie das Abonnement neu",
    solde: false,
    modal: {
      close: "Schließen",
      end: "ich bestätige",
      placeholder: "Sind Sie wirklich sicher, Ihr Abonnement beenden zu wollen?",
      title: "Bestätigung",
    },
    names: {
      r_2y: "Basic - Mietdatei 24 monate",
      r_6: "Basic - Mietdatei 6 monate ",
      r_12: "Basic - Mietdatei 12 monate",
      s_2y: "Basic - Verkaufsdatei 24 monate",
      srm_2y: "Premium - 24 monate",
      srm_6: "Premium - 6 monate",
      srm_12: "Premium - 12 monate",
      sr_2y: "Standard- 24 monate",
      sr_6: "Standard- 6 monate",
      sr_12: "Standard- 12 monate",
      s_6: "Basic - Verkaufsdatei 6 monate",
      s_12: "Basic - Verkaufsdatei 12 monate",
    },
    new: "Wählen Sie ihr Abo",
    orders: {
      activeStatus: {
        false: "Abgelaufen",
        true: "Aktiv",
      },
      header: {
        OrderEnd: "Endtermin",
        OrderStart: "Anfangsdatum",
        Orderstatus: "Status",
        orderTitle: "Titel",
      },
    },
    periodPrice: "Preis der Periode",
    reduction: {
      twoyears: "1-Monats Rabatt",
      sixMonths: "6-Monats Rabatt",
      twelveMonths: "12-Monats Rabatt",
    },
    rent: {
      option1: {
        title: "Nur VERKAUFS-Dateien",
        valid: false,
      },
      option2: {
        title: "Nur MIET-Dateien",
        valid: true,
      },
      option3: {
        title: "Benutzerdefinierte Dateien",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "r_2y",
          formula: "24 monate",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "r_6",
          formula: "6 monate",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "r_12",
          formula: "12 monate",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - nur Miete",
    },
    select: "Wählen",
    sell: {
      option1: {
        title: "Nur VERKAUFS-Dateien",
        valid: true,
      },
      option2: {
        title: "Nur MIET-Dateien",
        valid: false,
      },
      option3: {
        title: "Benutzerdefinierte Dateien",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "s_2y",
          formula: "24 monate",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "s_6",
          formula: "6 Monate",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "s_12",
          formula: "12 monate",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - nur Verkauf",
    },
    sell_rent: {
      option1: {
        title: "Nur VERKAUFS-Dateien",
        valid: true,
      },
      option2: {
        title: "Nur MIET-Dateien",
        valid: true,
      },
      option3: {
        title: "Archivierte Dateien",
        valid: true,
      },
      option4: {
        title: "-",
        valid: false,
      },
      option5: {
        title: "-",
        valid: false,
      },
      option6: {
        title: "-",
        valid: false,
      },
      option7: {
        title: "-",
        valid: false,
      },
      option8: {
        title: "-",
        valid: false,
      },
      option9: {
        title: "-",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "sr_2y",
          formula: "24 monate",
          delay: 24,
          periodPrice: 1176,
          price: 49,
        },
        sixMonths: {
          code: "sr_6",
          formula: "6 monate",
          delay: 6,
          periodPrice: 474,
          price: 79,
        },
        twelveMonths: {
          code: "sr_12",
          formula: "12 monate",
          delay: 12,
          periodPrice: 828,
          price: 69,
        },
      },
      title: "STANDARD",
    },
    sell_rent_media: {
      option1: {
        title: "Nur VERKAUFS-Dateien",
        valid: true,
      },
      option2: {
        title: "Nur MIET-Dateien",
        valid: true,
      },
      option3: {
        title: "Archivierte Dateien",
        valid: true,
      },
      option4: {
        title: "Personalisierung der vorhandenen Vorlagen",
        valid: true,
      },
      option5: {
        title: "Hochladen neuer Vorlagen",
        valid: true,
      },
      option6: {
        title: "Informationseingabe durch Kunden",
        valid: true,
      },
      option7: {
        title: "Hochladen und Speichern von Dokumenten (Mai)",
        valid: true,
      },
      option8: {
        title: "Messaging",
        valid: true,
      },
      option9: {
        title: "Elektronische Unterschriften (Juni)",
        valid: true,
      },
      periods: {
        twoyears: {
          code: "srm_2y",
          formula: "24 monate",
          delay: 24,
          periodPrice: 1656,
          price: 69,
        },
        sixMonths: {
          code: "srm_6",
          formula: "6 monate",
          delay: 6,
          periodPrice: 594,
          price: 99,
        },
        twelveMonths: {
          code: "srm_12",
          formula: "12 monate",
          delay: 12,
          periodPrice: 1068,
          price: 89,
        },
      },
      title: "PREMIUM",
    },
    title: "Abonnement",
    upgrade: "Abonnement-Upgrade",
  },
  title: "titel en",
};
