import React, { useState } from "react";
import useForm from "react-hook-form";
import Translate from "react-translate-component";
import counterpart from "counterpart";

function SignUpForm(props) {
  const { handleSubmit, register, errors, setError } = useForm();
  const [values, setValues] = useState({ password: "", role: "agency" });
  const [cgu, setCgu] = useState(false);
  const [cgv, setCgv] = useState(false);
  const [gpdr, setGpdr] = useState(false);
  const handleInputGpdr = () => {
    setGpdr(!gpdr);
  };

  const handleInputCgu = () => {
    setCgu(!cgu);
  };
  const handleInputCgv = () => {
    setCgv(!cgv);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = () => {
    props.submitRegister(values);
  };
  const ph_email = counterpart.translate("forms.signup.placeholders.email");
  const ph_password = counterpart.translate("forms.signup.placeholders.password");
  const ph_password_confirmation = counterpart.translate("forms.signup.placeholders.password_confirmation");

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      {!props.successRegister && (
        <>
          <div className="flex-row">
            <label className="lf--label" htmlFor="email">
              <svg x="0px" y="0px" width="12px" height="13px">
                <path
                  fill="#B1B7C4"
                  d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"
                />
              </svg>
            </label>
            <input
              id="email"
              className="lf--input"
              placeholder={ph_email}
              type="text"
              name="email"
              onChange={handleInputChange}
              ref={register({
                required: "forms.signup.validations.email_req",
                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "forms.signup.validations.email" },
              })}
            />
          </div>
          {errors.email && <Translate content={errors.email.message} component="p" className="text-danger xs-text" />}

          <div className="flex-row mt-4">
            <label className="lf--label" htmlFor="password">
              <svg x="0px" y="0px" width="15px" height="5px">
                <g>
                  <path
                    fill="#B1B7C4"
                    d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"
                  />
                </g>
              </svg>
            </label>
            <input
              id="password"
              className="lf--input"
              placeholder={ph_password}
              type="password"
              name="password"
              onChange={handleInputChange}
              ref={register({
                required: "forms.signup.validations.password_req",
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&*/.])(?=.{6,})/,
                  message: "forms.signup.validations.password",
                },
              })}
            />
          </div>
          {errors.password && <Translate content={errors.password.message} component="p" className={`text-danger xs-text`} />}
          {!errors.password && <Translate content="forms.signup.validations.password" component="p" className="xs-text" />}

          <div className="flex-row mt-4">
            <label className="lf--label" htmlFor="password-confirmation">
              <svg x="0px" y="0px" width="15px" height="5px">
                <g>
                  <path
                    fill="#B1B7C4"
                    d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"
                  />
                </g>
              </svg>
            </label>
            <input
              id="password-confirmation"
              className="lf--input"
              placeholder={ph_password_confirmation}
              type="password"
              name="passwordConfirmation"
              ref={register({ required: "forms.signup.validations.password_confirmation_req" })}
              onChange={async (e) => {
                const value = e.target.value;
                if (value === values.password) {
                  setError("passwordConfirmation");
                } else {
                  setError("passwordConfirmation", "notMatch", "forms.signup.validations.confirmation");
                }
              }}
            />
          </div>
          {errors.passwordConfirmation && <Translate content={errors.passwordConfirmation.message} component="p" className="text-danger xs-text" />}

          <div className="flex-row mt-4">
            <div className="row rgpd">
              <div className="col-12 mt-2 pb-3">
                <div className="radio pl-2">
                  <label className="radio-container">
                    <Translate content="forms.agency.labels.rgpd" unsafe />
                    <input type="radio" name="gpdr" defaultValue={gpdr} defaultChecked={gpdr} onClick={handleInputGpdr} ref={register({ validate: (value) => value === "true" })} />
                    <span className={`checkmark ${gpdr ? "checked" : "unchecked"}`}></span>
                  </label>
                </div>
                {errors.gpdr && <Translate content="forms.agency.validation.required" component="p" className="text-danger xs-text" />}
              </div>
            </div>
          </div>
          <div className="flex-row mt-4">
            <div className="row rgpd">
              <div className="col-12 mt-2 pb-3">
                <div className="radio pl-2">
                  <label className="radio-container">
                    <Translate content="forms.signup.labels.cgv" unsafe />
                    <input type="radio" name="cgv" defaultValue={cgv} defaultChecked={cgv} onClick={handleInputCgv} ref={register({ validate: (value) => value === "true" })} />
                    <span className={`checkmark ${cgv ? "checked" : "unchecked"}`}></span>
                  </label>
                </div>
                {errors.cgv && <Translate content="forms.signup.validations.cgv" component="p" className="text-danger xs-text" />}
              </div>
            </div>
          </div>

          {props.errorRegister && <Translate content="forms.signup.errors.register" component="p" className="text-danger xs-text mt-1" />}

          <Translate component="input" attributes={{ value: "forms.signup.submit" }} type="submit" className="lf--submit mt-4" />
        </>
      )}

      {props.successRegister && <p> Bravo </p>}
    </form>
  );
}

export default SignUpForm;
