import React from "react";
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';

class Load extends React.Component {
 //other logic
   render() {
    return(
      <Loader
        className='subscriptionLoader'
        type="Grid"
        color="#fff"
        height={100}
        width={100}
        visible={this.props.loading}
        />
    );
   }
}


const mapStateToProps = (state) => ({
  loading: state.loadReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Load);
