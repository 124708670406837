import React, { useState } from "react";
import Translate from "react-translate-component";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

function OrderLine({ loadingUnsubscribe, order, endSubscription, reactivate }) {
  const [showModal, setShowModal] = useState(false);

  const unSubscription = () => {
    endSubscription(order.id);
    setShowModal(false);
  };

  return (
    <tr className={`table-${order.status ? "success" : "danger"}`}>
      {console.log(order)}
      <th scope="row">
        <Translate content={`subscriptions.names.${order.orderName}`} />
      </th>
      <td>{Moment(order.orderStart).format("YYYY-MM-DD")}</td>
      <td>
        <Translate content={`subscriptions.orders.activeStatus.${order.status}`} />
      </td>

      <td>
        <Loader type="ThreeDots" color="#0575e6" height={25} width={50} visible={loadingUnsubscribe} className="upload-spinner" />

        {!loadingUnsubscribe && order.orderEnd === null && (
          <div className="end-subscription" onClick={() => setShowModal(true)}>
            <Translate content="subscriptions.end_subscription" />
          </div>
        )}
        {!loadingUnsubscribe && !(order.orderEnd === null) && Moment(order.orderEnd).format("YYYY-MM-DD")}
        {!loadingUnsubscribe && order.status && order.subscriptionStatus === "ended" && (
          <span className="ml-2 end-subscription" onClick={() => reactivate(order.id)}>
            <Translate content="subscriptions.reactivate" />
          </span>
        )}
      </td>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate content="subscriptions.modal.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-2">
              <Translate content="subscriptions.modal.placeholder" component="p" />
            </div>

            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => unSubscription()}>
                <Translate content="subscriptions.modal.end" />
              </button>
            </div>
            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => setShowModal(false)}>
                <Translate content="subscriptions.modal.close" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </tr>
  );
}

export default OrderLine;
