import React, { useState } from "react";
import Translate from "react-translate-component";
import { ResponsiveBar } from "@nivo/bar";
import useAxios from "axios-hooks";
import Loader from "react-loader-spinner";
import counterpart from "counterpart";

function Chart({ chartType, folderType, year }) {
  const [{ data, loading }] = useAxios({
    url: "/insights/fetch",
    method: "POST",
    data: { chartType: chartType, folderType: folderType, folderYear: year },
  });
  const keysLabel = (key) => {
    return counterpart.translate(`forms.folder.options.status.${folderType}.${key}`);
  };

  return (
    <div className="chart-item">
      {loading && (
        <div className="loading">
          <Loader type="Puff" color="#0575e6" height={25} width={50} visible={true} className="spinner" />
        </div>
      )}
      {!loading && (
        <>
          <div>
            <Translate content={`insight.${chartType}`} className="chart-title" />
          </div>

          <div className="chart">
            <ResponsiveBar
              data={data.insights
                .filter((x) => x.label !== "Total")
                .map((x) => {
                  return { [chartType]: x.label, [keysLabel(x.label)]: x.value };
                })}
              keys={[keysLabel("pe"), keysLabel("ac"), keysLabel("af"), keysLabel("bo"), keysLabel("co"), keysLabel("ma"), keysLabel("pr")]}
              indexBy={chartType}
              margin={{ top: 0, right: 110, bottom: 50, left: 2 }}
              padding={0.1}
              layout="horizontal"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              isInteractive={false}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 0,
                tickRotation: 0,
                legend: `${counterpart.translate("total")} : ${data.insights.filter((x) => x.label === "Total")[0].value}`,
                legendPosition: "middle",
                legendOffset: 40,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Chart;
