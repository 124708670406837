import React from "react";
import Translate from "react-translate-component";
import ChartContainer from "./insights/chartContainer";

class InsightsContainer extends React.Component {
  render() {
    return (
      <div className="agency-container col-12">
        <div className="py-3">
          <Translate content="forms.agency.insights" component="h5" className="agency-title" />
        </div>

        <ChartContainer />
      </div>
    );
  }
}

export default InsightsContainer;
