import React, { Component } from "react";
import MailBoxContainer from "../../mailBox/mailBoxContainer";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/actions/conversationActions";

// check if user has acces to the folder

class mailBox extends Component {
  componentDidMount() {
    var folderId = this.props.match.params.folderId;
    this.props.getConversations(folderId);
  }

  createConversation = (values) => {
    var folderId = this.props.match.params.folderId;
    this.props.newConversation({ ...values, folderId: folderId });
  };
  getConversation = (id) => {
    var folderId = this.props.match.params.folderId;
    this.props.getConversation(id, folderId);
  };

  render() {
    return (
      <div className="mailBox app">
        <div className="app__content">
          <div className="accounts"></div>
          <div className="menu"></div>

          <MailBoxContainer
            createConversation={this.createConversation}
            conversations={this.props.conversations}
            conversation={this.props.conversation}
            getConversation={this.getConversation}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  conversations: state.conversationReducer.conversations,
  conversation: state.conversationReducer.conversation,
});

const mapDispatchToProps = (dispatch) => ({
  newConversation: (values) => dispatch(actionCreator.newConversation(values)),
  getConversations: (folderId) => dispatch(actionCreator.getConversations(folderId)),
  getConversation: (conversationId, folderId) => dispatch(actionCreator.getConversation(conversationId, folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(mailBox);
