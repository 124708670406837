import React from "react";

function Participants() {
  return (
    <div className="participants-infos">
      <div className="menu-tags">
        <li className="menu-tags__item">
          <span className="dot dot--agency"></span>
          <span className="menu-tags__label">Agency</span>
        </li>
        <li className="menu-tags__item">
          <span className="dot dot--sellers"></span>
          <span className="menu-tags__label">Sellers</span>
        </li>
        <li className="menu-tags__item">
          <span className="dot dot--buyers"></span>
          <span className="menu-tags__label">Buyers</span>
        </li>
        <li className="menu-tags__item">
          <span className="dot dot--notary"></span>
          <span className="menu-tags__label">notary</span>
        </li>
      </div>
    </div>
  );
}

export default Participants;
