import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/actions/authActions';
import SignUpForm from './forms/sign_up_form';
import Translate from 'react-translate-component';

class SignIn extends React.Component {
  submitRegister = (values) => {
    this.props.registerUser(values);
  }

  render(){
    return (
      <div className='authentification'>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <Translate content='authentication.signup_title' className='auth-title mt-3 mb-2' component='div' />
            <div className='blue-line' />
            <SignUpForm submitRegister={this.submitRegister} errorRegister={this.props.errorRegister} successRegister={this.props.successRegister}/>
            <Link to='/sign_in' className='lf--forgot'>
              <Translate content="redirect.to_signin_txt" className='redirect-text' />
              <Translate content="redirect.to_signin" />
            </Link>
          </div>

          <div className='col-md-6 col-sm-12 bg-auth'>
            <div className='img signup'></div>
            <div className='img-bg'></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorRegister: state.authReducer.errorRegister,
  successRegister: state.authReducer.successRegister,
});

const mapDispatchToProps = (dispatch) => ({
  registerUser: (values) => dispatch(actionCreator.registerUser(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
