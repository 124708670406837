import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <section id="footer">
      <div className="">
        <div className="col-12 green-bg">
          <div className="row text-center text-xs-center text-sm-left text-md-left">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="footer-title">© 2020 - OREA - Optimised Real Estate Administration</div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="footer-title">Nous contacter</div>
              <ul className="list-unstyled quick-links">
                <li>
                  <span>55, rue de Luxembourg, L-4391 Pontpierre</span>
                </li>
                <li>
                  <span>+352 26 57 07 250</span>
                </li>
                <li>
                  <a href="mailto:info@orea.immo">
                    <span>info@orea.immo</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="footer-title">Mentions légales</div>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="/cgu">
                    <span>Conditions générales d’utilisation</span>
                  </a>
                </li>
                <li>
                  <a href="/cgv">
                    <span>Conditions générales de vente</span>
                  </a>
                </li>
                <li>
                  <span>RCSL : B143.207</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
