import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { millionWord } from "./helpers";
import Navigation from "./navigation";
import Translate from "react-translate-component";
import InputGroup from "./inputGroup";
import ActionButtons from "./actionButtons";
import GenerationButtons from "./generationButtons";
import DownloadDocument from "./downloadDocument";
import NewDocuments from "./upload/newDocuments";
import SumuryDocument from "./upload/summaryDocuments";
import Switch from "react-switch";
import { emptyBeneficiary } from "./data/emptyData";
import useForm, { FormContext } from "react-hook-form";
import counterpart from "counterpart";

function Form({
  agencyCountry,
  submitFolder,
  initValues,
  type,
  propertyData,
  sellerData,
  buyerData,
  actData,
  emptySellerData,
  emptyBuyerData,
  edit,
  generateDocument,
  loading,
  documentFile,
}) {
  const [activeTab, setActiveTab] = useState(window.location.hash.substr(1) || "tab1");
  const [values, setValues] = useState(initValues);
  const [docIndex, setDocIndex] = useState(-1);
  const [requirements, setRequirements] = useState(false);
  const [documentLanguage, setDocumentLanguage] = useState("fr");
  const [sellerModal, setSellerModal] = useState();
  const [sellerBenefModal, setSellerBenefModal] = useState();
  const [buyerModal, setBuyerModal] = useState();
  const [buyerBenefModal, setBuyerBenefModal] = useState();
  const [uid, setUid] = useState(1);

  const [beneficiary] = useState(emptyBeneficiary);

  const methods = useForm();
  const { handleSubmit } = methods;

  const changeTab = (tab) => {
    setActiveTab(tab);
    window.location.hash = tab;
    window.scrollTo(0, 0);
  };
  const activeKlass = (tab) => {
    return activeTab === tab ? "active" : "";
  };
  const selectOption = (code) => {
    return (
      <option value={code} key={code}>
        {counterpart.translate("forms.folder.options." + code)}{" "}
      </option>
    );
  };
  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    var vals = { ...values };

    const prevObj = () => {
      vals[section][name] = value;
      if (type === "sell" && name === "propertyAddressLocality") {
        vals[section]["propertyAddressFrom"] = value;
      }
      if (name === "actSellingPrice") {
        vals.act.actSellingPriceLetters = millionWord(vals.act.actSellingPrice);
      }
      if (name === "actBankguaranteeAmount") {
        vals.act.actBankguaranteeAmountLetters = millionWord(vals.act.actBankguaranteeAmount);
      }
      if (name === "actCharge") {
        vals.act.actChargeLettres = millionWord(vals.act.actCharge);
      }
      if (
        type === "rent" &&
        section === "act" &&
        (name === "actSellingPrice" || name === "actBankguaranteeMonths") &&
        vals.act.actSellingPrice !== null &&
        vals.act.actBankguaranteeMonths !== null
      ) {
        vals.act.actBankguaranteeAmount = vals.act.actSellingPrice * vals.act.actBankguaranteeMonths;
        vals.act.actBankguaranteeAmountLetters = millionWord(vals.act.actSellingPrice * vals.act.actBankguaranteeMonths);
      }
      return vals;
    };

    setValues(prevObj);
  };
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  const handleSellerInputChange = (e, index, section) => {
    const { name, value } = e.target;
    var vals = { ...values };

    const prevObj = () => {
      vals[section] = vals[section].map((s, i) => {
        if (i === index) {
          s[name] = value;
          if (name === "sellerMatSecSoc" && s.sellerMatSecSoc.length > 7) {
            var d = s.sellerMatSecSoc;
            var yyyy = d.substring(0, 4);
            var mm = d.substring(4, 6);
            var dd = d.substring(6, 8);
            if (isValidDate(new Date(yyyy + "-" + mm + "-" + dd))) {
              s.sellerBirthDate = new Date(yyyy + "-" + mm + "-" + dd);
            }
          }
          if (name === "buyerMatSecSoc" && s.buyerMatSecSoc.length > 7) {
            var d = s.buyerMatSecSoc;
            var yyyy = d.substring(0, 4);
            var mm = d.substring(4, 6);
            var dd = d.substring(6, 8);
            if (isValidDate(new Date(yyyy + "-" + mm + "-" + dd))) {
              s.buyerBirthDate = new Date(yyyy + "-" + mm + "-" + dd);
            }
          }
          return s;
        }
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };
  const CopySellerAdress = (e, index) => {
    index === 0 ? copyPropretyAddress(index) : copySellerAddress(index);
  };

  const copyPropretyAddress = (index) => {
    console.log("ici", index);
    var vals = { ...values };
    const prevObj = () => {
      vals.sellers = vals.sellers.map((s, i) => {
        if (i === index)
          return {
            ...s,
            sellerAddressNumber: vals.property.propertyAddressNumber,
            sellerAddressStreet: vals.property.propertyAddressStreet,
            sellerAddressZipCode: vals.property.propertyAddressZipCode,
            sellerAddressLocality: vals.property.propertyAddressLocality,
            sellerAddressCountry: vals.property.propertyAddressCountry,
          };
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };

  const copySellerAddress = (index) => {
    var vals = { ...values };
    const prevObj = () => {
      vals.sellers = vals.sellers.map((s, i) => {
        if (i === index)
          return {
            ...s,
            sellerAddressNumber: vals.sellers[0].sellerAddressNumber,
            sellerAddressStreet: vals.sellers[0].sellerAddressStreet,
            sellerAddressZipCode: vals.sellers[0].sellerAddressZipCode,
            sellerAddressLocality: vals.sellers[0].sellerAddressLocality,
            sellerAddressCountry: vals.sellers[0].sellerAddressCountry,
          };
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };

  const copyBuyerAddress = (e, index) => {
    var vals = { ...values };
    const prevObj = () => {
      vals.buyers = vals.buyers.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            buyerAddressNumber: vals.buyers[0].buyerAddressNumber,
            buyerAddressStreet: vals.buyers[0].buyerAddressStreet,
            buyerAddressZipCode: vals.buyers[0].buyerAddressZipCode,
            buyerAddressLocality: vals.buyers[0].buyerAddressLocality,
            buyerAddressCountry: vals.buyers[0].buyerAddressCountry,
          };
        }
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };

  const setDate = (date, name, section) => {
    setValues({ ...values, [section]: { ...values[section], [name]: date } });
  };
  const setDateWithIndex = (date, name, section, index) => {
    var vals = values[section];
    vals[index][name] = date;
    setValues({ ...values, [section]: [...vals] });
  };
  const changeLanguage = (lang) => {
    setDocumentLanguage(lang);
  };
  const addSeller = () => {
    var sellerData = { ...emptySellerData };
    setValues({ ...values, sellers: [...values.sellers, sellerData] });
  };
  const addBuyer = () => {
    var buyerData = { ...emptyBuyerData };
    setValues({ ...values, buyers: [...values.buyers, buyerData] });
  };
  const remove = (index, section) => {
    var vals = { ...values };

    const prevObj = () => {
      vals[section] = vals[section].map((s, i) => {
        if (i === index) return { ...s, remove: true };
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };

  const generateDoc = (docId) => {
    setDocIndex(docId);
    generateDocument(docId, documentLanguage);
  };
  const onSubmit = (data) => {
    submitFolder(values);
  };
  const addSellerBeneficiary = (index, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var copyBeneficiary = { ...beneficiary };

    seller[benefSection] = [...seller[benefSection], copyBeneficiary];
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };
  const handleBenefChange = (e, index, benefIndex, section, benefSection) => {
    const { name, value } = e.target;

    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef[name] = value;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };
  const handleBenefValidation = (validation, index, benefIndex, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef.validated = validation;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };

  const handleValidation = (validation, index, section) => {
    var vals = { ...values };

    const prevObj = () => {
      vals[section] = vals[section].map((s, i) => {
        if (i === index) {
          s.validated = validation;
          return s;
        }
        return s;
      });
      return vals;
    };
    setValues(prevObj);
  };
  const badge = (val) => {
    return val === 0 ? "feelfree" : "required";
  };

  const handleBenefRemove = (index, benefIndex, section, benefSection) => {
    var sellers = values[section];
    var seller = sellers[index];
    var benef = seller[benefSection][benefIndex];
    benef.remove = true;
    seller[benefSection][benefIndex] = benef;
    sellers[index] = seller;

    setValues({ ...values, [section]: sellers });
  };
  const changeRequirements = (req) => {
    setRequirements(req);
  };
  const changeUid = (id) => {
    setUid(id);
  };
  const handleChangeStatus = (checked) => {
    var vals = { ...values };
    vals.active = checked;

    setValues(vals);
    submitFolder({ ...values, active: checked, advancement: "" });
  };

  const handleChangeAdvancement = (e) => {
    const { name, value } = e.target;
    submitFolder({ ...values, advancement: value, active: !["ac", "pe"].includes(value) });
  };

  return (
    <div className="row">
      <div className="col-md-9 col-sm-12">
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row col-12 folder-status">
              {edit && (
                <h4>
                  <Translate content={`forms.folder.options.${values.property.propertyType}`} />
                  {" - "}
                  {values.property.propertyAddressLocality && <span>{values.property.propertyAddressLocality}</span>}
                </h4>
              )}

              {edit && values.active && (
                <span className="advancement-status">
                  <select className="select" name="advancement" defaultValue={values.currentAdvancement} onChange={handleChangeAdvancement}>
                    <option value="pr">{counterpart.translate(`forms.folder.options.status.${type}.pr`)}</option>
                    <option value="ma">{counterpart.translate(`forms.folder.options.status.${type}.ma`)}</option>
                    <option value="co">{counterpart.translate(`forms.folder.options.status.${type}.co`)}</option>
                    <option value="bo">{counterpart.translate(`forms.folder.options.status.${type}.bo`)}</option>
                    <option value="af">{counterpart.translate(`forms.folder.options.status.${type}.af`)}</option>
                    <option value="ac">{counterpart.translate(`forms.folder.options.status.${type}.ac`)}</option>
                    <option value="pe">{counterpart.translate(`forms.folder.options.status.${type}.pe`)}</option>
                  </select>
                </span>
              )}

              {edit && !values.active && (
                <span className="switchery-container disactivated">
                  <Switch
                    onChange={handleChangeStatus}
                    onHandleColor="#0575e6"
                    onColor="#fff"
                    offColor="#fff"
                    offHandleColor="#cecece"
                    checked={values.active}
                    className="switchery"
                  />
                  <Translate content={`forms.folder.options.status.${type}.${values.advancement}`} className="ml-1" />
                </span>
              )}
            </div>

            <hr />
            {edit && <GenerationButtons generateDoc={generateDoc} loading={loading} type={type} documentLanguage={documentLanguage} changeLanguage={changeLanguage} />}
            {documentFile && <DownloadDocument documentFile={documentFile} type={type} docIndex={docIndex} />}

            <Navigation changeTab={changeTab} activeTab={activeTab} type={type} edit={edit} onSubmit={onSubmit}>
              <div className={`content-tab ${activeKlass("tab1")}`} key={1}>
                <InputGroup
                  agencyCountry={agencyCountry}
                  formData={propertyData}
                  prefix="property"
                  setRequirements={changeRequirements}
                  folderType={type}
                  handleInputChange={(e, section) => handleInputChange(e, "property")}
                  setDate={setDate}
                  values={values.property}
                  showLot={["studio", "apartment", "duplex", "triplex", "penthouse", "loft", "office", "garage", "commerce"].includes(values.property.propertyType)}
                />
                <ActionButtons prevTab={false} nextTab="tab2" changeTab={changeTab} edit={edit} onSubmit={onSubmit} />
              </div>

              <div className={`content-tab ${activeKlass("tab2")}`} key={2}>
                {values.sellers.length > 0 &&
                  values.sellers.map((item, index) => {
                    return (
                      <div key={`2_${index}`}>
                        {!item.remove && !item.validated && (
                          <div key={index}>
                            <InputGroup
                              agencyCountry={agencyCountry}
                              formData={sellerData}
                              prefix="sellers"
                              setRequirements={changeRequirements}
                              folderType={type}
                              handleInputChange={(e, i, s) => handleSellerInputChange(e, index, "sellers")}
                              handleCopyAdress={(e, i) => CopySellerAdress(e, index)}
                              setDate={setDateWithIndex}
                              values={item}
                              conditionValue={item["sellerType"]}
                              index={index}
                            />

                            {item["sellerType"] === "CompanySeller" && (
                              <>
                                <Translate content={`forms.folder.labels.EcoBen`} className="label" component="h3" />
                                {item.sellerBeneficiaries.length > 0 &&
                                  item.sellerBeneficiaries.map((benefItem, benefIndex) => {
                                    return (
                                      <div className="row" key={benefIndex}>
                                        {!benefItem.validated && !benefItem.remove && (
                                          <>
                                            <div className="col-md-12  no-bg col-sm-12 custom-input ">
                                              <div className="input">
                                                <div className="radio">
                                                  <label className="radio-container">
                                                    <Translate content="forms.folder.options.mr" />
                                                    <input
                                                      type="radio"
                                                      name="label"
                                                      value="mr"
                                                      ref={methods.register()}
                                                      onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                      defaultChecked={benefItem.label === "mr"}
                                                    />
                                                    <span className={`checkmark ${benefItem.label === "mr" ? "" : "un"}checked`}></span>
                                                  </label>

                                                  <label className="radio-container">
                                                    <Translate content="forms.folder.options.mrs" />
                                                    <input
                                                      type="radio"
                                                      name="label"
                                                      value="mrs"
                                                      ref={methods.register()}
                                                      onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                      defaultChecked={benefItem.label === "mrs"}
                                                    />
                                                    <span className={`checkmark ${benefItem.label === "mrs" ? "" : "un"}checked`}></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.benefFirstName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="firstName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.firstName}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.benefLastName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="lastName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.lastName}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.streetNumber`} className="label" />
                                                <input
                                                  type="text"
                                                  name="streetNumber"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.streetNumber}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.streetName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="streetName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.streetName}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.zipCode`} className="label" />
                                                <input
                                                  type="text"
                                                  name="zipCode"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.zipCode}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.city`} className="label" />
                                                <input
                                                  type="text"
                                                  name="city"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.city}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.country`} className="label" />
                                                <input
                                                  type="text"
                                                  name="country"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.country}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.profession`} className="label" />
                                                <input
                                                  type="text"
                                                  name="profession"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.profession}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.secSoc`} className="label" />
                                                <input
                                                  type="text"
                                                  name="secSoc"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.secSoc}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.numId`} className="label" />
                                                <input
                                                  type="text"
                                                  name="numId"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.numId}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.birthLocality`} className="label" />
                                                <input
                                                  type="text"
                                                  name="birthLocality"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.birthLocality}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.civilStatus`} className="label" />
                                                <select
                                                  name="civilStatus"
                                                  className="select"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                  defaultValue={benefItem.civilStatus}
                                                >
                                                  <option disabled value="">
                                                    {" "}
                                                  </option>
                                                  {["single", "married", "divorced", "widowed"].map((choice) => selectOption(choice))}
                                                </select>
                                              </div>
                                            </div>

                                            <div
                                              className="col-md-12 validate-benef"
                                              onClick={() => handleBenefValidation(true, index, benefIndex, "sellers", "sellerBeneficiaries")}
                                            >
                                              <i className="fa fa-check"></i>
                                            </div>
                                          </>
                                        )}
                                        {benefItem.validated && !benefItem.remove && (
                                          <>
                                            <div className="mb-2 benef col-md-8 col-xs-8">
                                              {benefItem.firstName} {benefItem.lastName} ...
                                            </div>
                                            <div className="mb-2 col-md-4 col-xs-2 inlineFlex">
                                              <div className="show-benef" onClick={() => handleBenefValidation(false, index, benefIndex, "sellers", "sellerBeneficiaries")}>
                                                <i className="fa fa-eye"></i>
                                              </div>
                                              <div className="remove-benef" onClick={() => setSellerBenefModal({ ...sellerBenefModal, [index]: { [benefIndex]: true } })}>
                                                <i className="fa fa-trash"></i>
                                              </div>
                                            </div>

                                            <Modal
                                              show={sellerBenefModal && sellerBenefModal[index] && sellerBenefModal[index][[benefIndex]]}
                                              onHide={() => {
                                                setSellerBenefModal({ ...sellerBenefModal, [index]: { [benefIndex]: false } });
                                              }}
                                            >
                                              <Modal.Header closeButton>
                                                <Modal.Title>
                                                  <Translate content="modal.suppression.title" />
                                                </Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                <div className="row">
                                                  <div className="col-12 mb-2">
                                                    <Translate content="modal.suppression.body" />
                                                  </div>

                                                  <div className="col-6 mb-2">
                                                    <button
                                                      className="direction float-right"
                                                      onClick={() => handleBenefRemove(index, benefIndex, "sellers", "sellerBeneficiaries")}
                                                    >
                                                      <Translate content="modal.suppression.confirmation" />
                                                    </button>
                                                  </div>
                                                  <div className="col-6 mb-2">
                                                    <button
                                                      className="direction float-right"
                                                      onClick={() => {
                                                        setSellerBenefModal({ ...sellerBenefModal, [index]: { [benefIndex]: false } });
                                                      }}
                                                    >
                                                      <Translate content="modal.suppression.close" />
                                                    </button>
                                                  </div>
                                                </div>
                                              </Modal.Body>
                                            </Modal>
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                <button type="button" onClick={() => addSellerBeneficiary(index, "sellers", "sellerBeneficiaries")} className="add-ecoBen">
                                  <Translate content={`forms.folder.labels.addEcoBen`} />
                                </button>
                              </>
                            )}

                            <hr />

                            <div className="row">
                              <div className="col-md-12">
                                <button
                                  type="button"
                                  className="remove-btn col-md-3"
                                  onClick={() => {
                                    setSellerModal({ ...sellerModal, [index]: true });
                                  }}
                                >
                                  <i className="fa fa-user-times"></i>
                                  <Translate content="forms.folder.buttons.remove_seller" />
                                </button>
                                <button type="button" className="validate-btn offset-md-6 col-md-3" onClick={() => handleValidation(true, index, "sellers")}>
                                  <i className="fa fa-check"></i>
                                  <Translate content="forms.folder.buttons.validate" />
                                </button>
                              </div>
                            </div>
                            <Modal
                              show={sellerModal && sellerModal[index]}
                              onHide={() => {
                                setSellerModal({ ...sellerModal, [index]: false });
                              }}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  <Translate content="modal.suppression.title" />
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="col-12 mb-2">
                                    <Translate content="modal.suppression.body" />
                                  </div>

                                  <div className="col-6 mb-2">
                                    <button className="direction float-right" onClick={() => remove(index, "sellers")}>
                                      <Translate content="modal.suppression.confirmation" />
                                    </button>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <button
                                      className="direction float-right"
                                      onClick={() => {
                                        setSellerModal({ ...sellerModal, [index]: false });
                                      }}
                                    >
                                      <Translate content="modal.suppression.close" />
                                    </button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>

                            <hr />
                          </div>
                        )}

                        {!item.remove && item.validated && (
                          <div className="tiny-sb">
                            <div className="mb-2 pl-2 benef">
                              {item.sellerType === "privateSeller" && (
                                <span>
                                  {item.sellerLastName} {item.sellerFirstName}
                                </span>
                              )}
                              {item.sellerType === "CompanySeller" && <span>{item.sellerCompanyName}</span>}
                            </div>
                            <div className="mb-2">
                              <div className="show-benef" onClick={() => handleValidation(false, index, "sellers")}>
                                <i className="fa fa-eye"></i>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                <button type="button" className="add-btn" onClick={addSeller}>
                  <i className="fa fa-user-plus"></i>
                  <Translate content="forms.folder.buttons.add_seller" />
                </button>
                <ActionButtons prevTab="tab1" nextTab="tab3" changeTab={changeTab} edit={edit} onSubmit={onSubmit} />
              </div>

              <div className={`content-tab ${activeKlass("tab3")}`} key={3}>
                {values.buyers.length > 0 &&
                  values.buyers.map((item, index) => {
                    return (
                      <div key={`3_${index}`}>
                        {!item.remove && !item.validated && (
                          <div key={index}>
                            <InputGroup
                              key={`3_${index}`}
                              agencyCountry={agencyCountry}
                              formData={buyerData}
                              prefix="buyers"
                              setRequirements={changeRequirements}
                              folderType={type}
                              handleInputChange={(e, i, s) => handleSellerInputChange(e, index, "buyers")}
                              copyBuyerAddress={(e, i) => copyBuyerAddress(e, index)}
                              setDate={setDateWithIndex}
                              values={item}
                              conditionValue={item["buyerType"]}
                              index={index}
                            />

                            {item["buyerType"] === "CompanySeller" && (
                              <>
                                <Translate content={`forms.folder.labels.EcoBen`} className="label" component="h3" />
                                {item.buyerBeneficiaries.length > 0 &&
                                  item.buyerBeneficiaries.map((benefItem, benefIndex) => {
                                    return (
                                      <div className="row" key={benefIndex}>
                                        {!benefItem.validated && !benefItem.remove && (
                                          <>
                                            <div className="col-md-12  no-bg col-sm-12 custom-input ">
                                              <div className="input">
                                                <div className="radio">
                                                  <label className="radio-container">
                                                    <Translate content="forms.folder.options.mr" />
                                                    <input
                                                      type="radio"
                                                      name="label"
                                                      value="mr"
                                                      ref={methods.register()}
                                                      onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                      defaultChecked={benefItem.label === "mr"}
                                                    />
                                                    <span className={`checkmark ${benefItem.label === "mr" ? "" : "un"}checked`}></span>
                                                  </label>

                                                  <label className="radio-container">
                                                    <Translate content="forms.folder.options.mrs" />
                                                    <input
                                                      type="radio"
                                                      name="label"
                                                      value="mrs"
                                                      ref={methods.register()}
                                                      onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                      defaultChecked={benefItem.label === "mrs"}
                                                    />
                                                    <span className={`checkmark ${benefItem.label === "mrs" ? "" : "un"}checked`}></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.benefFirstName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="firstName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.firstName}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.benefLastName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="lastName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.lastName}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.streetNumber`} className="label" />
                                                <input
                                                  type="text"
                                                  name="streetNumber"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.streetNumber}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.streetName`} className="label" />
                                                <input
                                                  type="text"
                                                  name="streetName"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.streetName}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.zipCode`} className="label" />
                                                <input
                                                  type="text"
                                                  name="zipCode"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.zipCode}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.city`} className="label" />
                                                <input
                                                  type="text"
                                                  name="city"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.city}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-4 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.country`} className="label" />
                                                <input
                                                  type="text"
                                                  name="country"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.country}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.profession`} className="label" />
                                                <input
                                                  type="text"
                                                  name="profession"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.profession}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.secSoc`} className="label" />
                                                <input
                                                  type="text"
                                                  name="secSoc"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.secSoc}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.numId`} className="label" />
                                                <input
                                                  type="text"
                                                  name="numId"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.numId}
                                                />
                                              </div>
                                            </div>

                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.birthLocality`} className="label" />
                                                <input
                                                  type="text"
                                                  name="birthLocality"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.birthLocality}
                                                />
                                              </div>
                                            </div>
                                            <div className={`custom-input col-md-6 col-xs-12`}>
                                              <div className="input">
                                                <Translate content={`forms.folder.labels.civilStatus`} className="label" />
                                                <select
                                                  name="civilStatus"
                                                  className="select"
                                                  ref={methods.register()}
                                                  onChange={(e, i, b) => handleBenefChange(e, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                                  defaultValue={benefItem.civilStatus}
                                                >
                                                  <option disabled value="">
                                                    {" "}
                                                  </option>
                                                  {["single", "married", "divorced", "widowed"].map((choice) => selectOption(choice))}
                                                </select>
                                              </div>
                                            </div>
                                            <div
                                              className="col-md-12 validate-benef"
                                              onClick={() => handleBenefValidation(true, index, benefIndex, "buyers", "buyerBeneficiaries")}
                                            >
                                              <i className="fa fa-check"></i>
                                            </div>
                                          </>
                                        )}

                                        {benefItem.validated && !benefItem.remove && (
                                          <>
                                            <div className="mb-2 benef col-md-8 col-xs-8">
                                              {benefItem.firstName} {benefItem.lastName} ...
                                            </div>
                                            <div className="mb-2 col-md-4 col-xs-4 inlineFlex">
                                              <div className="show-benef" onClick={() => handleBenefValidation(false, index, benefIndex, "buyers", "buyerBeneficiaries")}>
                                                <i className="fa fa-eye"></i>
                                              </div>
                                              <div className="remove-benef" onClick={() => setBuyerBenefModal({ ...buyerBenefModal, [index]: { [benefIndex]: true } })}>
                                                <i className="fa fa-trash"></i>
                                              </div>
                                            </div>
                                            <Modal
                                              show={buyerBenefModal && buyerBenefModal[index] && buyerBenefModal[index][[benefIndex]]}
                                              onHide={() => {
                                                setBuyerBenefModal({ ...buyerBenefModal, [index]: { [benefIndex]: false } });
                                              }}
                                            >
                                              <Modal.Header closeButton>
                                                <Modal.Title>
                                                  <Translate content="modal.suppression.title" />
                                                </Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                <div className="row">
                                                  <div className="col-12 mb-2">
                                                    <Translate content="modal.suppression.body" />
                                                  </div>

                                                  <div className="col-6 mb-2">
                                                    <button className="direction float-right" onClick={() => handleBenefRemove(index, benefIndex, "buyers", "buyerBeneficiaries")}>
                                                      <Translate content="modal.suppression.confirmation" />
                                                    </button>
                                                  </div>
                                                  <div className="col-6 mb-2">
                                                    <button
                                                      className="direction float-right"
                                                      onClick={() => {
                                                        setBuyerBenefModal({ ...buyerBenefModal, [index]: { [benefIndex]: false } });
                                                      }}
                                                    >
                                                      <Translate content="modal.suppression.close" />
                                                    </button>
                                                  </div>
                                                </div>
                                              </Modal.Body>
                                            </Modal>
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                <button type="button" onClick={() => addSellerBeneficiary(index, "buyers", "buyerBeneficiaries")} className="add-ecoBen">
                                  <Translate content={`forms.folder.labels.addEcoBen`} />
                                </button>
                              </>
                            )}

                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <button type="button" className="remove-btn col-md-3" onClick={() => setBuyerModal({ ...buyerModal, [index]: true })}>
                                  <i className="fa fa-user-times"></i>
                                  <Translate content="forms.folder.buttons.remove_buyer" />
                                </button>
                                <button type="button" className="validate-btn offset-md-6 col-md-3" onClick={() => handleValidation(true, index, "buyers")}>
                                  <i className="fa fa-check"></i>
                                  <Translate content="forms.folder.buttons.validate" />
                                </button>
                              </div>
                            </div>
                            <Modal
                              show={buyerModal && buyerModal[index]}
                              onHide={() => {
                                setBuyerModal({ ...buyerModal, [index]: false });
                              }}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  <Translate content="modal.suppression.title" />
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="col-12 mb-2">
                                    <Translate content="modal.suppression.body" />
                                  </div>

                                  <div className="col-6 mb-2">
                                    <button className="direction float-right" onClick={() => remove(index, "buyers")}>
                                      <Translate content="modal.suppression.confirmation" />
                                    </button>
                                  </div>
                                  <div className="col-6 mb-2">
                                    <button
                                      className="direction float-right"
                                      onClick={() => {
                                        setBuyerModal({ ...buyerModal, [index]: false });
                                      }}
                                    >
                                      <Translate content="modal.suppression.close" />
                                    </button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>

                            <hr />
                          </div>
                        )}
                        {!item.remove && item.validated && (
                          <div className="tiny-sb">
                            <div className="mb-2 pl-2 benef tiny-sb-names">
                              {item.buyerType === "privateSeller" && (
                                <span>
                                  {item.buyerLastName} {item.buyerFirstName}
                                </span>
                              )}
                              {item.buyerType === "CompanySeller" && <span>{item.buyerCompanyName}</span>}
                            </div>
                            <div className="mb-2 tiny-sb-icons">
                              <div className="show-benef" onClick={() => handleValidation(false, index, "buyers")}>
                                <i className="fa fa-eye"></i>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                <button type="button" className="add-btn" onClick={addBuyer}>
                  <i className="fa fa-user-plus"></i>
                  <Translate content="forms.folder.buttons.add_buyer" />
                </button>
                <ActionButtons prevTab="tab2" nextTab="tab4" changeTab={changeTab} edit={edit} onSubmit={onSubmit} />
              </div>

              <div className={`content-tab ${activeKlass("tab4")}`} key={4}>
                <InputGroup
                  agencyCountry={agencyCountry}
                  formData={actData}
                  prefix="act"
                  setRequirements={changeRequirements}
                  folderType={type}
                  handleInputChange={(e, section) => handleInputChange(e, "act")}
                  setDate={setDate}
                  values={values.act}
                />
                <ActionButtons prevTab="tab3" nextTab={edit ? "tab5" : false} changeTab={changeTab} edit={edit} onSubmit={onSubmit} />
              </div>

              {edit && (
                <div className={`content-tab ${activeKlass("tab5")}`} key={5}>
                  <NewDocuments folder={values} changeUid={changeUid} />
                  <SumuryDocument folder={values} uid={uid} changeUid={changeUid} />
                  <ActionButtons prevTab="tab4" nextTab={false} changeTab={changeTab} edit={edit} onSubmit={onSubmit} />
                </div>
              )}
            </Navigation>

            <div className="row">
              <div className="col-md-4 offset-md-8 col-sm-12">
                <button type="submit" className="submit">
                  <Translate content="forms.folder.submit" />
                </button>
              </div>
            </div>
          </form>
        </FormContext>
      </div>

      <div className="col-md-3 col-sm-0">
        <div className="large-requirements">
          <Translate content="forms.folder.requirements" className="pt-1" component="h4" />
          <hr />
          {requirements && (
            <div className="reqs">
              <div>
                <Translate className={`badge ${badge(requirements[0])}`} content={`badge.${badge(requirements[0])}`} />
                <Translate content="documents.types.pb" />
              </div>
              <div>
                <Translate className={`badge ${badge(requirements[1])}`} content={`badge.${badge(requirements[1])}`} />
                <Translate content="documents.types.pf" />
              </div>

              <div>
                <Translate className={`badge ${badge(requirements[2])}`} content={`badge.${badge(requirements[2])}`} />
                <Translate content="documents.types.prv" />
              </div>

              <div>
                <Translate className={`badge ${badge(requirements[3])}`} content={`badge.${badge(requirements[3])}`} />
                <Translate content="documents.types.ara" />
              </div>
              {type === "sell" && (
                <div>
                  <Translate className={`badge ${badge(requirements[4])}`} content={`badge.${badge(requirements[4])}`} />
                  <Translate content="documents.types.c" />
                </div>
              )}
              <div>
                <Translate className={`badge ${badge(requirements[5])}`} content={`badge.${badge(requirements[5])}`} />
                <Translate content="documents.types.an" />
              </div>
              <div>
                <Translate className={`badge ${badge(requirements[6])}`} content={`badge.${badge(requirements[6])}`} />
                <Translate content="documents.types.f" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Form;
