import React, { useState } from "react";
import useAxios from "axios-hooks";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import Translate from "react-translate-component";

function Item({ folderId, documentTarget, targetId, documentType, uid }) {
  const [showDelModal, setShowDelModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [linkRef, setLinkRef] = useState(React.createRef());

  const [{ data, loading }, fetch] = useAxios({
    url: "/folderUploads/load",
    method: "POST",
    data: {
      folderId,
      documentTarget,
      targetId,
      documentType,
    },
  });

  const [{ loading: deleteLoading }, executedelete] = useAxios(
    {
      url: "/folderUploads/remove",
      method: "DELETE",
      data: {
        folderId,
        documentTarget,
        targetId,
        documentType,
        fileName,
      },
    },
    { manual: true }
  );

  const [{ fileData, fileLoading }, downloadFile] = useAxios(
    {
      method: "POST",
      url: "/folderUploads/download",
      responseType: "blob",
    },
    { manual: true, responseType: "blob" }
  );
  const download = (file_name) => {
    downloadFile({
      data: {
        folderId,
        documentTarget,
        targetId,
        documentType,
        fileName: file_name,
      },
    }).then((response) => {
      const href = window.URL.createObjectURL(response.data);
      const a = linkRef.current;
      a.download = file_name;
      a.href = href;
      a.click();
      a.href = "";
    });
  };

  const fileToDelete = (file) => {
    setShowDelModal(true);
    setFileName(file);
  };

  const remove = () => {
    executedelete().then((response) => {
      setShowModal(true);
      setShowDelModal(false);
      fetch();
    });
  };

  React.useEffect(() => {
    uid !== 1 && fetch();
  }, [uid]);

  return (
    <td className="text-center">
      {loading && (
        <div className="loading">
          <Loader type="Puff" color="#0575e6" height={25} width={50} visible={true} className="spinner" />
        </div>
      )}
      {!loading && data.files_names.length > 0 && (
        <div className="target-files hover" onClick={() => setShowModal(true)}>
          <i className="fa fa-file blue-txt"></i>
          <span className="count blue-txt">{data.files_names.length}</span>
        </div>
      )}
      {!loading && data.files_names.length === 0 && (
        <div className="target-files">
          <i className="fa fa-file gray-txt"></i>
        </div>
      )}

      {!loading && data.files_names.length > 0 && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content={`forms.folder.documents.new.file.${documentType}`} className="arima text-center" component="th" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data.files_names.map((file_name, i) => (
              <div className="row py-2 blue-border-bottom" key={i}>
                <a ref={linkRef} />
                <div className="col-9">
                  <span className="file-name">{file_name}</span>
                </div>
                <div className="col-3">
                  <i className="fa fa-download blue-txt mr-4 hover" onClick={() => download(file_name)}></i>
                  <i className="fa fa-trash blue-txt ml-2 hover" onClick={() => fileToDelete(file_name)}></i>
                </div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
      )}
      {!loading && data.files_names.length > 0 && (
        <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content="custom_files.modal.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-2">
                <Translate content="custom_files.modal.placeholder" component="p" />
              </div>

              <div className="col-6 mb-2">
                <button className="direction float-right" onClick={() => remove()}>
                  <Translate content="custom_files.modal.delete" />
                </button>
              </div>
              <div className="col-6 mb-2">
                <button className="direction float-right" onClick={() => setShowDelModal(false)}>
                  <Translate content="custom_files.modal.close" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </td>
  );
}

export default Item;
