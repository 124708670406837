import React from 'react';
import { Link } from 'react-router-dom';
import Translate from 'react-translate-component';

import Slider from './contents/slider';
import Intro from './contents/intro';
import Concept from './contents/concept';
import Comparison from './contents/comparison';
import Usp from './contents/usp';
import Testimonials from './contents/testimonials';
import Advantages from './contents/advantages';
import SubscriptionsForm from '../../agency/forms/subscriptionsForm';
import ContactForm from './contents/contact_form';


function HpContent(props) {
  return(
    <div>

      <div className='silder-top'>
        <Slider />
      </div>

      <div className='section introduction'>
          <Intro />
      </div>

      <div className='section concept'>
        <Concept />
      </div>
      <div className='section comparison'>
        <Comparison />
      </div>

      <div className='section usp'>
        <div className='row'>
          <Usp key={1} index={1} />
          <Usp key={2} index={2} />
          <Usp key={3} index={3} />
          <Usp key={4} index={4} />
          <Usp key={5} index={5} />
        </div>

        <div className='row'>
          <div className='col-12 my-4 text-center'>
            <Translate className='placeholder' content='homepage.usp.placeholder' />
          </div>
          <div className='offset-md-3 col-md-6 offset-sm-3 col-sm-6'>
            <div className='usp-action'>
              <Link to='/' className='link'>
                <Translate content='homepage.usp.action'/>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='testimonials'>
        <div className='section'>
          <Testimonials />
        </div>
      </div>

      <div className='section advantages'>
        <Advantages />
      </div>

      <div className='section subscriptions'>
        <Translate content='homepage.subscriptions.title' className='title' unsafe/>
        <div className="color_border"></div>
        <Translate content="homepage.subscriptions.placeholder" component='p' className='mt-2' />
        <SubscriptionsForm agencyPanel={false}/>
      </div>

      <div className='contact'>
        <div className='header'>
          <Translate content='homepage.contact.title' className='title' component='h3'/>
        </div>

        <div className='row col-12'>
          <div className='col-md-6 col-sm-12 information'>
            <div className="footer_contact_info" >
              <div className="title section_heading fo_black">
                  <Translate content='homepage.contact.information' component='h4' />
                  <div className="color_border"></div>
              </div>

              <div className="footer_single_contact_info">
                  <i className="fa fa-globe" aria-hidden="true"></i>
                  <p>55 Rue du Luxembourg</p>
                  <p>L-4391, Pontpierre</p>
              </div>
              <div className="footer_single_contact_info">
                  <i className="fa fa-phone-square" aria-hidden="true"></i>
                  <a href="tel:+35226570707">+352 26 57 07 07</a>
                  <a href="tel:+35226570708">+352 26 57 07 08</a>
              </div>
              <div className="footer_single_contact_info">
                  <i className="fa fa-envelope-square" aria-hidden="true"></i>
                  <a href="mailto:contact@linc.lu">contact@linc.lu</a>
              </div>
            </div>
          </div>

          <div className='col-md-6 col-sm-12 form'>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HpContent;
