import React from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import * as actionCreator from "../../../store/actions/accountActions";
import * as aActionACreator from "../../../store/actions/agencyActions";

import Actions from "../../../components/account/folders/actions.js";
import Table from "../../../components/account/folders/table.js";
import AccessAgency from "./access.agency";

class AgencyFolders extends React.Component {
  componentDidMount() {
    this.props.getFolders();
    this.props.getCurrentOrder();
  }
  submitAction = (values) => {};

  render() {
    return (
      <div className="Agency">
        <div className="container agency-folders">
          <AccessAgency>
            <div className="custom-box">
              <Translate content="forms.folder.title" className="folders-title" component="h5" />
              <Actions />
              <div className="row">
                <div className="col-12">
                  <Translate content="folders.table.title" className="table-title" component="div" />
                </div>
                <div className="col-12">
                  {this.props.folders && this.props.folders.length > 0 && (
                    <Table folders={this.props.folders} currentOrder={this.props.currentOrder} removeFolder={(id) => this.props.removeFolder(id)} />
                  )}
                </div>
              </div>
            </div>
          </AccessAgency>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  folders: state.accountReducer.folders,
  currentOrder: state.agencyReducer.currentOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getFolders: () => dispatch(actionCreator.getFolders()),
  getCurrentOrder: () => dispatch(aActionACreator.getCurrentOrder()),
  removeFolder: (id) => dispatch(actionCreator.removeFolder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyFolders);
