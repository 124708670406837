import React from "react";
import { connect } from "react-redux";
import Form from "../shared/form";
import * as actionCreator from "../../../store/actions/accountActions";
import * as aActionACreator from "../../../store/actions/agencyActions";
import { emptySellerData, emptyBuyerData } from "../shared/data/emptyData";
import { propertyData, sellerData, buyerData, actData } from "../shared/data/sellData";
import Invite from "../shared/invite";

class EditSellContainer extends React.Component {
  submitFolder = (values) => {
    this.props.updateFolder(values);
  };

  generateDocument = (docIndex, documentLanguage) => {
    this.props.generateDocument(docIndex, "sell", this.props.initValues.id, documentLanguage);
  };

  sendInvitation = (values) => {
    this.props.sendInvitation({ ...values, folderId: this.props.initValues.id });
  };
  componentDidMount() {
    this.props.getCurrentOrder();
  }

  render() {
    return (
      <div>
        {(this.props.initValues.active || (!this.props.initValues.active && this.props.currentOrder && this.props.currentOrder.sell && this.props.currentOrder.rent)) && (
          <>
            {console.log(this.props.initValues)}
            <Form
              submitFolder={this.submitFolder}
              initValues={this.props.initValues}
              type="sell"
              propertyData={propertyData}
              sellerData={sellerData}
              buyerData={buyerData}
              actData={actData}
              emptySellerData={emptySellerData}
              emptyBuyerData={emptyBuyerData}
              edit={true}
              generateDocument={this.generateDocument}
              loading={this.props.loading}
              documentFile={this.props.documentFile}
              agencyCountry={this.props.agencyCountry}
            />
            {window.location.hash.substr(1) !== "tab5" && <Invite type="sell" sendInvitation={this.sendInvitation} />}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.accountReducer.loading,
  documentFile: state.accountReducer.documentFile,
  currentOrder: state.agencyReducer.currentOrder,
});

const mapDispatchToProps = (dispatch) => ({
  updateFolder: (values) => dispatch(actionCreator.updateFolder(values)),
  generateDocument: (docIndex, type, folderId, documentLanguage) => dispatch(actionCreator.generateDocument(docIndex, type, folderId, documentLanguage)),
  sendInvitation: (values) => dispatch(actionCreator.sendInvitation(values)),
  getCurrentOrder: () => dispatch(aActionACreator.getCurrentOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSellContainer);
