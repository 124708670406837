import { folderRequestService } from "../services/folderRequestService";
import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";

const flash = (name) => {
  return counterpart.translate(name);
};

export function getFolderRequests() {
  return (dispatch) => {
    folderRequestService.getFolderRequests().then(
      (response) => {
        dispatch(folder_requests(response.data.folder_requests));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function updateFolderRequest(values) {
  return (dispatch) => {
    folderRequestService.updateFolderRequest(values).then(
      (response) => {
        dispatch(Notifications.success({ message: flash("flash.folder.success.update") }));
        window.location.reload();
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function getFolderRequest(id) {
  return (dispatch) => {
    folderRequestService.getFolderRequest(id).then(
      (response) => {
        dispatch(folder_request(response.data.folder_request));
        dispatch(setAgencyCountry(response.data.agencyCountry));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

// Reducers
export function folder_requests(folder_requests) {
  return {
    type: "GET_FOLDER_REQUESTS",
    folder_requests,
  };
}

export function folder_request(folder_request) {
  return {
    type: "GET_FOLDER_REQUEST",
    folder_request,
  };
}
export function setAgencyCountry(agencyCountry) {
  return {
    type: "SET_AGENCY_COUNTRY_UP",
    agencyCountry,
  };
}
