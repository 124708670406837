import React from "react";
import Translate from "react-translate-component";

import AccessAgencyMedia from "../access.agency.media";
import { Link } from "react-router-dom";

const FilesType = () => {
  return (
    <div className="Agency">
      <div className="container custom-files">
        <AccessAgencyMedia>
          <div className="row custom-box">
            <div className="col-12">
              <Translate content="custom_files.types.title" component="h5" className="mb-3" />
              <Translate content="custom_files.types.placeholder" component="p" className="mb-3" />
            </div>

            <div className="col-12">
              <a href="https://oreaprodstor.blob.core.windows.net/modeemploi/mode_emploi_orea.docx" download>
                <Translate content="download_doc" />
              </a>
            </div>

            <div className="row col-12 mt-3">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="file-type-card">
                  <Link to="/account/custom_files/sell">
                    <div className="card-body">
                      <i className="fa fa-key" />
                      <Translate content="custom_files.types.sell" component="h5" className="title" />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="file-type-card">
                  <Link to="/account/custom_files/rent">
                    <div className="card-body">
                      <i className="fa fa-file" />
                      <Translate content="custom_files.types.rent" component="h5" className="title" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </AccessAgencyMedia>
      </div>
    </div>
  );
};

export default FilesType;
