import React from "react";
import Header from '../../shared/header';
import AgencyContainer from '../../agency/agencyContainer';
import { connect } from 'react-redux';

class Dashboard extends React.Component {
  render(){
    return (
      <div className='Agency'>
        <div className='container'>
          <div className='row'>
            <AgencyContainer />
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  agency: state.agencyReducer.agency,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
