import React from "react";
import Moment from "moment";
import useForm from "react-hook-form";
import Translate from "react-translate-component";
import useAxios from "axios-hooks";
import Loader from "react-loader-spinner";

function Messages({ conversation, getConversation }) {
  const { handleSubmit, register, errors } = useForm();
  const [{ data, loading }, addMessage] = useAxios(
    {
      url: "/conversations/add_message",
      method: "POST",
    },
    { manual: true }
  );
  const onSubmit = (data, e) => {
    addMessage({
      data: { conversationId: conversation.conversationId, body: data.body },
    }).then((resp) => {
      getConversation(conversation.conversationId);
      e.target.reset();
    });
  };

  return (
    <div className="preview">
      {conversation && (
        <>
          <div className="preview-top">
            <div className="preview__title">{conversation.subject}</div>
            <div>
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
          <div className="scrollable">
            <div className="preview-content scrollable__target scrollable-messages">
              {conversation.messages.map((message) => (
                <div className="preview-respond" key={message.id}>
                  <div className="preview-respond__head" key={message.id}>
                    <div className="profile-head">
                      <div className="profile-head__id">
                        <div className={`profile-head__avatar dot--${message.role}`} />
                        <div>
                          <div className="profile-head__name">{message.sender.email}</div>
                          <div className="profile-head__mail">{message.role}</div>
                        </div>
                      </div>
                      <div className="date">{Moment(message.createdAt).fromNow()}</div>
                    </div>
                  </div>
                  <div className="preview-respond__content">
                    <p className="paragraph">{message.body}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="preview-foot">
            <form onSubmit={handleSubmit(onSubmit)} className="reply-form">
              <div className="reply-container">
                <textarea
                  type="text"
                  name="body"
                  ref={register()}
                  className="message-input"
                  ref={register({
                    required: "Required",
                  })}
                ></textarea>

                <button className="preview-foot__button button">
                  {!loading && <i className="fa fa-reply mr-1"></i>}
                  {<Loader type="ThreeDots" color="#0575e6" height={15} width={30} visible={loading} className="upload-spinner mr-1" />}
                  Reply
                </button>
                {errors.body && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default Messages;
