import React, { useState } from "react";
import Translate from "react-translate-component";
import Item from "./items";

class SummaryDocument extends React.Component {
  render() {
    const { folder } = this.props;
    return (
      <div className="mb-5 nav-content new-documents">
        <hr />
        <div className="row">
          <div className="col-12 mb-5 basic">
            <Translate content="forms.folder.documents.new.summary" className="arima" component="h5" />

            <table style={{ width: "100%" }} key={1} className="table">
              <thead className="thead-light">
                <tr>
                  <Translate style={{ width: "15%" }} content={`forms.folder.documents.new.type.${folder.type}.seller`} className="arima" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.rgpd" className="arima text-center" component="th" />
                  <Translate style={{ width: "25%" }} content="forms.folder.documents.new.file.pb" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.ar" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.cid" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.css" className="arima text-center" component="th" />
                </tr>
              </thead>
              <tbody>
                {folder.sellers.map((seller) => (
                  <tr key={seller.id}>
                    {seller.sellerType === "privateSeller" && <td>{seller.sellerLastName}</td>}
                    {seller.sellerType === "CompanySeller" && <td>{seller.sellerCompanyName}</td>}

                    <Item folderId={folder.id} documentTarget="seller" targetId={seller.id} documentType="rgpd" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="seller" targetId={seller.id} documentType="pb" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="seller" targetId={seller.id} documentType="ar" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="seller" targetId={seller.id} documentType="cid" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="seller" targetId={seller.id} documentType="css" uid={this.props.uid} />
                  </tr>
                ))}
              </tbody>
            </table>

            <table style={{ width: "100%" }} key={2} className="table">
              <thead className="thead-light">
                <tr>
                  <Translate style={{ width: "15%" }} content={`forms.folder.documents.new.type.${folder.type}.buyer`} className="arima" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.rgpd" className="arima text-center" component="th" />
                  <Translate style={{ width: "25%" }} content="forms.folder.documents.new.file.pf" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.ar" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.cid" className="arima text-center" component="th" />
                  <Translate style={{ width: "20%" }} content="forms.folder.documents.new.file.css" className="arima text-center" component="th" />
                </tr>
              </thead>
              <tbody>
                {folder.buyers.map((buyer) => (
                  <tr key={buyer.id}>
                    {buyer.buyerType === "privateSeller" && <td>{buyer.buyerLastName}</td>}
                    {buyer.buyerType === "CompanySeller" && <td>{buyer.buyerCompanyName}</td>}

                    <Item folderId={folder.id} documentTarget="buyer" targetId={buyer.id} documentType="rgpd" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="buyer" targetId={buyer.id} documentType="pf" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="buyer" targetId={buyer.id} documentType="ar" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="buyer" targetId={buyer.id} documentType="cid" uid={this.props.uid} />
                    <Item folderId={folder.id} documentTarget="buyer" targetId={buyer.id} documentType="css" uid={this.props.uid} />
                  </tr>
                ))}
              </tbody>
            </table>

            <table style={{ width: "100%" }} key={3} className="table">
              <thead className="thead-light">
                <tr>
                  <Translate
                    style={{ width: folder.type === "rent" ? "15%" : "16.6%" }}
                    content={`forms.folder.documents.new.type.${folder.type}.transaction`}
                    className="arima"
                    component="th"
                  />
                  <Translate
                    style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                    content="forms.folder.documents.new.file.ma"
                    className="arima text-center"
                    component="th"
                  />
                  {folder.type === "sell" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "25%" : "16.6%" }}
                      content="forms.folder.documents.new.file.co"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  {folder.type === "rent" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "25%" : "16.6%" }}
                      content="forms.folder.documents.new.file.ba"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  {folder.type === "rent" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "25%" : "16.6%" }}
                      content="forms.folder.documents.new.file.bo"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  <Translate
                    style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                    content="forms.folder.documents.new.file.fa"
                    className="arima text-center"
                    component="th"
                  />
                  {folder.type === "sell" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                      content="forms.folder.documents.new.file.at"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  {folder.type === "sell" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                      content="forms.folder.documents.new.file.ac"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  {folder.type === "sell" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                      content="forms.folder.documents.new.file.cc"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>

                  <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="ma" uid={this.props.uid} />

                  {folder.type === "sell" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="co" uid={this.props.uid} />}
                  {folder.type === "rent" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="ba" uid={this.props.uid} />}
                  {folder.type === "rent" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="bo" uid={this.props.uid} />}
                  <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="fa" uid={this.props.uid} />
                  {folder.type === "sell" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="at" uid={this.props.uid} />}
                  {folder.type === "sell" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="ac" uid={this.props.uid} />}
                  {folder.type === "sell" && <Item folderId={folder.id} documentTarget="transaction" targetId={0} documentType="cc" uid={this.props.uid} />}
                </tr>
              </tbody>
            </table>

            <table style={{ width: "100%" }} key={4} className="table">
              <thead className="thead-light">
                <tr>
                  <Translate
                    style={{ width: folder.type === "rent" ? "15%" : "16.6%" }}
                    content={`forms.folder.documents.new.type.${folder.type}.bank`}
                    className="arima"
                    component="th"
                  />
                  <Translate
                    style={{ width: folder.type === "rent" ? "20%" : "16.6%" }}
                    content="forms.folder.documents.new.file.ap"
                    className="arima text-center"
                    component="th"
                  />
                  {folder.type === "sell" && (
                    <Translate
                      style={{ width: folder.type === "rent" ? "25%" : "16.6%" }}
                      content="forms.folder.documents.new.file.dp"
                      className="arima text-center"
                      component="th"
                    />
                  )}
                  <th style={{ width: folder.type === "rent" ? "20%" : "16.6%" }} className="arima text-center" />
                  <th style={{ width: folder.type === "rent" ? "20%" : "16.6%" }} className="arima text-center" />
                  {folder.type === "sell" && <th style={{ width: folder.type === "rent" ? "20%" : "16.6%" }} className="arima text-center" />}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <Item folderId={folder.id} documentTarget="bank" targetId={0} documentType="ap" uid={this.props.uid} />
                  {folder.type === "sell" && <Item folderId={folder.id} documentTarget="bank" targetId={0} documentType="dp" uid={this.props.uid} />}
                  <td></td>
                  <td></td>
                  {folder.type === "sell" && <td></td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryDocument;
