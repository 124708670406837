import React from "react";
import HpContent from "../../frontend/homepage/homepageContent";

class HomePage extends React.Component {
  render() {
    return (
      <div className="homePage">
        <HpContent />
      </div>
    );
  }
}

export default HomePage;
