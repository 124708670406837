import React from "react";
import CustomInput from "./customInput";
import Translate from "react-translate-component";

function inputGroup({
  agencyCountry,
  handleCopyAdress,
  setRequirements,
  formData,
  showLot,
  folderType,
  handleInputChange,
  setDate,
  prefix,
  values,
  conditionValue,
  index,
  docIndex,
  setValidation,
  copyBuyerAddress,
}) {
  return (
    <div>
      <div className="row">
        {(formData || []).map((data, i) => (
          <div key={i} className={`${data.klass} col-sm-12`}>
            {data.name === "sellerAddressNumber" && conditionValue === "privateSeller" && (
              <div>
                <Translate className="copyLink" onClick={handleCopyAdress} content={index === 0 ? "copyAdress" : `copySellerAddress.${folderType}`} />
              </div>
            )}

            {data.name === "buyerAddressNumber" && index > 0 && conditionValue === "privateSeller" && (
              <div>
                <Translate className="copyLink" onClick={copyBuyerAddress} content={`copyBuyerAddress.${folderType}`} />
              </div>
            )}
            <CustomInput
              key={`${prefix}_${i}`}
              prefix={prefix}
              index={index}
              data={data}
              setRequirements={setRequirements}
              folderType={folderType}
              handleInputChange={handleInputChange}
              setDate={setDate}
              value={values[data.name]}
              conditionValue={conditionValue}
              docIndex={docIndex}
              setValidation={setValidation}
              showLot={showLot}
              agencyCountry={agencyCountry}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default inputGroup;
