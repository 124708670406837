export default {
  total: "Total ouvert ",
  copySellerAddress: {
    sell: "Remplir l’adresse du premier vendeur",
    rent: "Remplir l’adresse du premier propriéaire",
  },
  copyBuyerAddress: {
    sell: "Remplir l’adresse du premier propriéaire",
    rent: "Remplir l’adresse du premier locataire",
  },
  insight: {
    count: "Nombre de dossier",
    commission: "Commission agence",
  },
  sepa: {
    name: "Name",
    email: "Email Address",
    promo: "Code promo",

    card: "Carte de crédit",
    sepa: "Ordre permanent (SEPA)",
    name_placeholder: "Nom du titulaire du compte bancaire",
    email_placeholder: "Adresse e-mail",
    promo_placeholder: "Code promo ...",
    button: "Mettre en place le prélèvement SEPA",
    acceptance:
      "En fournissant votre IBAN et en confirmant ce paiement, vous autorisez Rocketship Inc. et Stripe, notre fournisseur de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Le remboursement doit être demandé dans un délai de huit semaines à compter de la date à laquelle votre compte a été débité.",
  },
  active: "Actif",
  active_folder: "Dossier en cours",
  filePond: {
    labelIdle: ' Glissez et déposez vos fichiers ou <span class="filepond--label-action"> cliquez ici pour en télécharger </span>',
  },
  download_doc: "Cliquez ici pour télécharger un mode d'emploi",
  copyAdress: "Utiliser l'adresse du bien à vendre",
  copySBAdress: "Copier l'adresse du propriétaire ci-dessus",
  subscription_card: {
    by_month: "/ mois",
    htva: "htva",
  },
  subscription_form: {
    first_title: "Sélectionnez votre mode de paiement:",
    title: "Saisissez les données de votre carte de crédit",
    confirm: "Commander",
    name_placeholder: "Nom du titulaire de la carte de crédit",
    promo_placeholder: "Code promo",
    name_error: "Saisissez votre nom svp.",
  },
  modal: {
    suppression: {
      body: "Voulez-vous vraiment supprimer ces données?",
      close: "Fermer",
      confirmation: "Confirmer",
      title: "Confirmation",
      folder: {
        body:
          "Si vous validez la suppression, le dossier sera définitivement supprimé et toutes les données seront perdues. Vous ne pourrez plus y accéder par la suite! Voulez-vous vraiment confirmer la suppression ?",
        close: "Annuler ",
        confirmation: "Confirmer",
        title: "Attention",
      },
    },
  },
  access: {
    folder: {
      no_agency_action: "Appuyer svp",
      no_agency_btn: "Créer une agence",
      no_agency_media: "Votre abonnement actuel ne le permet pas :-(",
      no_agency_media_btn: "Cliquez ici pour mettre à jour",
      no_agency_placeholder: "Vous devez d'abord créer votre agence !",
      no_subscription_action: "Cliquez svp",
      no_subscription_btn: "Créer un abonnement",
      no_subscription_placeholder: "Vous devez vous abonner pour générer des documents !",
    },
  },
  authentication: {
    account: "Mon compte",
    logout: "Déconnecter",
    requests: "Requêtes",
    reset_title: "Réinitialiser le mot de passe",
    signin: "Se connecter",
    signin_title: "Se connecter",
    signup: "S'inscrire",
    signup_title: "Créer un compte",
    forgot: {
      link: "Mot de passe oublié ?",
      label: "Entrez svp l'adresse e-mail associée à votre compte. Nous vous y enverrons alors un e-mail pour réinitialiser votre mot de passe.",
      submit: "Valider",
      placeholder: "e-mail",
      error: "Aucun compte n'est associé à cette adresse e-mail. Saisissez svp la bonne adresse e-mail associée à votre compte.",
      success:
        "Nous vous avons envoyé un mail pour finaliser la réinitialisation de votre mot de passe. Si vous ne le trouvez pas, vérifiez aussi dans votre dossier de courrier indésirable.",
    },
  },
  badge: {
    feelfree: "Facultatif",
    optional: "Facultatif",
    required: "Obligatoire",
  },
  custom_files: {
    agency_files: "Modèles de mon agence",
    choose_file: "Choisir un fichier",
    plz_choose_file: "Choisissez un fichier",
    file: {
      added: "Modèle ajouté avec succès",
    },
    modal: {
      close: "Annuler",
      delete: "Supprimer",
      placeholder: "Voulez-vous vraiment supprimer ce fichier?",
      title: "Confirmation",
    },
    types: {
      placeholder:
        "Cette section vous permet de remplacer les modèles standard fournis par orea par vos modèles personnalisés et (en fonction de votre abonnement) d'ajouter des modèles supplémentaires, tels que des mandats de vente ou tout autre document que vous devez générer régulièrement.",
      rent: "Modèles de location",
      sell: "Modèles de vente",
      title: "Modèles personnalisés pour mon agence",
    },
  },
  documents: {
    rent: {
      an: "Attestation notaire",
      ara: "Analyse risque locataire",
      c: "Bail",
      f: "Facture",
      pb: "Provenance du bien",
      pf: "Provenance des fonds",
      prv: "Analyse risque propriétaire",
      rgpdal: "RGPD Locataire",
      rgpdp: "RGPD propriétaire",
    },
    sell: {
      an: "Attestation notaire",
      ara: "Analyse risque Acheteur",
      c: "Compromis de vente",
      f: "Facture",
      pb: "Provenance du bien",
      pf: "Provenance des fonds",
      prv: "Analyse risque Vendeur",
      rgpdal: "RGPD acheteur",
      rgpdp: "RGPD propriétaire",
    },
    types: {
      an: "Attestation notaire",
      ara: "Analyse risque Acheteur",
      c: "Compromis",
      f: "Facture",
      pb: "Provenance du bien",
      pf: "Provenance des fonds",
      prv: "Analyse risque Vendeur",
    },
  },
  flash: {
    agency: {
      success: {
        create: "Informations sur agence créées avec succès",
        update: "Informations de l'agence mises à jour avec succès",
      },
    },
    auth: {
      authTitle: "Authentification",
      connected: "Connecté avec succès",
      disconected: "Déconnecté avec succès",
      registred: "Enregistré avec succès",
      reset: "Mot de passe réinitialisé avec succès",
    },
    error: {
      general: "Erreur! Quelque chose ne va pas, désolé :-(",
    },
    folder: {
      success: {
        create: "Nouveau dossier créé avec succès",
        update: "Dossier mis à jour avec succès",
      },
    },
    invitation: {
      sended: "Invitation envoyée avec succès",
    },
    subscription: {
      success: {
        message: "Nouvel abonnement",
        title: "Félicitations",
      },
    },
  },
  folder_requests: {
    table: {
      agency: "Agence",
      folder: "Dossier",
      folderType: "Type",
      reference: "#Ref",
      reset_filter: "Réinitialiser filtres",
      section: "Section",
      title: "MES DOSSIERS",
      delete: "Supprimer dossier",
      mailBox: "Messagerie",
    },
  },
  folders: {
    actions: {
      rent: "Nouveau dossier de location",
      sell: "Nouveau dossier de vente",
    },
    table: {
      actions: "Actions",
      createdAt: "Création",
      foldertype: "Dossier",
      locality: "Localité",
      reference: "#Ref",
      reset_filter: "Réinitialiser le filtre",
      seller: "Propriétaire",
      street: "Rue",
      title: "Dossiers en cours",
      type: "Type",
      step: "Etape",
      types: {
        rent: "Location",
        sell: "Vente",
      },
      delete: "Supprimer dossier",
      mailBox: "Messagerie",
    },
  },
  forms: {
    agency: {
      labels: {
        Autorisation: "Autorisation de commerce",
        Bic: "BIC",
        City: "Localité <span>*</span>",
        CompanyName: "Nom de société <span>*</span>",
        DpofirstName: "Prénom",
        DpolastName: "Nom de famille",
        Email: "e-mail",
        Iban: "IBAN",
        ManagerEmail: "e-mail",
        ManagerFirstName: "Prénom",
        ManagerLastName: "Nom de famille",
        ManagerLocality: "Ville de résidence",
        ManagerProfession: "Titre",
        Phone: "Numéro de téléphone",
        PostalCode: "Code postal <span>*</span>",
        RbfirstName: "Prénom",
        RblastName: "Nom de famille",
        Rcsl: "Numéro registre de commerce",
        StreetName: "Nom de rue <span>*</span>",
        StreetNumber: "Numéro <span>*</span>",
        Tva: "Tva <span>*</span>",
        rgpd: "J’accepte que mes données soient stockées par Linc SA dans le respect des règles <a href='/cgu' target='_blank'>RGPD</a>.",
        Country: "Pays <span>*</span>",
        Lu: "Luxembourg",
        Fr: "France",
      },
      placeholder:
        "Ces informations sont utilisées pour remplir automatiquement les données de votre agence dans les différents modèles. Remplissez-les soigneusement pour que les documents puissent être remplis avec toutes les informations nécessaires.",
      placeholders: {
        Autorisation: "Autorisation",
        Bic: "BIC",
        City: "Localité",
        CompanyName: "Nom de l'agence (raison sociale)",
        DpofirstName: "Prénom",
        DpolastName: "Nom de famille",
        Email: "e-mail",
        Iban: "IBAN",
        ManagerEmail: "e-mail",
        ManagerFirstName: "Prénom",
        ManagerLastName: "Nom de famille",
        ManagerLocality: "Ville de résidence",
        ManagerProfession: "Titre",
        Phone: "Numéro de téléphone",
        PostalCode: "Code postal (avec identification du pays, p.ex. L-xxxx)",
        RbfirstName: "Prénom",
        RblastName: "Nom de famille",
        Rcsl: "Numéro de registre de commerce",
        StreetName: "Nom de rue",
        StreetNumber: "Numéro de rue",
        Tva: "Numéro de TVA",
      },
      sections: {
        agency: "Données de base",
        dpo: "Responsable Données / RGPD",
        informations: "Informations principales",
        manager: "Manager de l'agence",
        rb: "Responsable anti-blanchiment",
      },
      submit: "Valider",
      title: "Informations sur l'agence",
      insights: "Aperçus",
      validation: {
        email: "E-mail Invalide",
        required: "Ce champ est requis !",
      },
    },
    folder: {
      documents: {
        new: {
          title: "Ajouter un document",
          type: {
            label: "Type",
            sell: {
              buyer: "Acheteur",
              seller: "Vendeur",
              transaction: "Transaction",
              bank: "Banque",
            },
            rent: {
              buyer: "Locataire",
              seller: "Propriétaire",
              transaction: "BAIL",
              bank: "Banque",
            },
          },
          select: {
            buyer: "Selectionnez un ou plusieurs acheteurs",
            seller: "Selectionnez un ou plusieurs vendeurs",
          },
          file: {
            rgpd: "RGPD",
            pb: "Provenance du bien",
            pf: "Provenance des fonds",
            ar: "Analyse risque",
            cid: "Carte Id",
            css: "Carte de sécurité sociale",
            co: "Compromis",
            fa: "Facture",
            at: "Attestation notaire",
            ma: "Mandat",
            ac: "Acte",
            ap: "accord bancaire",
            dp: "dépôt de prêt",
            ba: "Bail",
            bo: "Etat des lieux fait",
            cc: "Cahier de charges",
          },
          choose_document: "choisissez le type de document que vous voulez ajouter",
          add_file: "Ajoutez vos fichiers",
          summary: "Documents enregistrés",
        },
      },
      buttons: {
        add_buyer: "Ajouter un acheteur",
        add_seller: "Ajouter un vendeur",
        next: "Suivant <i class='fa fa-caret-right'></i>",
        prev: "<i class='fa fa-caret-left'></i> Précédent",
        remove_buyer: "Supprimer l'acheteur",
        remove_seller: "Supprimer le vendeur",
        validate: "Valider",
      },
      download: {
        title: "Télécharger le document",
      },
      errors: {
        required: "Ce champ est obligatoire",
      },
      generation: {
        title: "Générez les documents en",
        upgrade_action_call: "Offres d'abonnement",
        upgrade_placeholder: "Upgrader votre abonnement pour ajouter des fichiers personnalisés!",
      },
      labels: {
        propertyPlaceName: "Lieudit",
        propertyNature: "Nature",
        propertyOccupation: "Occupation",
        EcoBen: "Bénéficiaires économiques",
        actAcquisitionResale: "Acquisition en vue de revente",
        actBankguaranteeAmount: "Montant de la garantie bancaire",
        actBankguaranteeAmountLetters: "Garantie bancaire (en lettres)",
        actBankguaranteeMonths: "Garantie bancaire - nb de mois",
        actBankguaranteeType: "Garantie bancaire - type",
        actBeneficiary: "Bénéficiaire (propriétaire compte)",
        actBeneficiaryBic: "BIC bénéficiaire",
        actBeneficiaryIban: "IBAN bénéficiaire",
        actCharge: "Charges",
        actChargeLettres: "Charges (en lettres)",
        actDate: "Date de l'acte",
        actDateAtTheLeast: "Date de l'acte (au plus tard le)",
        actDeadlineBank: "Délai pour accord bancaire",
        actEffectiveDate: "Date d'entrée en jouissance",
        actEmail: "E-Mail du clerc du notaire",
        actLocality: "Localité (notaire de résidence à)",
        actNotaryLastName: "Nom de famille du notaire",
        actRentCommission: "Commission (nombre de mois)",
        actSellCommission: "Commission en %",
        refAedmandat: "Réf AED mandat",
        refAedcompromis: "Réf AED compromis",
        actEstimetedPrice: "Prix estimé (dossier prospection)",
        actMandatPrice: "Prix mandat",
        actSellingPrice: {
          rent: "Loyer mensuel",
          sell: "Prix de vente (compromis, acte)",
        },
        actSellingPriceLetters: {
          rent: "Loyer mensuel en toutes lettres",
          sell: "Prix en lettres",
        },
        sellerType: {
          sell: "Veuillez choisir le type de vendeur que vous souhaitez ajouter",
          rent: "Veuillez choisir le type de propriétaire que vous souhaitez ajouter",
        },
        buyerType: {
          sell: "Veuillez choisir le type d'acheteur que vous souhaitez ajouter",
          rent: "Veuillez choisir le type de locataire que vous souhaitez ajouter",
        },

        addEcoBen: "Ajouter un bénéficiaire",
        benefFirstName: "Nom",
        benefLastName: "Prénom",
        birthLocality: "Nationalité",
        buyerAddressCountry: "Pays",
        buyerAddressLocality: "Localité",
        buyerAddressNumber: "Numéro",
        buyerAddressStreet: "Nom de rue",
        buyerAddressZipCode: "Code postal",
        buyerBirthDate: "Date de naissance (JJ-MM-AAAA)",
        buyerCivilStatus: "Etat civil",
        buyerCompanyForm: "Forme de société",
        buyerCompanyName: "Raison sociale",
        buyerEMail: "e-mail",
        buyerEcoBen: "Bénéficiaire économique",
        buyerFirstName: "Prénom",
        buyerIdNum: "Numéro de carte d'identité ou passeport",
        buyerLastName: "Nom",
        buyerLegalRep: "Représentant légal",
        buyerMatSecSoc: "Matricule de sécurité sociale",
        buyerNationality: "Nationalité",
        buyerNumRc: "Numéro Registre de Commerce",
        buyerNumTva: "Numéro TVA",
        buyerPhone: "Tél.",
        buyerProf: "Profession",
        buyerRepAddressCountry: "Pays",
        buyerRepAddressLocality: "Localité",
        buyerRepAddressNumber: "Numéro",
        buyerRepAddressStreet: "Nom de rue",
        buyerRepAddressZipCode: "Code postal",
        buyerRepEmail: "e-mail",
        city: "Localité",
        civilStatus: "État civil",
        country: "Pays",
        numId: "Numéro carte d'identité",
        profession: "Profession",
        propertyAddressCountry: "Pays",
        propertyAddressFrom: "De",
        propertyAddressLocality: "Localité",
        propertyAddressMunicipal: "Commune",
        propertyAddressNumber: "Numéro",
        propertyAddressSection: "Section",
        propertyAddressStreet: "Rue",
        propertyAddressZipCode: "Code postal",
        propertyAreTer: "Taille du terrain (a)",
        propertyBailEmp: "Bail emphytéotique",
        propertyBasement: "sous-sol, cave(s) (m²)",
        propertyConstructionYear: "Année de construction",
        propertyCpe: "CPE",
        propertyEmail: "e-mail",
        propertyEmpExt: "Nb emplacements extérieurs",
        propertyEmpInt: "Nb emplacements intérieurs",
        propertyHabSousBail: "Habitation sous bail",
        propertyLot: "Lot(s)",
        propertyNrbBedrom: "Nb de chambres",
        propertyNumCad: "Numéro cadastral",
        propertyServ: "Servitude",
        propertySitHyp: "Situation hypothécaire",
        propertySurHab: "Surface habitable (m²)",
        propertyTerBal: "Terrasse, balcon (m²)",
        propertyType: "Type",
        secSoc: "Sécurité sociale",
        sellerAddressCountry: "Pays",
        sellerAddressLocality: "Localité",
        sellerAddressNumber: "Numéro",
        sellerAddressStreet: "Rue",
        sellerAddressZipCode: "Code postal",
        sellerBirthDate: "Date de naissance (JJ-MM-AAAA)",
        sellerCivilStatus: "Etat civil",
        sellerCompanyForm: "Forme de société",
        sellerCompanyName: "Raison sociale",
        sellerEMail: "e-mail",
        sellerFirstName: "Prénom",
        sellerIdNum: "Numéro de carte d'identité ou Passeport",
        sellerLastName: "Nom",
        sellerLegalRep: "Représentant légal",
        sellerMatSecSoc: "Matricule de sécurité sociale",
        sellerNationality: "Nationalité",
        sellerNumRc: "Numéro Registre de Commerce",
        sellerNumTva: "Numéro TVA",
        sellerPhone: "Tél.",
        sellerProf: "Profession",
        sellerRepAddressCountry: "Pays",
        sellerRepAddressLocality: "Localité",
        sellerRepAddressNumber: "Numéro",
        sellerRepAddressStreet: "Rue",
        sellerRepAddressZipCode: "Code postal",
        sellerRepEmail: "e-mail",
        streetName: "Nom de rue",
        streetNumber: "Numéro de rue",
        zipCode: "Code postale",
        buyerLabel: "",
        sellerLabel: "",
      },
      modal: {
        continue_button: "Non, compléter le formulaire",
        generate_button: "Oui, télécharger le document",
        placeholder: "Formulaire incomplet, êtes-vous sûr de générer ce document?",
        title: "Confirmation",
      },
      options: {
        status: {
          pr: "Prospect",
          ma: "Mandat",
          co: "Compromis",
          bo: "Banque OK",
          af: "Acte fixé",
          ac: "Acté",
          pe: "Perdu",
          rent: {
            pr: "Potent. Kunde",
            ma: "Mandat signé",
            co: "Bail signé",
            bo: "Etat des lieux fait",
            af: "Facturé",
            ac: "Payé",
            pe: "Perdu",
          },
          sell: {
            pr: "Prospect",
            ma: "Mandat",
            co: "Compromis",
            bo: "Banque OK",
            af: "Acte fixé",
            ac: "Acté",
            pe: "Perdu",
          },
        },
        CompanySeller: "Société",
        apartment: "Appartement",
        commerce: "Commerce",
        corner_house: "Maison de coin",
        detached_house: "Maison isolée",
        divorced: "Divorcé",
        duplex: "Duplex",
        garage: "Garage",
        ground: "Terrain",
        land: "Terrain",
        loft: "Loft",
        married: "Marié - Pacsé",
        non: "Non",
        office: "Bureau",
        penthouse: "Penthouse",
        privateSeller: "Privé",
        row_house: "Maison en bande",
        semi_detached_house: "Maison jumelée",
        single: "Célibataire",
        studio: "Studio",
        triplex: "Triplex",
        widowed: "Veuf, veuve",
        yes: "Oui",
        mr: "Monsieur",
        mrs: "Madame",
      },
      submit: "Enregistrer",
      title: "Dossiers clients",
      requirements: "Requis pour",
      invite: {
        button_action: "Inviter",
        email_placeholder: "Remplissez 1 e-mail de destination à la fois",
        placeholder:
          "Ici, vous pouvez inviter les clients et le notaire à accéder à leur onglet d'informations, afin qu'ils puissent remplir eux-mêmes leurs données respectives. Chaque personne que vous invitez n'aura accès qu'à l'onglet respectif, c'est-à-dire que les vendeurs n'auront accès qu'à l'onglet complet des vendeurs. Utilisez cette fonctionnalité conviviale pour réduire votre charge de travail et recevoir les informations requises dès que possible. Veuillez inviter les utilisateurs un par un et sélectionnez à chaque fois l'onglet unique auquel ils doivent avoir accès.",
        title: "Invitez les clients ou notaire à ajouter des données",
        give_access: "Sélectionnez l'onglet auquel vous voulez donner accès:",
        email_label: "E-mail de la personne que vous voulez inviter",
      },
    },
    reset: {
      placeholders: {
        password: "Reset Password",
        password_confirmation: "Confirmation mot de passe",
      },
      submit: "Réinitialiser",
    },
    signin: {
      errors: {
        login: "Veuillez vérifier votre e-mail / mot de passe",
      },
      placeholders: {
        email: "e-mail",
        password: "Mot de passe",
      },
      submit: "Se connecter",
      validations: {
        email: "E-mail invalide",
        email_req: "E-mail obligatoire",
        password: "Mot de passe obligatoire",
      },
    },
    signup: {
      errors: {
        register: "Veuillez vérifier votre e-mail / mot de passe",
      },
      placeholders: {
        email: "E-mail",
        password: "Mot de passe",
        password_confirmation: "Confirmation mot de passe",
      },
      labels: {
        cgu: "J'ai lu et j'accepte les <a href='/cgu' target='_blank'>CGU</a>",
        cgv: "J'ai lu et j'accepte les <a href='/cgv' target='_blank'>Conditions Générales de Vente et d'Utilisation</a>.",
      },
      submit: "Créer un compte",
      validations: {
        confirmation: "Confirmation incorrecte",
        email: "E-mail invalide",
        email_req: "E-mail est requis",
        password: "Le mot de passe doit contenir au moins 1 minuscule, 1 majuscule, 1 caractère numérique, 1 caractère spécial et être d'au moins 6 caractères.",
        password_confirmation_req: "La confirmation du mot de passe est requise",
        password_req: "Mot de passe requis",
        cgu: "Cgu is required",
        cgv: "Cgv is required",
      },
    },
  },
  homepage: {
    advantages: {
      placeholder: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      rent: "Location",
      sell: "Vente",
      title: "<span>Documents en</span> <span>2 clicks</span>",
    },
    comparison: {
      action_call: "Découvrez nos formules àpd x EUR/mois",
      before: "Aujourd’hui",
      negatif: {
        first: "Vous perdez du temps inutile à saisir de manière répétitive les mêmes informations…",
        second: "dans tous les documents… ",
        sub_first: "(noms, adresses, professions, nationalité, carte d’identité, matricule des vendeurs et des acheteurs ; prix ; adresse et détails du bien ; notaire etc)",
        sub_second:
          "(déclaration de provenance des fonds acheteur,  déclaration de provenance des fonds vendeur, fiche d’analyse de risque, compromis, attestation de sincérité, facture,…)",
        third: "et à contrôler si vous avez bien rempli toutes les obligations légales pour éviter des amendes.",
      },
      now: "Demain, avec ImmoAML",
      placeholder: "Le temps est ce que vous avez de plus précieux. Ne le gaspillez pas !",
      positif: {
        first: "Saisissez une seule fois toutes les données",
        more: "et soyez sûr d’être irréprochable en cas de contrôle.",
        second: "Générez tous les documents en 2 clicks, au bon moment",
        third: "Accédez facilement à toutes les informations actuelles et historiques",
      },
    },
    concept: {
      body:
        "<p>Conçu en étroite collaboration avec des professionnels de l’immobilier.</p><p>IMMOAML gère tous vos documents administratifs en 2 clicks, en 3 langues, sur base de modèles juridiquement validés.</p>",
      title: "<span>Titre</span> <span>Concept</span>",
    },
    contact: {
      information: "information",
      title: "Contact",
    },
    intro: {
      body:
        "<p>Le travail administratif est le volet le plus agaçant pour tout bon agent immobilier.</p><p>Malheureusement, les contraintes légales deviennent de plus en plus envahissantes et imposent du travail rébarbatif et inutile</p>",
      title: "<span>Title</span> <span>Intro</span>",
    },
    sliders: {
      action_call: "Facilitez-vous la vie !",
      slider1: {
        list:
          "<ul><li>de remplir jusqu’à 7 fois les mêmes données ?</li><li>de perdre inutilement du temps précieux ?</li><li>de vérifier constamment que vous respectez toutes les formalités ?</li></ul>",
        title: "<span>Vous n’en avez </span><span> pas aussi assez…</span>",
      },
      slider2: {
        list:
          "<ul><li>Economisez des heures de travail administratif</li><li>Ne risquez plus d’amende en cas de contrôle</li><li>Garantissez à vos clients des documents de qualité en 3 langues</li></ul>",
        title: "<span>A partir de </span><span> seulement X eur/mois</span>",
      },
      slider3: {
        list:
          "<ul><li>Stop au travail administratif rébarbatif</li><li>Réduisez considérablement votre charge de travail</li><li>Gagnez en productivité et en temps libre</li></ul>",
        title: "<span>Arrêtez de gaspiller </span><span> votre temps !</span>",
      },
      slider4: {
        list:
          "<ul><li>Générez tous les documents administratifs et légaux en 2 clicks</li><li>Assurez une qualité exemplaire à vos clients</li><li>Soyez serein en cas de contrôle</li></ul>",
        title: "<span>Soyez au Top </span><span> de la qualité administrative, sans efforts !</span>",
      },
    },
    subscriptions: {
      placeholder:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
      title: "<span>Nos</span> <span>abonnements</span> ",
    },
    testimonials: {
      testimonials_1: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Bennani Ayoub",
        profession: "Software developper",
      },
      testimonials_2: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Marc Neuen",
        profession: "CEO & Founder, Creativeracer",
      },
      testimonials_3: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Julien Gras",
        profession: "CTO and dakchi",
      },
      title: "<span>What clients</span> <span>say</span>",
    },
    usp: {
      action: "Souscrivez à la formule de votre choix.",
      placeholder: "Evitez les amendes en cas de contrôle. Assurez-vous que vos documents soient irréprochables. ",
      usp_1: {
        body: "Saisissez une seule fois chaque info pour alimenter tous les documents requis.",
        title: "Usp title 1",
      },
      usp_2: {
        body: "Tous les documents sont validés juridiquement et disponibles en 3 langues.",
        title: "Usp title 2",
      },
      usp_3: {
        body: "Adaptez facilement les documents selon les besoins spécifiques du dossier et utilisez vos propres modèles personnalisés.",
        title: "Usp title 3",
      },
      usp_4: {
        body: "Accédez à tout moment à toutes les données et à tous les documents…  en cours et  historiques.",
        title: "Usp title 4",
      },
      usp_5: {
        body: "Toutes les données sensibles sont encryptées. Juste vous y avez accès.",
        title: "Usp title 5",
      },
    },
  },
  languages: {
    de: "Deutsch",
    en: "English",
    fr: "Français",
  },
  mailbox: {
    new: {
      errors: {
        body: "Merci d'écrire un message",
        participants: "Sélectionner destinataire(s)",
        subject: "Saisisse un sujet",
      },
      participants: "Participant(s)",
      placeholder: "Écrivez un nouveau message de",
      receivers: {
        agency: "Agence",
        buyers: "Acheteurs",
        notary: "Notaire",
        owner: "Propriétaires",
        sellers: "Vendeurs",
      },
      subject: "Sujet: ",
    },
  },
  navbar: {
    account: {
      custom_files: "Modèles",
      folders: "Mes dossiers",
    },
    subscription: "Abonnement",
  },
  navigation: {
    tabs: {
      property: "Infos bien",
      rent: {
        act: "Infos bail",
        buyer: "Infos locataire",
        property: "Infos bien",
        seller: "Infos Propr.",
        documents: "Documents",
      },
      sell: {
        act: "Infos acte",
        buyer: "Infos acheteur",
        property: "Infos bien",
        seller: "Infos Vendeur",
        documents: "Documents",
      },
    },
  },
  payment: {
    detail: {
      delay: "Période : ",
      from: "Début de validité : ",
      periodPrice: "Prix pour la période : ",
      price: "Prix facturé pour la période : ",
      to: "À :",
      by_month: "/ mois",
      months: "mois",
    },
  },
  redirect: {
    to_signin: "Connectez-vous",
    to_signin_txt: "Vous avez déjà un compte ? ",
    to_signup: "S'inscrire",
    to_signup_txt: "Vous n’avez pas encore de compte ? ",
  },
  rent: {
    title: "Nouveau dossier de location",
  },
  rgpd: "RGPD",
  sell: {
    title: "Nouveau dossier de vente",
  },
  subscriptions: {
    end_subscription: "Arrêter l'abonnement",
    reactivate: "Relancer l'abonnement",
    solde: false,
    modal: {
      close: "Fermer",
      end: "Je confirme",
      placeholder: "Êtes-vous vraiment sûr de vouloir mettre fin à votre abonnement?",
      title: "Confirmation",
    },
    names: {
      r_2y: "Basic - Location 24 mois",
      r_6: "Basic - Location 6 mois ",
      r_12: "Basic - Location 12 mois",
      s_2y: "Basic - Vente 24 mois",
      srm_2y: "Premium - 24 mois",
      srm_6: "Premium - 6 mois",
      srm_12: "Premium - 12 mois",
      sr_2y: "Standard- 24 mois",
      sr_6: "Standard- 6 mois",
      sr_12: "Standard- 12 mois",
      s_6: "Basic - Vente 6 mois",
      s_12: "Basic - Vente 12 mois",
    },
    new: "Choisissez votre abonnement",
    orders: {
      activeStatus: {
        false: "Expiré",
        true: "Actif",
      },
      header: {
        OrderEnd: "Date de fin",
        OrderStart: "Date de début",
        Orderstatus: "Statut",
        orderTitle: "Titre",
      },
    },
    periodPrice: "Prix pour période",
    reduction: {
      twoyears: "Remise 24 mois",
      sixMonths: "Remise 6 mois",
      twelveMonths: "Remise 12 mois",
    },
    rent: {
      option1: {
        title: "Fichiers VENTE uniquement",
        valid: false,
      },
      option2: {
        title: "Fichiers LOCATION uniquement",
        valid: true,
      },
      option3: {
        title: "Fichiers personnalisés",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "r_2y",
          formula: "24 mois",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "r_6",
          formula: "6 mois",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "r_12",
          formula: "12 mois",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - Location",
    },
    select: "Sélectionner",
    sell: {
      option1: {
        title: "Fichiers VENTE uniquement",
        valid: true,
      },
      option2: {
        title: "Fichiers LOCATION uniquement",
        valid: false,
      },
      option3: {
        title: "Fichiers personnalisés",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "s_2y",
          formula: "24 mois",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "s_6",
          formula: "6 mois",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "s_12",
          formula: "12 mois",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - Ventes",
    },
    sell_rent: {
      option1: {
        title: "Fichiers Vente",
        valid: true,
      },
      option2: {
        title: "Fichiers Location",
        valid: true,
      },
      option3: {
        title: "Historique et Archives",
        valid: true,
      },
      option4: {
        title: "-",
        valid: false,
      },
      option5: {
        title: "-",
        valid: false,
      },
      option6: {
        title: "-",
        valid: false,
      },
      option7: {
        title: "-",
        valid: false,
      },
      option8: {
        title: "-",
        valid: false,
      },
      option9: {
        title: "-",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "sr_2y",
          formula: "24 mois",
          delay: 24,
          periodPrice: 1176,
          price: 49,
        },
        sixMonths: {
          code: "sr_6",
          formula: "6 mois",
          delay: 6,
          periodPrice: 474,
          price: 79,
        },
        twelveMonths: {
          code: "sr_12",
          formula: "12 mois",
          delay: 12,
          periodPrice: 828,
          price: 69,
        },
      },
      title: "STANDARD",
    },
    sell_rent_media: {
      option1: {
        title: "Fichiers Vente",
        valid: true,
      },
      option2: {
        title: "Fichiers Location",
        valid: true,
      },
      option3: {
        title: "Historique et Archives",
        valid: true,
      },
      option4: {
        title: "Modification des modèles fournis",
        valid: true,
      },
      option5: {
        title: "Upload de vos modèles personnalisés",
        valid: true,
      },
      option6: {
        title: "Saisie des infos par les clients",
        valid: true,
      },
      option7: {
        title: "Upload et stockage de documents (mai)",
        valid: true,
      },
      option8: {
        title: "Messagerie (mai)",
        valid: true,
      },
      option9: {
        title: "Signatures électroniques (juin)",
        valid: true,
      },

      periods: {
        twoyears: {
          code: "srm_2y",
          formula: "24 mois",
          delay: 24,
          periodPrice: 1656,
          price: 69,
        },
        sixMonths: {
          code: "srm_6",
          formula: "6 mois",
          delay: 6,
          periodPrice: 594,
          price: 99,
        },
        twelveMonths: {
          code: "srm_12",
          formula: "12 mois",
          delay: 12,
          periodPrice: 1068,
          price: 89,
        },
      },
      title: "PREMIUM",
    },
    title: "Abonnement",
    upgrade: "Mettre à niveau l'abonnement",
  },
  title: "titre en",
};
