import React from "react";
import Translate from 'react-translate-component';

function Tabs({lang, changeTab}) {
  const active = (tab) => {
    return lang === tab ? 'active' : ''
  }
  return(

      <div className="tabs">
        <div className={`tab ${active('fr')}`} onClick={() => changeTab('fr')}>
          FR
        </div>
        <div className={`tab ${active('en')}`} onClick={() => changeTab('en')}>
          EN
        </div>
        <div className={`tab ${active('de')}`} onClick={() => changeTab('de')}>
          DE
        </div>
      </div>

  )
}

export default Tabs;
