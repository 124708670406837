import React, { useState } from "react";
import Translate from "react-translate-component";
import useForm from "react-hook-form";
import CheckboxGroup from "react-checkbox-group";

function NewConversation({ createConversation }) {
  const { handleSubmit, register, errors } = useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [open, setOpen] = useState(false);
  const initParticipants = ["agency", "sellers", "buyers", "notary"];
  const [participants, setParticipants] = useState([]);

  const onSubmit = (values) => {
    createConversation(values);
    setOpen(false);
  };
  return (
    <div className="message-action">
      <div className="account-email">{user.email}</div>
      <div className="new">
        <button className="new__button new-mail__toggle" onClick={() => setOpen(true)}>
          <i className="fa fa-plus" aria-hidden="true"></i>
        </button>

        <div className={`new-mail ${open ? "active" : ""}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="new-mail__top">
              <div className="new-mail__title"></div>
              <i className="new-mail__close new-mail__toggle fa fa-times" aria-hidden="true" onClick={() => setOpen(false)}></i>
            </div>

            <div className="new-mail-exp">
              <div className="new-mail-exp__item">
                <div className="new-mail-exp__label">
                  <Translate content="mailbox.new.participants" />
                </div>

                <div className="radio">
                  <CheckboxGroup value={participants} onChange={(item) => setParticipants(item)}>
                    {(Checkbox) => (
                      <>
                        {initParticipants.map((c, i) => (
                          <label className="radio-container" key={i}>
                            <Translate content={`mailbox.new.receivers.${c}`} />
                            <Checkbox value={c} />
                            <span className={`checkmark ${participants.includes(c) ? "checked" : "unchecked"}`}></span>
                          </label>
                        ))}
                      </>
                    )}
                  </CheckboxGroup>
                </div>
                <div>
                  <input name="participants" type="hidden" ref={register({ required: true })} value={participants} />
                  {errors.participants && participants.length === 0 && (
                    <div className="error mt-1">
                      <Translate content="mailbox.new.errors.participants" />
                    </div>
                  )}
                </div>
              </div>

              <div className="new-mail-exp__item subject">
                <div className="new-mail-exp__label">
                  <Translate content="mailbox.new.subject" />
                </div>
                <input name="subject" ref={register({ required: true })} placeholder="Enter mail object" type="text" className="new-mail-exp__input" />
              </div>
              {errors.subject && (
                <div className="error mt-2">
                  <Translate content="mailbox.new.errors.subject" />
                </div>
              )}
            </div>

            <div className="new-mail__content">
              <textarea className="new-mail__message" name="body" ref={register({ required: true })} placeholder="..."></textarea>
              {errors.body && (
                <div className="error ml-4">
                  <Translate content="mailbox.new.errors.body" />
                </div>
              )}
            </div>

            <div className="new-mail-foot">
              <div className="new-mail-foot__insert"></div>
              <div className="new-mail-foot__actions">
                <button className="button button new-mail__toggle" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="button button--primary ml-2" type="submit">
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewConversation;
