const initialState = { agencyCountry: false, folder_requests: false, folder_request: false };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FOLDER_REQUESTS":
      return {
        ...state,
        folder_requests: action.folder_requests,
      };
    case "GET_FOLDER_REQUEST":
      return {
        ...state,
        folder_request: action.folder_request,
      };
    case "SET_AGENCY_COUNTRY_UP":
      return {
        ...state,
        agencyCountry: action.agencyCountry,
      };
    default:
      return state;
  }
};

export default authReducer;
