import React from "react";
import Translate from 'react-translate-component';
import { Link } from 'react-router-dom';

function Actions(props) {

  return(
    <div className="row my-5">
      <div className="col-sm-6">
        <Link to='/account/sell/new' className='btn-folder f-sell'>
          <Translate content='folders.actions.sell' />
        </Link>
      </div>

      <div className="col-sm-6">
        <Link to='/account/rent/new' className='btn-folder f-rent'>
          <Translate content='folders.actions.rent' />
        </Link>
      </div>
    </div>
  )
}

export default Actions;
