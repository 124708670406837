import React, { useState } from "react";
import useForm from "react-hook-form";
import Translate from "react-translate-component";
import counterpart from "counterpart";

function AgencyForm(props) {
  const defaultValues = { DpofirstName: "", DpolastName: "", ManagerEmail: "" };
  const initValues = props.agency ? props.agency : defaultValues;
  const { handleSubmit, register, errors } = useForm();
  const [values, setValues] = useState(initValues);
  const [showInfo, setShowInfo] = useState(props.agency.id !== 0 ? true : false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (data, e) => {
    props.submitAgency(values);
  };
  const placeholder = (name) => {
    return counterpart.translate("forms.agency.placeholders." + name);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" defaultValue={values.id} />
      <div className="my-4">
        <div className="py-3">
          <Translate content="forms.agency.title" component="h5" className="agency-title" />
          <Translate content="forms.agency.placeholder" className="agency-sub-title" />
        </div>

        <div className="py-4">
          <div className="row mb-5">
            <div className="col-12">
              <Translate content="forms.agency.sections.informations" className="section-title" />
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="input-group">
                  <label htmlFor="CompanyName" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.CompanyName" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="CompanyName"
                      name="companyName"
                      placeholder={placeholder("CompanyName")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.companyName}
                    />
                    {errors.companyName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>

                <div className="input-group">
                  <label htmlFor="Tva" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.Tva" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="Tva"
                      name="tva"
                      placeholder={placeholder("Tva")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.tva}
                    />
                    {errors.Tva && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>

                <div className="input-group">
                  <label htmlFor="City" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.Country" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <select
                      ref={register({ required: true })}
                      name="country"
                      onChange={handleInputChange}
                      defaultValue={values.country}
                      className="select"
                      disabled={values.id > 0 && values.country !== null ? true : false}
                    >
                      <option value=""></option>
                      <option value="L">{counterpart.translate("forms.agency.labels.Lu")}</option>
                      <option value="F">{counterpart.translate("forms.agency.labels.Fr")}</option>
                    </select>

                    {errors.country && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="input-group">
                  <label htmlFor="StreetNumber" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.StreetNumber" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="StreetNumber"
                      name="streetNumber"
                      placeholder={placeholder("StreetNumber")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.streetNumber}
                    />
                    {errors.streetNumber && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="StreetName" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.StreetName" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="StreetName"
                      name="streetName"
                      placeholder={placeholder("StreetName")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.streetName}
                    />
                    {errors.streetName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="PostalCode" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.PostalCode" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="PostalCode"
                      name="postalCode"
                      placeholder={placeholder("PostalCode")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.postalCode}
                    />
                    {errors.postalCode && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="City" className="col-sm-12 col-form-label">
                    <Translate content="forms.agency.labels.City" unsafe />
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="border"
                      id="City"
                      name="city"
                      placeholder={placeholder("City")}
                      onChange={handleInputChange}
                      ref={register({ required: true })}
                      defaultValue={values.city}
                    />
                    {errors.city && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`agency-sup-information ${showInfo ? "show" : ""}`}>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <Translate content="forms.agency.sections.agency" className="section-title" />
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="Email" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Email" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Email"
                        name="email"
                        placeholder={placeholder("Email")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.email}
                      />
                      {errors.email && <Translate content={errors.email.message} component="p" className="text-warning xs-text" />}
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Phone" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Phone" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Phone"
                        name="phone"
                        placeholder={placeholder("Phone")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.phone}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Rcsl" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Rcsl" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Rcsl"
                        name="rcsl"
                        placeholder={placeholder("Rcsl")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.rcsl}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Autorisation" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Autorisation" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Autorisation"
                        name="autorisation"
                        placeholder={placeholder("Autorisation")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.autorisation}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <Translate content="forms.agency.sections.manager" className="section-title" />
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="ManagerFirstName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.ManagerFirstName" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="ManagerFirstName"
                        name="managerFirstName"
                        placeholder={placeholder("ManagerFirstName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.managerFirstName}
                      />
                      {errors.managerFirstName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="ManagerLastName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.ManagerLastName" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="ManagerLastName"
                        name="managerLastName"
                        placeholder={placeholder("ManagerLastName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.managerLastName}
                      />
                      {errors.managerLastName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="ManagerLocality" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.ManagerLocality" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="ManagerLocality"
                        name="managerLocality"
                        placeholder={placeholder("ManagerLocality")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.managerLocality}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="ManagerProfession" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.ManagerProfession" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="ManagerProfession"
                        name="managerProfession"
                        placeholder={placeholder("ManagerProfession")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.managerProfession}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="ManagerEmail" className="col-form-label col-12">
                      <Translate content="forms.agency.labels.ManagerEmail" unsafe />
                    </label>
                    <div className="col-12">
                      <input
                        type="text"
                        className="border"
                        id="ManagerEmail"
                        name="managerEmail"
                        placeholder={placeholder("ManagerEmail")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.managerEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <Translate content="forms.agency.sections.rb" className="section-title" />
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="RbfirstName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.RbfirstName" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="RbfirstName"
                        name="rbfirstName"
                        placeholder={placeholder("RbfirstName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.rbfirstName}
                      />
                      {errors.rbfirstName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="RblastName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.RblastName" unsafe />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="RblastName"
                        name="rblastName"
                        placeholder={placeholder("RblastName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.rblastName}
                      />
                      {errors.rblastName && <Translate content="forms.agency.validation.required" component="p" className="text-warning xs-text" />}
                    </div>
                  </div>
                </div>
                <Translate content="forms.agency.sections.dpo" className="section-title mt-5 mb-1" component="div" />
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="DpofirstName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.DpofirstName" />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="DpofirstName"
                        name="dpofirstName"
                        placeholder={placeholder("DpofirstName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.dpofirstName}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="DpolastName" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.DpolastName" />
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="DpolastName"
                        name="dpolastName"
                        placeholder={placeholder("DpolastName")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.dpolastName}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="col-12 mt-4">
                <div className="section-title"> Iban / Bic</div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="Iban" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Iban" unsafe /> 1
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Iban"
                        name="iban"
                        placeholder={placeholder("Iban")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.iban}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Iban" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Iban" unsafe /> 2
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Iban2"
                        name="iban2"
                        placeholder={placeholder("Iban")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.iban2}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Iban" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Iban" unsafe /> 3
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Iban3"
                        name="iban3"
                        placeholder={placeholder("Iban")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.iban3}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Iban" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Iban" unsafe /> 4
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Iban4"
                        name="iban4"
                        placeholder={placeholder("Iban")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.iban4}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="Bic" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Bic" unsafe /> 1
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Bic"
                        name="bic"
                        placeholder={placeholder("Bic")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.bic}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Bic" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Bic" unsafe /> 2
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Bic2"
                        name="bic2"
                        placeholder={placeholder("Bic")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.bic2}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Bic" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Bic" unsafe /> 3
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Bic3"
                        name="bic3"
                        placeholder={placeholder("Bic")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.bic3}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="Bic" className="col-sm-12 col-form-label">
                      <Translate content="forms.agency.labels.Bic" unsafe /> 4
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="border"
                        id="Bic4"
                        name="bic4"
                        placeholder={placeholder("Bic")}
                        onChange={handleInputChange}
                        ref={register()}
                        defaultValue={values.bic4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`agency-toggle mt-3`} onClick={() => setShowInfo(!showInfo)}>
            {showInfo ? <i className="fa fa-angle-double-up"></i> : <i className="fa fa-angle-double-down"></i>}
          </div>

          <button className="btn btn-info btn-block my-4 col-md-4 col-sm-12" type="submit">
            <Translate content="forms.agency.submit" />
          </button>
        </div>
      </div>
    </form>
  );
}

export default AgencyForm;
