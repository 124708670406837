import React from "react";
import Translate from "react-translate-component";
import DatePicker from "react-datepicker";
import counterpart from "counterpart";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext } from "react-hook-form";

function CustomInput({ agencyCountry, data, showLot, folderType, handleInputChange, setDate, prefix, value, conditionValue, index }) {
  const methods = useFormContext();

  const selectOption = (code) => {
    return (
      <option value={code} key={code}>
        {counterpart.translate("forms.folder.options." + code)}{" "}
      </option>
    );
  };
  const selectedDate = (value) => {
    if (value === "" || value === null) return "";

    var date = new Date(value);
    return date;
  };
  const sendDate = (value) => {
    if (value === "" || value === null) return "";
    var date = new Date(value.setHours(15));

    return date;
  };

  return (
    <>
      {data.countries.includes(agencyCountry) &&
        (!data.condition || (data.condition && data.conditionValue === conditionValue)) &&
        (data.name !== "propertyLot" || (data.name === "propertyLot" && showLot)) && (
          <div className={`${data.klass} col-sm-12 custom-input`}>
            <div className="input">
              <Translate content={`forms.folder.labels.${data.name}${data.labelType ? `.${folderType}` : ""}`} className="label" />
              {(data.type === "text" || data.type === "number") && (
                <input type={data.type} name={data.name} ref={methods.register({ required: data.required })} onChange={handleInputChange} defaultValue={value} />
              )}
              {data.type === "select" && (
                <select name={data.name} className="select" defaultValue={value} ref={methods.register({ required: data.required })} onChange={handleInputChange}>
                  <option disabled value="">
                    {" "}
                  </option>
                  {data.choices.map((choice) => selectOption(choice.code))}
                </select>
              )}
              {data.type === "radio" && (
                <div className="radio">
                  {data.choices.map((choice) => (
                    <label className="radio-container" key={choice.code}>
                      <Translate content={`forms.folder.options.${choice.code}`} />
                      <input
                        type="radio"
                        name={data.name}
                        defaultValue={choice.code}
                        defaultChecked={choice.code === value}
                        ref={methods.register({ required: data.required })}
                        onChange={handleInputChange}
                      />
                      <span className={`checkmark ${choice.code === value ? "checked" : "unchecked"}`}></span>
                    </label>
                  ))}
                </div>
              )}
              {data.type === "date" && (
                <DatePicker
                  onChange={(date) => setDate(sendDate(date), data.name, prefix, index)}
                  dateFormat={["dd-MM-yyyy", "dd.MM.yyyy", "dd/MM/yyyy"]}
                  selected={selectedDate(value)}
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  dropdownMode="select"
                />
              )}
            </div>
          </div>
        )}
    </>
  );
}

export default CustomInput;
