import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Translate from 'react-translate-component';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

function Slider(props) {
  return(
    <Carousel showThumbs={false} autoPlay={true} transitionTime={20000} infiniteLoop={true}>
        <div>
            <img src="./images/sliders/slider2.jpg" alt='slider'/>
            <div className='slider-block'>
              <Fade left cascade>
                <Translate content='homepage.sliders.slider1.title' component='div' className='title' unsafe/>
                <Translate content='homepage.sliders.slider1.list' component='div' className='list' unsafe/>
              </Fade>
            </div>
            <Link to='/' className="legend">
              <Translate content='homepage.sliders.action_call' />
            </Link>
        </div>

        <div>
            <img src="./images/sliders/slider1.jpg" alt='slider'/>
            <div className='slider-block'>
              <Fade left cascade>
                <Translate content='homepage.sliders.slider2.title' component='div' className='title' unsafe/>
                <Translate content='homepage.sliders.slider2.list' component='div' className='list' unsafe/>
              </Fade>
            </div>
            <Link to='/' className="legend">
              <Translate content='homepage.sliders.action_call' />
            </Link>
        </div>
        <div>
            <img src="./images/sliders/slider2.jpg" alt='slider'/>
            <div className='slider-block'>
              <Fade left cascade>
                <Translate content='homepage.sliders.slider3.title' component='div' className='title' unsafe/>
                <Translate content='homepage.sliders.slider3.list' component='div' className='list' unsafe/>
              </Fade>
            </div>
            <Link to='/' className="legend">
              <Translate content='homepage.sliders.action_call' />
            </Link>
        </div>

        <div>
            <img src="./images/sliders/slider1.jpg" alt='slider'/>
            <div className='slider-block'>
              <Fade left cascade>
                <Translate content='homepage.sliders.slider4.title' component='div' className='title' unsafe/>
                <Translate content='homepage.sliders.slider4.list' component='div' className='list' unsafe/>
              </Fade>
            </div>
            <Link to='/' className="legend">
              <Translate content='homepage.sliders.action_call' />
            </Link>
        </div>
    </Carousel>
  )
}

export default Slider;
