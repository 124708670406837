import React from 'react'
import CustomFiles from './customFiles';

const CustomRentFiles = () => {
  return (
    <CustomFiles fileType='rent' />
  )
}

export default CustomRentFiles;
