export default {
  total: "Open files",
  copySellerAddress: {
    sell: "Use the address of the first seller",
    rent: "Use the address of the first buyer",
  },
  copyBuyerAddress: {
    sell: "Use the address of the first owner",
    rent: "Use the address of the first tenant",
  },
  insight: {
    count: "Number of files",
    commission: "Agency Commission",
  },
  sepa: {
    card: "Credit card",
    sepa: "Standing order (SEPA)",
    name: "Name",
    name_placeholder: "Name of bank account holder",
    email: "Email Address",
    email_placeholder: "Email Address",
    button: "Set up SEPA Direct Debit",
    promo: "Promo code",
    promo_placeholder: "Promo code ...",
    acceptance:
      "By providing your IBAN and confirming this payment, you are authorizing Rocketship Inc. and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within eight weeks starting from the date on which your account was debited.",
  },
  active: "Active",
  active_folder: "Active file",
  filePond: {
    labelIdle: 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
  },
  download_doc: "Click here to download some guideline",
  copyAdress: "Use address of property for sale",
  copySBAdress: "Copy address of owner above",
  subscription_card: {
    by_month: "/ month",
    htva: "VAT excl",
  },
  subscription_form: {
    first_title: "Please choose your payment method:",
    title: "Please enter your credit card details",
    name_placeholder: "Name of the cardholder",
    promo_placeholder: "Promo code ...",
    name_error: "Please enter your name",
    confirm: "Confirm order",
  },
  modal: {
    suppression: {
      body: "This data will be removed, do you confirm ?",
      close: "Close",
      confirmation: "Confirm",
      title: "Confirmation",
    },
    folder: {
      body:
        "If you validate the deletion, the file will be permanently deleted and all data will be lost. You will not be able to access it afterwards! Do you really want to confirm the deletion?",
      close: "Cancel",
      confirmation: "Confirm",
      title: "Warning",
    },
  },
  access: {
    folder: {
      no_agency_action: "Click please",
      no_agency_btn: "Create agency",
      no_agency_media: "Your current subscription does not allow this :-( ",
      no_agency_media_btn: "Click here to upgrade",
      no_agency_placeholder: "You need to create your agency first !",
      no_subscription_action: "Please click",
      no_subscription_btn: "Create subscription",
      no_subscription_placeholder: "You need to subscribe to generate documents !",
    },
  },
  authentication: {
    account: "My account",
    logout: "Logout",
    requests: "Requests",
    reset_title: "Reset password",
    signin: "Sign in",
    signin_title: "Sign in",
    signup: "Sign up",
    signup_title: "Create account",
    forgot: {
      link: "Forgotten your password?",
      label: "Please enter the email address associated with your account. We will then send you an email to reset your password.",
      submit: "Validate",
      placeholder: "Email",
      error: "No account is associated with this email address. Please enter the correct email address associated with your account.",
      success: "We have sent you an email to finalize the reset of your password. If you can't find it, check your junk mail folder as well.",
    },
  },
  badge: {
    feelfree: "Optinal",
    optional: "Optional",
    required: "Required",
  },
  custom_files: {
    agency_files: "Agency templates",
    choose_file: "Choose a file",
    plz_choose_file: "Please choose a file",
    file: {
      added: "Template added with success",
    },
    modal: {
      close: "Cancel",
      delete: "Delete",
      placeholder: "Are you sure to delete this file ?",
      title: "Confirmation",
    },
    types: {
      placeholder:
        "This section allows you to replace orea's standard templates with your personalised ones and (based on your subscription) to add additional templates, such as sales mandates or any other documents you need to generate regularly.",
      rent: "Rental templates",
      sell: "Sales templates",
      title: "Manage custom templates for my agency",
    },
  },
  documents: {
    rent: {
      an: "Attestation for notary",
      ara: "Risk analysis Tenant",
      c: "Lease contract",
      f: "Invoice",
      pb: "Origin of property",
      pf: "Origin of funds",
      prv: "Risk analysis Owner",
      rgpdal: "Tenant GDPR",
      rgpdp: "Owner GDPR",
    },
    sell: {
      an: "Attestation for notary",
      ara: "Risk analysis Buyer",
      c: "Sales agreement",
      f: "Invoice",
      pb: "Origin of property",
      pf: "Origin of funds",
      prv: "Risk analysis Seller",
      rgpdal: "Buyer GDPR",
      rgpdp: "Owner GDPR",
    },
    types: {
      an: "Attestation for notary",
      ara: "Risk analysis Buyer",
      c: "Sales agreement",
      f: "Invoice",
      pb: "Origin of property",
      pf: "Origin of funds",
      prv: "Risk analysis Seller",
    },
  },
  flash: {
    agency: {
      success: {
        create: "Agency informations created with success",
        update: "Agency informations updated with success",
      },
    },
    auth: {
      authTitle: "Authentication",
      connected: "Connected with success",
      disconected: "Disconnected with success",
      registred: "Registered with success",
      reset: "Password reset with success",
    },
    error: {
      general: "Error! Something goes wrong here, sorry :-(",
    },
    folder: {
      success: {
        create: "New file created with success",
        update: "Folder updated with success",
      },
    },
    invitation: {
      sended: "Invitation sent with success",
    },
    subscription: {
      success: {
        message: "New subscription",
        title: "Congratulations",
      },
    },
  },
  folder_requests: {
    table: {
      agency: "Agency",
      folder: "File",
      folderType: "Type",
      reference: "#Ref",
      reset_filter: "Reset filters",
      section: "Section",
      title: "MY FILES",
      delete: "Delete file",
      mailBox: "Messaging",
    },
  },
  folders: {
    actions: {
      rent: "New rental file",
      sell: "New sales file",
    },
    table: {
      actions: "Actions",
      createdAt: "Created",
      foldertype: "Folder",
      locality: "City",
      reference: "#Ref",
      reset_filter: "Reset filter",
      seller: "Owner",
      street: "Street",
      title: "Files in progress",
      type: "Type",
      step: "Step",
      types: {
        sell: "Sell",
        rent: "Rent",
      },
      delete: "Delete file",
      mailBox: "Messaging",
    },
  },
  forms: {
    active: "Active",
    agency: {
      labels: {
        Autorisation: "Trade authorisation",
        Bic: "BIC",
        City: "City <span>*</span>",
        CompanyName: "Company name <span>*</span>",
        DpofirstName: "First name",
        DpolastName: "Last name",
        Email: "Email",
        Iban: "IBAN",
        ManagerEmail: "Email",
        ManagerFirstName: "First name",
        ManagerLastName: "Last name",
        ManagerLocality: "Locality",
        ManagerProfession: "Title",
        Phone: "Phone number",
        PostalCode: "Zip Code <span>*</span>",
        RbfirstName: "First name",
        RblastName: "Last name",
        Rcsl: "Trade Registry number",
        StreetName: "Street name <span>*</span>",
        StreetNumber: "Number <span>*</span>",
        Tva: "VAT <span>*</span>",
        rgpd: "I agree that my data may be stored by Linc SA in accordance with <a href='/cgu' target='_blank'>RGPD</a> rules.",
      },
      placeholder:
        "These information are used to automatically fill in your agency's data in the various templates. Please fill them out carefully so that we can complete the documents with all the necessary information.",
      placeholders: {
        Autorisation: "Authorisation",
        Bic: "BIC",
        City: "City",
        CompanyName: "Official agency name",
        DpofirstName: "First name",
        DpolastName: "Last name",
        Email: "Email",
        Iban: "IBAN",
        ManagerEmail: "Email",
        ManagerFirstName: "First name",
        ManagerLastName: "Last name",
        ManagerLocality: "City of residence",
        ManagerProfession: "Title",
        Phone: "Phone number",
        PostalCode: "ZIP code (with country identification, i.e. L-xxxx)",
        RbfirstName: "First name",
        RblastName: "Last name",
        Rcsl: "Trade Registry number",
        StreetName: "Street name",
        StreetNumber: "Street number",
        Tva: "VAT number",
        Country: "Country <span>*</span>",
        Lu: "Luxembourg",
        Fr: "France",
      },
      sections: {
        agency: "Basic data",
        dpo: "DPO / GDPR Officer",
        informations: "Main information",
        manager: "Office Manager",
        rb: "AML Officer",
      },
      submit: "Validate",
      title: "Agency informations",
      insights: "Insights",
      validation: {
        email: "Invalid E-mail",
        required: "This Field is required !",
      },
    },
    folder: {
      documents: {
        new: {
          title: "Add a document",
          type: {
            label: "Type",
            sell: {
              buyer: "Buyer",
              seller: "Seller",
              transaction: "Transaction",
              bank: "Bank",
            },
            rent: {
              buyer: "Tenant",
              seller: "Owner",
              transaction: "Lease",
              bank: "Bank",
            },
          },
          select: {
            buyer: "Select one or more buyer(s)",
            seller: "Select one or more seller(s)",
          },
          file: {
            rgpd: "RGPD",
            pb: "Origin of property",
            pf: "Origin of funds",
            ar: "Risk analysis",
            cid: "ID Card",
            css: "Social security card",
            co: "Sales agreement",
            fa: "Invoice",
            at: "Notary attestation",
            ma: "Mandat",
            ac: "Deed",
            ap: "Agreement in principle",
            dp: "Bank deposit",
            ba: "Lease contract",
            bo: "Inventory done",
            cc: "Bill of specifications",
          },
          choose_document: "What type of document do you want to add?",
          add_file: "Add your files",
          summary: "Saved documents",
        },
      },
      buttons: {
        add_buyer: "Add buyer",
        add_seller: "Add seller",
        next: "Next <i class='fa fa-caret-right'></i>",
        prev: "<i class='fa fa-caret-left'></i> Previous",
        remove_buyer: "Remove buyer",
        remove_seller: "Remove seller",
        validate: "Validate",
      },
      download: {
        title: "Download document",
      },
      errors: {
        required: "This field is required",
      },
      generation: {
        title: "Generate documents in",
        upgrade_action_call: "Subscription Offers",
        upgrade_placeholder: "Upgrade your subscription to add custom files ! ",
      },
      labels: {
        propertyPlaceName: "Place name",
        propertyNature: "Nature",
        propertyOccupation: "Occupation",
        EcoBen: "Economic beneficiaries",
        actAcquisitionResale: "Acquisition for resale",
        actBankguaranteeAmount: "Amount of bank guarantee ",
        actBankguaranteeAmountLetters: "Bank guarantee (in letters)",
        actBankguaranteeMonths: "Bank guarantee - months",
        actBankguaranteeType: "Bank guarantee - type",
        actBeneficiary: "Beneficiary (bank account owner)",
        actBeneficiaryBic: "Beneficiary BIC",
        actBeneficiaryIban: "Beneficiary IBAN",
        actCharge: "Charges",
        actChargeLettres: "Charges (in lettres)",
        actDate: "Date of deeds",
        actDateAtTheLeast: "Date of deeds (latest on)",
        actDeadlineBank: "Deadline for bank guarantee",
        actEffectiveDate: "Date of entry into possession",
        actEmail: "Email of clerk of notary",
        actLocality: "City of the notary",
        actNotaryLastName: "Family name of notary",
        actRentCommission: "Commission (number of months)",
        actSellCommission: "Commission in %",
        refAedmandat: "AED mandate reference",
        refAedcompromis: "AED sales agreement reference",
        actEstimetedPrice: "ESTIMATED PRICE (PROSPECTING FILE)",
        actMandatPrice: "MANDATE PRICE",
        actSellingPrice: {
          rent: "Monthly rent",
          sell: "Sales price",
        },
        actSellingPriceLetters: {
          rent: "Monthly rent (in letters)",
          sell: "Price (in letters)",
        },
        sellerType: {
          sell: "Please choose what type of seller you want to add",
          rent: "Please choose what type of lessor you want to add",
        },
        buyerType: {
          sell: "Please choose what type of buyer you want to add",
          rent: "Please choose what type of tenant you want to add",
        },
        addEcoBen: "Add Beneficiary",
        benefFirstName: "First name",
        benefLastName: "Last name",
        birthLocality: "Nationaly",
        buyerAddressCountry: "Country",
        buyerAddressLocality: "City",
        buyerAddressNumber: "Number",
        buyerAddressStreet: "Street name",
        buyerAddressZipCode: "Zip code",
        buyerBirthDate: "Date of birth",
        buyerCivilStatus: "Civil status",
        buyerCompanyForm: "Form of company",
        buyerCompanyName: "Name of company",
        buyerEMail: "Email",
        buyerEcoBen: "Economic beneficiary",
        buyerFirstName: "First name",
        buyerIdNum: "Identity Card Number or Passport",
        buyerLabel: "",
        buyerLastName: "Name",
        buyerLegalRep: "Legal representative",
        buyerMatSecSoc: "Social security number",
        buyerNationality: "Nationality",
        buyerNumRc: "Trade Registry number",
        buyerNumTva: "VAT number",
        buyerPhone: "Phone",
        buyerProf: "Profession",
        buyerRepAddressCountry: "Country",
        buyerRepAddressLocality: "City",
        buyerRepAddressNumber: "Number",
        buyerRepAddressStreet: "Street name",
        buyerRepAddressZipCode: "Zip code",
        buyerRepEmail: "Email",
        city: "City",
        civilStatus: "Civil status",
        country: "Country",
        numId: "ID number",
        profession: "Profession",
        propertyAddressCountry: "Country",
        propertyAddressFrom: "From",
        propertyAddressLocality: "City",
        propertyAddressMunicipal: "Commune",
        propertyAddressNumber: "Number",
        propertyAddressSection: "Section",
        propertyAddressStreet: "Street",
        propertyAddressZipCode: "Zip code",
        propertyAreTer: "Plot size (a)",
        propertyBailEmp: "Emphyteotic lease",
        propertyBasement: "Basement (sqm)",
        propertyConstructionYear: "Construction year",
        propertyCpe: "Energy passport",
        propertyEmail: "Email",
        propertyEmpExt: "Nb exterior car parks",
        propertyEmpInt: "Nb interior car parks",
        propertyHabSousBail: "Property under lease",
        propertyLot: "Allotment(s)",
        propertyNrbBedrom: "Nb of rooms",
        propertyNumCad: "Cadastral number",
        propertyServ: "Servitude",
        propertySitHyp: "Mortgage status",
        propertySurHab: "Habitable surface (sqm)",
        propertyTerBal: "Terrace, balcony (sqm)",
        propertyType: "Type",
        secSoc: "Social Security",
        sellerLabel: "",
        sellerAddressCountry: "Country",
        sellerAddressLocality: "City",
        sellerAddressNumber: "Number",
        sellerAddressStreet: "Street",
        sellerAddressZipCode: "Zip Code",
        sellerBirthDate: "Birthdate (DD-MM-YYYY)",
        sellerCivilStatus: "Civil status",
        sellerCompanyForm: "Form of company",
        sellerCompanyName: "Name of company",
        sellerEMail: "Email",
        sellerFirstName: "First name",
        sellerIdNum: "Identity Card Number or Passport",
        sellerLastName: "Name",
        sellerLegalRep: "Legal representative",
        sellerMatSecSoc: "Social security number",
        sellerNationality: "Nationality",
        sellerNumRc: "Trade Registry number",
        sellerNumTva: "VAT number",
        sellerPhone: "Phone",
        sellerProf: "Profession",
        sellerRepAddressCountry: "Country",
        sellerRepAddressLocality: "City",
        sellerRepAddressNumber: "Number",
        sellerRepAddressStreet: "Street",
        sellerRepAddressZipCode: "Zip Code",
        sellerRepEmail: "Email",
        streetName: "Street name",
        streetNumber: "Street number",
        zipCode: "Zip Code",
      },
      modal: {
        continue_button: "No, complete form first",
        generate_button: "Yes, download document",
        placeholder: "Incomplete form, are you sure to generate document ?",
        title: "Confirmation",
      },
      options: {
        status: {
          pr: "Prospect",
          ma: "Mandate",
          co: "Sales agreement",
          bo: "Bank OK",
          af: "Notary planned",
          ac: "Deeds OK",
          pe: "Lost",
          rent: {
            pr: "Prospect",
            ma: "Mandate",
            co: "Signed lease",
            bo: "Inventory done",
            af: "Billed",
            ac: "Paid",
            pe: "Lost",
          },
          sell: {
            pr: "Prospect",
            ma: "Mandate",
            co: "Sales agreement",
            bo: "Bank OK",
            af: "Notary planned",
            ac: "Deeds OK",
            pe: "Lost",
          },
        },
        CompanySeller: "Company",
        apartment: "Flat",
        commerce: "Commerce",
        corner_house: "Corner house",
        detached_house: "Single house",
        divorced: "Divorced",
        duplex: "Duplex",
        garage: "Garage",
        ground: "Plot",
        land: "Plot",
        loft: "Loft",
        married: "Married",
        non: "No",
        office: "Office",
        penthouse: "Penthouse",
        privateSeller: "Person",
        row_house: "Townhouse",
        semi_detached_house: "Semi-detached house",
        single: "Bachelor",
        studio: "Studio",
        triplex: "Triplex",
        widowed: "Widowed",
        yes: "Yes",
        mr: "Mr.",
        mrs: "Mrs",
      },
      submit: "Submit",
      title: "Customer files",
      requirements: "Required for",
      invite: {
        button_action: "Invite",
        email_placeholder: "Fill in 1 destination e-mail at a time",
        placeholder:
          "Here, you can invite clients and the notary to access their information tab, so they can fill in their respective data. Every person you invite will only have access to their respective tab, i.e. the sellers will only have access to the complete Sellers' tab. Use this great functionality to reduce your workload and receive the required information as soon as possible. Please invite users one by one and select each time the single tab they should get access to.",
        title: "Invite clients or the notary to add data",
        give_access: "Select the tab you want to give access to:",
        email_label: "E-mail of the person you want to invite",
      },
    },
    reset: {
      placeholders: {
        password: "Reset Password",
        password_confirmation: "Password confirmation",
      },
      submit: "Reset",
    },
    signin: {
      errors: {
        login: "Please verify your e-mail / password",
      },
      placeholders: {
        email: "Email",
        password: "Password",
      },
      submit: "Log in",
      validations: {
        email: "E-mail Invalid",
        email_req: "E-mail is required",
        password: "Password is required",
      },
    },
    signup: {
      errors: {
        register: "Please verify your e-mail / password",
      },
      placeholders: {
        email: "Email",
        password: "Password",
        password_confirmation: "Password confirmation",
      },
      submit: "Create account",
      labels: {
        cgu: "J'ai lu et j'accepte les <a href='/cgu' target='_blank'>CGU</a>",
        cgv: "I have read and accept the <a href='/cgv' target='_blank'>General Terms and Conditions of Sale and Use.</a>.",
      },
      validations: {
        confirmation: "Incorrect confirmation",
        email: "E-mail Invalid",
        email_req: "E-mail is required",
        password: "The password must contain at least 1 lowercase, 1 uppercase, 1 numeric character, 1 special character and be at least 6 characters long.",
        password_confirmation_req: "Password confirmation is required",
        password_req: "Password is required",
        cgu: "Cgu is required",
        cgv: "Cgv is required",
      },
    },
  },
  homepage: {
    advantages: {
      placeholder: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      rent: "Location",
      sell: "Vente",
      title: "<span>Documents en</span> <span>2 clicks</span>",
    },
    comparison: {
      action_call: "Découvrez nos formules àpd x EUR/mois",
      before: "Aujourd’hui",
      negatif: {
        first: "Vous perdez du temps inutile à saisir de manière répétitive les mêmes informations…",
        second: "dans tous les documents… ",
        sub_first: "(noms, adresses, professions, nationalité, carte d’identité, matricule des vendeurs et des acheteurs ; prix ; adresse et détails du bien ; notaire etc)",
        sub_second:
          "(déclaration de provenance des fonds acheteur,  déclaration de provenance des fonds vendeur, fiche d’analyse de risque, compromis, attestation de sincérité, facture,…)",
        third: "et à contrôler si vous avez bien rempli toutes les obligations légales pour éviter des amendes.",
      },
      now: "Demain, avec ImmoAML",
      placeholder: "Le temps est ce que vous avez de plus précieux. Ne le gaspillez pas !",
      positif: {
        first: "Saisissez une seule fois toutes les données",
        more: "et soyez sûr d’être irréprochable en cas de contrôle.",
        second: "Générez tous les documents en 2 clicks, au bon moment",
        third: "Accédez facilement à toutes les informations actuelles et historiques",
      },
    },
    concept: {
      body:
        "<p>Conçu en étroite collaboration avec des professionnels de l’immobilier.</p><p>IMMOAML gère tous vos documents administratifs en 2 clicks, en 3 langues, sur base de modèles juridiquement validés.</p>",
      title: "<span>Title</span> <span>Concept</span>",
    },
    contact: {
      information: "information",
      title: "Contact",
    },
    intro: {
      body:
        "<p>Le travail administratif est le volet le plus agaçant pour tout bon agent immobilier.</p><p>Malheureusement, les contraintes légales deviennent de plus en plus envahissantes et imposent du travail rébarbatif et inutile</p>",
      title: "<span>Title</span> <span>Intro</span>",
    },
    sliders: {
      action_call: "Facilitez-vous la vie !",
      slider1: {
        list:
          "<ul><li>de remplir jusqu’à 7 fois les mêmes données ?</li><li>de perdre inutilement du temps précieux ?</li><li>de vérifier constamment que vous respectez toutes les formalités ?</li></ul>",
        title: "<span>Vous n’en avez </span><span> pas aussi assez…</span>",
      },
      slider2: {
        list:
          "<ul><li>Economisez des heures de travail administratif</li><li>Ne risquez plus d’amende en cas de contrôle</li><li>Garantissez à vos clients des documents de qualité en 3 langues</li></ul>",
        title: "<span>A partir de </span><span> seulement X eur/mois</span>",
      },
      slider3: {
        list:
          "<ul><li>Stop au travail administratif rébarbatif</li><li>Réduisez considérablement votre charge de travail</li><li>Gagnez en productivité et en temps libre</li></ul>",
        title: "<span>Arrêtez de gaspiller </span><span> votre temps !</span>",
      },
      slider4: {
        list:
          "<ul><li>Générez tous les documents administratifs et légaux en 2 clicks</li><li>Assurez une qualité exemplaire à vos clients</li><li>Soyez serein en cas de contrôle</li></ul>",
        title: "<span>Soyez au Top </span><span> de la qualité administrative, sans efforts !</span>",
      },
    },
    subscriptions: {
      placeholder:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
      title: "<span>Nos</span> <span>abonnements</span> ",
    },
    testimonials: {
      testimonials_1: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Bennani Ayoub",
        profession: "Software developper",
      },
      testimonials_2: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Marc Neuen",
        profession: "CEO & Founder, Creativeracer",
      },
      testimonials_3: {
        body:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti iusto voluptatum voluptas soluta, rem! Deserunt sint nam consequatur laborum, aperiam quod, quo impedit ea. Veniam, doloribus, molestias? Architecto, maxime et?",
        name: "Julien Gras",
        profession: "CTO and dakchi",
      },
      title: "<span>What clients</span> <span>say</span>",
    },
    usp: {
      action: "Souscrivez à la formule de votre choix.",
      placeholder: "Evitez les amendes en cas de contrôle. Assurez-vous que vos documents soient irréprochables. ",
      usp_1: {
        body: "Saisissez une seule fois chaque info pour alimenter tous les documents requis.",
        title: "Usp title 1",
      },
      usp_2: {
        body: "Tous les documents sont validés juridiquement et disponibles en 3 langues.",
        title: "Usp title 2",
      },
      usp_3: {
        body: "Adaptez facilement les documents selon les besoins spécifiques du dossier et utilisez vos propres modèles personnalisés.",
        title: "Usp title 3",
      },
      usp_4: {
        body: "Accédez à tout moment à toutes les données et à tous les documents…  en cours et  historiques.",
        title: "Usp title 4",
      },
      usp_5: {
        body: "Toutes les données sensibles sont encryptées. Juste vous y avez accès.",
        title: "Usp title 5",
      },
    },
  },
  languages: {
    de: "Deutsch",
    en: "English",
    fr: "Français",
  },
  mailbox: {
    new: {
      errors: {
        body: "Please write a message",
        participants: "Please select addressee(s)",
        subject: "Please enter a subject",
      },
      participants: "Participant(s)",
      placeholder: "Write a new message from",
      receivers: {
        agency: "Agence",
        buyers: "Buyers",
        notary: "Notary",
        owner: "Owners",
        sellers: "Sellers",
      },
      subject: "Title",
    },
  },
  navbar: {
    account: {
      custom_files: "Templates",
      folders: "My Files",
    },
    subscription: "Subscription",
  },
  navigation: {
    tabs: {
      property: "Property info",
      rent: {
        act: "Lease info",
        buyer: "Tenant info",
        property: "Property info",
        seller: "Lessor info",
        documents: "Documents",
      },
      sell: {
        act: "Deeds info",
        buyer: "Buyer info",
        property: "Property info",
        seller: "Seller info",
        documents: "Documents",
      },
    },
  },
  payment: {
    detail: {
      delay: "Delay : ",
      from: "Start :",
      periodPrice: "Total : ",
      price: "Price billed for period : ",
      to: "To : ",
      by_month: "/ month",
      months: "months",
    },
  },
  redirect: {
    to_signin: "Log in",
    to_signin_txt: "Already have an account ?",
    to_signup: "Sign up",
    to_signup_txt: "Don't have an account yet?",
  },
  rent: {
    title: "New rental folder",
  },
  rgpd: "GDPR",
  sell: {
    title: "New sales file",
  },
  subscriptions: {
    end_subscription: "Stop subscription",
    reactivate: "Relaunch the subscription",
    solde: false,
    modal: {
      close: "Close",
      end: "I confirm",
      placeholder: "Are you really sure to end your subscription ?",
      title: "Confirmation",
    },
    names: {
      r_2y: "Basic - Rental 24 months",
      r_6: "Basic - Rental 6 months ",
      r_12: "Basic - Rental 12 months",
      s_2y: "Basic - Sales 24 months",
      srm_2y: "Premium - 24 months",
      srm_6: "Premium - 6 months",
      srm_12: "Premium - 12 months",
      sr_2y: "Standard- 24 months",
      sr_6: "Standard- 6 months",
      sr_12: "Standard- 12 months",
      s_6: "Basic - 6 months",
      s_12: "Basic - 12 months",
    },
    new: "Choose your subscription",
    orders: {
      activeStatus: {
        false: "Expired",
        true: "Active",
      },
      header: {
        OrderEnd: "End date",
        OrderStart: "Start date",
        Orderstatus: "Status",
        orderTitle: "Title",
      },
    },
    periodPrice: "Period price",
    reduction: {
      twoyears: "24 months discount",
      sixMonths: "6 months discount",
      twelveMonths: "12 months discount",
    },
    rent: {
      option1: {
        title: "Only SALES files",
        valid: false,
      },
      option2: {
        title: "Only RENTAL files",
        valid: true,
      },
      option3: {
        title: "Custom files",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "r_2y",
          formula: "24 months ",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "r_6",
          formula: "6 months",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "r_12",
          formula: "12 months",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - Rentals only",
    },
    select: "Select",
    sell: {
      option1: {
        title: "Only SALES files",
        valid: true,
      },
      option2: {
        title: "Only RENTAL files",
        valid: false,
      },
      option3: {
        title: "Custom files",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "s_2y",
          formula: "24 months ",
          delay: 24,
          periodPrice: 696,
          price: 29,
        },
        sixMonths: {
          code: "s_6",
          formula: "6 months",
          delay: 6,
          periodPrice: 294,
          price: 49,
        },
        twelveMonths: {
          code: "s_12",
          formula: "12 months",
          delay: 12,
          periodPrice: 468,
          price: 39,
        },
      },
      title: "BASIC - Sales only",
    },
    sell_rent: {
      option1: {
        title: "Sell folders",
        valid: true,
      },
      option2: {
        title: "Rental folders",
        valid: true,
      },
      option3: {
        title: "Archives",
        valid: true,
      },
      option4: {
        title: "-",
        valid: false,
      },
      option5: {
        title: "-",
        valid: false,
      },
      option6: {
        title: "-",
        valid: false,
      },
      option7: {
        title: "-",
        valid: false,
      },
      option8: {
        title: "-",
        valid: false,
      },
      option9: {
        title: "-",
        valid: false,
      },
      periods: {
        twoyears: {
          code: "sr_2y",
          formula: "24 months",
          delay: 24,
          periodPrice: 1176,
          price: 49,
        },
        sixMonths: {
          code: "sr_6",
          formula: "6 months",
          delay: 6,
          periodPrice: 474,
          price: 79,
        },
        twelveMonths: {
          code: "sr_12",
          formula: "12 months",
          delay: 12,
          periodPrice: 828,
          price: 69,
        },
      },
      title: "STANDARD",
    },
    sell_rent_media: {
      option1: {
        title: "Sell folders",
        valid: true,
      },
      option2: {
        title: "Rental folders",
        valid: true,
      },
      option3: {
        title: "Archives",
        valid: true,
      },
      option4: {
        title: "Personalisation of provided templates",
        valid: true,
      },
      option5: {
        title: "Upload of new personalised templates",
        valid: true,
      },
      option6: {
        title: "Entry of information by customers",
        valid: true,
      },
      option7: {
        title: "Upload and storage of documents (May)",
        valid: true,
      },
      option8: {
        title: "Messaging service",
        valid: true,
      },
      option9: {
        title: "Electronic signatures (June)",
        valid: true,
      },
      periods: {
        twoyears: {
          code: "srm_2y",
          formula: "24 months",
          delay: 24,
          periodPrice: 1656,
          price: 69,
        },
        sixMonths: {
          code: "srm_6",
          formula: "6 months",
          delay: 6,
          periodPrice: 594,
          price: 99,
        },
        twelveMonths: {
          code: "srm_12",
          formula: "12 months",
          delay: 12,
          periodPrice: 1068,
          price: 89,
        },
      },
      title: "PREMIUM",
    },
    title: "Subscription",
    upgrade: "Upgrade subscription",
  },
  title: "title en",
};
