import React, { useState } from "react";
import Translate from "react-translate-component";
import Chart from "./chart";

function ChartContainer() {
  const [year, setYear] = useState(new Date().getFullYear());
  const activeYear = (klassYear) => {
    return year === klassYear ? "blue-txt" : "clickable";
  };

  return (
    <div className="row">
      <div className="col-12">
        <span className={activeYear(2020)} onClick={() => setYear(2020)}>
          2020
        </span>
        <span className="pl-1 pr-1">|</span>
        <span className={activeYear(2021)} onClick={() => setYear(2021)}>
          2021
        </span>
      </div>
      <div className="col-12">
        <Translate content="folders.table.types.sell" component="h5" />
      </div>
      <div className="col-md-6 col-sm-12">
        <Chart chartType="count" folderType="sell" year={year} />
      </div>
      <div className="col-md-6 col-sm-12">
        <Chart chartType="commission" folderType="sell" year={year} />
      </div>

      <div className="col-12">
        <Translate content="folders.table.types.rent" component="h5" />
      </div>
      <div className="col-md-6 col-sm-12">
        <Chart chartType="count" folderType="rent" year={year} />
      </div>
      <div className="col-md-6 col-sm-12">
        <Chart chartType="commission" folderType="rent" year={year} />
      </div>
    </div>
  );
}

export default ChartContainer;
