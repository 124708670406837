import React, { Component } from "react";
import Translate from "react-translate-component";
import { withRouter } from "react-router-dom";
import TableFilter from "react-table-filter";
import {} from "react-table-filter/lib/styles.css";
import history from "./history";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import counterpart from "counterpart";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: this.props.folders,
    };
    this._filterUpdated = this._filterUpdated.bind(this);
  }
  _filterUpdated(newData, filterConfiguration) {
    this.setState({
      folders: newData,
    });
  }
  componentDidMount() {
    this.setState({
      folders: this.props.folders,
      showDelModal: false,
      idToRemove: "",
    });
    this.tableFilterNode && this.tableFilterNode._filterMulipleRows();
  }

  resetFilter = () => {
    this.tableFilterNode.reset(this.props.folders, true);
    this.setState({
      folders: this.props.folders,
    });
  };

  goToEdit = (item) => {
    if (!item.active && this.props.currentOrder === null) return;
    if (!item.active && (!this.props.currentOrder.sell || !this.props.currentOrder.rent)) return;
    history.push(`/account/${item.type}/edit/${item.id}#tab1`);
    window.location.reload();
  };

  render() {
    const formatedDate = (date) => {
      if (date === "" || date === null) return "";
      var d = new Date(date);
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    };
    const folders = this.state.folders;
    const elementsHtml = folders.map((item, index) => {
      return (
        <tr key={"row_" + index} className={item.active ? "" : "disable"}>
          <td className="cell" onClick={() => this.goToEdit(item)}>
            {item.id}
          </td>
          <td className="cell" onClick={() => this.goToEdit(item)}>
            {item.active ? <Translate content="forms.folder.options.yes" /> : <Translate content="forms.folder.options.non" />}
          </td>
          <td className="cell" onClick={() => this.goToEdit(item)}>
            {formatedDate(item.createdAt)}
          </td>
          <td className={`cell ${item.type}`} onClick={() => this.goToEdit(item)}>
            <Translate content={`folders.table.types.${item.type}`} />
          </td>
          <td className="cell sell" onClick={() => this.goToEdit(item)}>
            <Translate content={`forms.folder.options.status.${item.type}.${item.currentAdvancement}`} />
          </td>
          <td className="cell" onClick={() => this.goToEdit(item)}>
            {item.property.propertyAddressLocality}
          </td>
          <td className="cell" onClick={() => this.goToEdit(item)}>
            {item.property.propertyAddressStreet}
          </td>
          <td className="cell sbs" onClick={() => this.goToEdit(item)}>
            {item.sellers && item.sellers.length > 0 && (
              <div className="row">
                <div className="col-4">
                  {item.sellers[0].sellerType === "privateSeller" && <>{item.sellers[0].sellerLastName}</>}
                  {item.sellers[0].sellerType === "CompanySeller" && <>{item.sellers[0].sellerCompanyName}</>}
                </div>
              </div>
            )}
          </td>

          <td className="td-icons" data-tip={counterpart.translate("folders.table.delete")}>
            <div className="pl-1 " onClick={() => this.setState({ showDelModal: true, idToRemove: item.id })}>
              <i className="fa fa-trash mr-2"></i>
            </div>
            <ReactTooltip />
            {/* <div className="pl-1 white-hover" onClick={() => this.props.history.push(`/mailbox/${item.id}`)}>
              <i className="fa fa-comments"></i>
              {<span className={`unreaden-conv ${item.unreaden}`}></span>}
              <Translate content="folders.table.mailBox" className="blue-txt" />
            </div> */}
          </td>
        </tr>
      );
    });
    return (
      <div className="examples">
        <div className="pull-right reset-filter" onClick={() => this.resetFilter()}>
          <Translate content="folders.table.reset_filter" />
        </div>
        <div className="basic">
          <table className="basic-table">
            <thead>
              <TableFilter
                rows={folders}
                onFilterUpdate={this._filterUpdated}
                initialFilters={{ active: ["Non"] }}
                ref={(node) => {
                  this.tableFilterNode = node;
                }}
              >
                <th key="id" filterkey="id" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folders.table.reference" />
                </th>
                <th key="active" filterkey="active" className="tcell" casesensitive={"false"} showsearch={"true"} itemDisplayValueFunc={(itemValue) => (itemValue ? "oui" : "Non")}>
                  <Translate content="active" />
                </th>
                <th key="createdAt" filterkey="createdAt" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folders.table.createdAt" />
                </th>
                <th key="type" filterkey="type" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folders.table.type" />
                </th>
                <th
                  key="currentAdvancement"
                  filterkey="currentAdvancement"
                  className="tcell"
                  casesensitive={"false"}
                  showsearch={"false"}
                  itemDisplayValueFunc={(itemValue) => counterpart.translate("forms.folder.options.status." + itemValue)}
                >
                  <Translate content="folders.table.step" />
                </th>
                <th key="property.propertyAddressLocality" filterkey="property.propertyAddressLocality" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folders.table.locality" />
                </th>

                <th key="property.propertyAddressStreet" filterkey="property.propertyAddressStreet" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folders.table.street" />
                </th>
                <th
                  key="sellers"
                  filterkey="sellers"
                  itemDisplayValueFunc={(itemValue) =>
                    itemValue.map(
                      (item, i) => (item.sellerType === "privateSeller" && item.sellerLastName + " ") || (item.sellerType === "CompanySeller" && item.sellerCompanyName)
                    )
                  }
                  className="tcell sbs"
                  casesensitive={"false"}
                  showsearch={"true"}
                  alignleft={"true"}
                >
                  <Translate content="folders.table.seller" />
                </th>
                <th key="actions"></th>
              </TableFilter>
            </thead>
            <tbody>{elementsHtml}</tbody>
          </table>
        </div>
        <Modal show={this.state.showDelModal} onHide={() => this.setState({ showDelModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content="modal.suppression.folder.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-2">
                <Translate content="modal.suppression.folder.body" component="p" />
              </div>

              <div className="col-6 mb-2">
                <button className="direction float-right" onClick={() => this.props.removeFolder(this.state.idToRemove)}>
                  <Translate content="modal.suppression.folder.confirmation" />
                </button>
              </div>
              <div className="col-6 mb-2">
                <button className="direction float-right" onClick={() => this.setState({ showDelModal: false })}>
                  <Translate content="modal.suppression.folder.close" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Table);
