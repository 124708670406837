import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionACreator from "../../../store/actions/folderRequestActions";
import Translate from "react-translate-component";

import RentEdit from "../../user_panel/request/folder/rentEdit";
import SellEdit from "../../user_panel/request/folder/sellEdit";

class FolderRequests extends Component {
  componentDidMount() {
    this.props.getFolderRequest(this.props.match.params.id);
  }
  updateFolder = (values) => {
    this.props.updateFolderRequest({ ...this.props.folder_request, folder: values });
  };
  render() {
    return (
      <div className="Agency">
        <div className="container agency-folders">
          <div className="custom-box">
            <div className="row">
              <div className="col-12">
                {this.props.folder_request && (
                  <Translate
                    content={`navigation.tabs.${this.props.folder_request.folder.type}.${this.props.folder_request.section}`}
                    className="fr-title blue-bg px-2 py-1 white"
                    component="div"
                  />
                )}
              </div>
              <div className="col-12">
                {this.props.folder_request && (
                  <>
                    {this.props.folder_request.folder.type === "rent" && (
                      <RentEdit agencyCountry={this.props.agencyCountry} folder_request={this.props.folder_request} formKey={1} updateFolder={this.updateFolder} />
                    )}
                    {this.props.folder_request.folder.type === "sell" && (
                      <SellEdit agencyCountry={this.props.agencyCountry} folder_request={this.props.folder_request} formKey={2} updateFolder={this.updateFolder} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  folder_request: state.folderRequestReducer.folder_request,
  agencyCountry: state.folderRequestReducer.agencyCountry,
});

const mapDispatchToProps = (dispatch) => ({
  getFolderRequest: (id) => dispatch(actionACreator.getFolderRequest(id)),
  updateFolderRequest: (values) => dispatch(actionACreator.updateFolderRequest(values)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FolderRequests);
