import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';

import * as serviceWorker from './serviceWorker';

import App from './App';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';

import { Provider } from 'react-redux';

import authReducer from './store/reducers/authReducer';
import agencyReducer from './store/reducers/agencyReducer';
import loadReducer from './store/reducers/loadReducer';
import accountReducer from './store/reducers/accountReducer';
import folderRequestReducer from './store/reducers/folderRequestReducer';
import conversationReducer from './store/reducers/conversationReducer';
import thunk from "redux-thunk";

import './store/interceptors/interceptor.request'
import './store/interceptors/interceptor.response'

const root_reducer = combineReducers({
  authReducer: authReducer,
  agencyReducer: agencyReducer,
  loadReducer: loadReducer,
  accountReducer: accountReducer,
  folderRequestReducer: folderRequestReducer,
  conversationReducer: conversationReducer,
  notifications
})


const store = createStore(root_reducer, applyMiddleware(thunk));
const root = document.getElementById('root');

ReactDOM.render(<Provider store={store}><App /></Provider>, root);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
