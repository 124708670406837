import React from "react";
import Translate from "react-translate-component";
import OrderLine from "./orderLine";

function AgencyOrders(props) {
  const orders = (props.orders || []).map((order) => (
    <OrderLine key={order.id} order={order} reactivate={props.reactivate} endSubscription={props.endSubscription} loadingUnsubscribe={props.loadingUnsubscribe} />
  ));

  return (
    <table className="table table-bordered orders">
      <thead>
        <tr>
          <th scope="col">
            <Translate content="subscriptions.orders.header.orderTitle" component="b" />
          </th>
          <th scope="col">
            <Translate content="subscriptions.orders.header.OrderStart" component="b" />
          </th>
          <th scope="col">
            <Translate content="subscriptions.orders.header.Orderstatus" component="b" />
          </th>
          <th scope="col">
            <Translate content="subscriptions.orders.header.OrderEnd" component="b" />
          </th>
        </tr>
      </thead>
      <tbody>{orders}</tbody>
    </table>
  );
}

export default AgencyOrders;
