const axios = require('axios');

export const folderRequestService = { getFolderRequests, getFolderRequest, updateFolderRequest};

function getFolderRequests() {
  return axios.get(`/folderRequests/index`);
}

function getFolderRequest(id) {
  return axios.get(`/folderRequests/show/${id}`);
}

function updateFolderRequest(values) {
  console.log(values);
  return axios.put(`/folderRequests/update`, values);
}
