const axios = require("axios");

export const authService = { login, resetPassword, register, resetForgetenPassword };

function login(values) {
  const requestBody = {
    email: values.email,
    password: values.password,
  };

  return axios.post(`/users/authenticate`, requestBody);
}

function register(values) {
  const requestBody = {
    password: values.password,
    email: values.email,
    role: values.role,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return axios.post(`/users/register`, requestBody, config);
}

function resetPassword(values) {
  const requestBody = {
    password: values.password,
    reset_token: values.reset_token,
  };

  return axios.post(`/users/reset`, requestBody);
}
function resetForgetenPassword(values) {
  return axios.post(`/users/forgot_request`, values);
}
