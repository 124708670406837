import React, {useState} from "react";
import SubscriptionsCard from './card/subscriptionCard';
import SubscriptionsSpecialCard from './card/subscriptionsSpecialCard';

function SubscriptionsList({changeActiveSubscription, activeSubscription, subscriptions}) {

  const [type, setType] = useState('sell');
  const switchCard = (tp) => {
    setType(tp);
  }

  return(
    <div className='price-list'>
        <div className="row">

          <div className={`special-card col-sm-6 col-md-4 col-xs-12 col-${type} ${type === 'rent' ? 'is-flipped' : ''}`}>
            <SubscriptionsSpecialCard switchCard={() => switchCard('rent')} type='sell' subscription={subscriptions.sell} changeActiveSubscription={changeActiveSubscription} activeSubscription={activeSubscription}/>
            <SubscriptionsSpecialCard switchCard={() => switchCard('sell')} type='rent' subscription={subscriptions.rent} changeActiveSubscription={changeActiveSubscription} activeSubscription={activeSubscription}/>
          </div>

          <SubscriptionsCard type='sell_rent' subscription={subscriptions.sell_rent} changeActiveSubscription={changeActiveSubscription} activeSubscription={activeSubscription}/>
          <SubscriptionsCard type='sell_rent_media' subscription={subscriptions.sell_rent_media} changeActiveSubscription={changeActiveSubscription} activeSubscription={activeSubscription}/>

      </div>
    </div>
  )
}

export default SubscriptionsList;
