import React from "react";
import Messages from "./components/messages";
import NewConversation from "./components/newConversation";
import Conversations from "./components/conversations";
import Participants from "./components/participants";

function MailBoxContainer({ createConversation, conversations, conversation, getConversation }) {
  return (
    <div className="mails">
      <div className="message-list scrollable">
        <NewConversation createConversation={createConversation} />
        <Conversations conversations={conversations} conversation={conversation} getConversation={getConversation} />
        <Participants />
      </div>

      <Messages conversation={conversation} getConversation={getConversation} />
    </div>
  );
}

export default MailBoxContainer;
