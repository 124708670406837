import React, { Component } from "react";
import Translate from "react-translate-component";
import { withRouter } from "react-router-dom";
import TableFilter from "react-table-filter";
import {} from "react-table-filter/lib/styles.css";
import history from "./history";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_requests: this.props.folder_requests,
    };
    this._filterUpdated = this._filterUpdated.bind(this);
  }
  _filterUpdated(newData, filtersObject) {
    this.setState({
      folder_requests: newData,
    });
  }
  componentDidMount() {
    this.setState({
      folder_requests: this.props.folder_requests,
    });
  }

  resetFilter = () => {
    this.tableFilterNode.reset(this.props.folder_requests, true);
    this.setState({
      folder_requests: this.props.folder_requests,
    });
  };
  goToEdit = (item) => {
    history.push(`/user_panel/folder_request/${item.id}/edit`);
    window.location.reload();
  };
  render() {
    const folder_requests = this.state.folder_requests;
    const elementsHtml = folder_requests.map((item, index) => {
      return (
        <tr key={"row_" + index}>
          <td onClick={() => this.goToEdit(item)} className="cell">
            {item.id}
          </td>
          <td onClick={() => this.goToEdit(item)} className={`cell ${item.folderType}`}>
            <span>{item.folderType}</span>
          </td>
          <td onClick={() => this.goToEdit(item)} className="cell">
            {item.folderId}
          </td>
          <td onClick={() => this.goToEdit(item)} className="cell">
            {item.section}
          </td>
          <td onClick={() => this.goToEdit(item)} className="cell">
            {item.agencyName}
          </td>
          {/* <td className="td-icons">
            <div className="pl-1 white-hover" onClick={() => this.props.history.push(`/mailbox/${item.folderId}`)}>
              <i className="fa fa-comments"></i>
              {<span className={`unreaden-conv ${item.unreaden}`}></span>}
              <Translate content="folders.table.mailBox" className="blue-txt" />
            </div>
          </td> */}
        </tr>
      );
    });
    return (
      <div className="examples">
        <div className="pull-right reset-filter" onClick={() => this.resetFilter()}>
          <Translate content="folder_requests.table.reset_filter" />
        </div>
        <div className="basic">
          <table className="basic-table">
            <thead>
              <TableFilter
                rows={folder_requests}
                onFilterUpdate={this._filterUpdated}
                ref={(node) => {
                  this.tableFilterNode = node;
                }}
              >
                <th key="id" filterkey="id" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folder_requests.table.reference" />
                </th>
                <th key="folderType" filterkey="folderType" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folder_requests.table.folderType" />
                </th>
                <th key="folderId" filterkey="folderId" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folder_requests.table.folder" />
                </th>
                <th key="section" filterkey="section" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folder_requests.table.section" />
                </th>

                <th key="agencyName" filterkey="agencyName" className="tcell" casesensitive={"false"} showsearch={"true"}>
                  <Translate content="folder_requests.table.agency" />
                </th>
                {/* <th key="actions">
                  <Translate content="folders.table.actions" />
                </th> */}
              </TableFilter>
            </thead>
            <tbody>{elementsHtml}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(Table);
