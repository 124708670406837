import React from "react";
import Form from "./shared/form";
import { emptySellerData, emptyBuyerData } from "../../../account/shared/data/emptyData";
import { sellerData, buyerData, actData } from "../../../account/shared/data/sellData";

class EditSellContainer extends React.Component {
  submitFolder = (values) => {
    this.props.updateFolder(values);
  };
  render() {
    const { folder, section } = this.props.folder_request;
    const user = localStorage.getItem("user");

    return (
      <div key={this.props.formKey}>
        <Form
          submitFolder={this.submitFolder}
          key={this.props.formKey}
          initValues={folder}
          type="sell"
          editSection={section}
          sellerData={sellerData}
          buyerData={buyerData}
          actData={actData}
          emptySellerData={{ ...emptySellerData, sellerEMail: user.email, sellerRepEmail: user.email }}
          emptyBuyerData={{ ...emptyBuyerData, buyerEMail: user.email, buyerRepEmail: user.email }}
          agencyCountry={this.props.agencyCountry}
        />
      </div>
    );
  }
}

export default EditSellContainer;
