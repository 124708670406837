import React from 'react';
import Translate from 'react-translate-component';
import Fade from 'react-reveal/Fade';


function Intro(props) {
  return(
    <div className='row'>

        <div className='col-md-4 col-sm-6 col-xs-12'>
          <img src='./images/sliders/slider1.jpg' className='img' alt='intro' />
        </div>

        <div className='col-md-8 col-sm-6 col-xs-12'>
          <Fade right cascade>
            <Translate content='homepage.concept.title' className='title' unsafe/>
            <div className="color_border"></div>
          </Fade>

          <Translate content='homepage.concept.body' className='body' component='div' unsafe/>
        </div>

    </div>
  )
}

export default Intro;
