const axios = require('axios');
const instance = axios.create();

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

axios.interceptors.response.use(function(response) {
    return response;
}, function (error) {
    console.log('axios', error);

    const originalRequest = error.config;
    originalRequest._retry = true;

    return Promise.reject(error);
});
