const axios = require("axios");

export const agencyService = { getAgency, createAgency, updateAgency, getAgencyOrders, getActiveOrder, getCurrentOrder, upgradeSubscription, endSubscription, reactivate };

function getAgency() {
  return axios.get(`/agencies/show`);
}

function createAgency(values) {
  const data = {
    City: values.city,
    CompanyName: values.companyName,
    Email: values.email,
    DpofirstName: values.dpofirstName,
    DpolastName: values.dpolastName,
    ManagerEmail: values.managerEmail,
    ManagerFirstName: values.managerFirstName,
    ManagerLastName: values.managerLastName,
    PostalCode: values.postalCode,
    RbfirstName: values.rbfirstName,
    RblastName: values.rblastName,
    StreetName: values.streetName,
    StreetNumber: values.streetNumber,
    Phone: values.phone,
    Rcsl: values.rcsl,
    Autorisation: values.autorisation,
    Tva: values.tva,
    ManagerLocality: values.managerLocality,
    Iban: values.iban,
    Bic: values.bic,
    Iban2: values.iban2,
    Bic2: values.bic2,
    Iban3: values.iban3,
    Bic3: values.bic3,
    Iban4: values.iban4,
    Bic4: values.bic4,
    ManagerProfession: values.managerProfession,
    Country: values.country,
  };
  return axios.post(`/agencies/create`, data);
}

function updateAgency(values) {
  const data = {
    City: values.city,
    CompanyName: values.companyName,
    Email: values.email,
    DpofirstName: values.dpofirstName,
    DpolastName: values.dpolastName,
    ManagerEmail: values.managerEmail,
    ManagerFirstName: values.managerFirstName,
    ManagerLastName: values.managerLastName,
    PostalCode: values.postalCode,
    RbfirstName: values.rbfirstName,
    RblastName: values.rblastName,
    StreetName: values.streetName,
    StreetNumber: values.streetNumber,
    Phone: values.phone,
    Rcsl: values.rcsl,
    Autorisation: values.autorisation,
    Tva: values.tva,
    ManagerLocality: values.managerLocality,
    Iban: values.iban,
    Bic: values.bic,
    Iban2: values.iban2,
    Bic2: values.bic2,
    Iban3: values.iban3,
    Bic3: values.bic3,
    Iban4: values.iban4,
    Bic4: values.bic4,
    ManagerProfession: values.managerProfession,
    Country: values.country,
  };
  console.log(data);

  return axios.put(`/agencies/update/${values.id}`, data);
}

export function getAgencyOrders() {
  return axios.get(`/subscriptions/orders`);
}

export function getActiveOrder() {
  return axios.get(`/subscriptions/active_order`);
}

export function getCurrentOrder() {
  return axios.get(`/subscriptions/current_order`);
}
export function upgradeSubscription(with_media) {
  const data = {
    withMedia: with_media,
  };
  return axios.post(`/subscriptions/upgrade`, data);
}

export function endSubscription(id) {
  return axios.put(`/subscriptions/unsubscribe/${id}`);
}
export function reactivate(id) {
  return axios.put(`/subscriptions/reactivate/${id}`);
}
