import React from "react";
import Translate from "react-translate-component";

function Navigation({ activeTab, changeTab, children, type, edit, onSubmit }) {
  const active = (tab) => {
    return activeTab === tab ? "active" : "";
  };
  const changeTabUrl = (tab) => {
    edit && onSubmit();
    !edit && changeTab(tab);
    window.location.hash = tab;
  };
  return (
    <div className="navigation mb-1">
      <div className={`tabs ${edit ? "full" : ""}`}>
        <div className={`tab ${active("tab1")}`} onClick={() => changeTabUrl("tab1")}>
          <Translate content={`navigation.tabs.property`} />
        </div>
        <div className={`tab ${active("tab2")}`} onClick={() => changeTabUrl("tab2")}>
          <Translate content={`navigation.tabs.${type}.seller`} />
        </div>
        <div className={`tab ${active("tab3")}`} onClick={() => changeTabUrl("tab3")}>
          <Translate content={`navigation.tabs.${type}.buyer`} />
        </div>
        <div className={`tab ${active("tab4")}`} onClick={() => changeTabUrl("tab4")}>
          <Translate content={`navigation.tabs.${type}.act`} />
        </div>

        {edit && (
          <div className={`tab ${active("tab5")}`} onClick={() => changeTabUrl("tab5")}>
            <Translate content={`navigation.tabs.${type}.documents`} />
          </div>
        )}
      </div>

      <div className="nav-content">{children}</div>
    </div>
  );
}

export default Navigation;
