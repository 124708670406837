import React, { useState } from "react";
import Translate from "react-translate-component";
import { StripeProvider } from "react-stripe-elements";
import MyStoreCheckout from "./stripe/MyStoreCheckout";
import Sepa from "./stripe/sepa/sepa";
import { Link } from "react-router-dom";
import counterpart from "counterpart";

function SubscriptionsForm({ activeSubscription, agencyPanel, publicKey }) {
  const [tab, setTab] = useState("visa");
  const translator = (key) => {
    return counterpart.translate(key);
  };
  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(date);
  };
  const date = (month) => {
    var CurrentDate = new Date();
    CurrentDate.setMonth(CurrentDate.getMonth() + month);
    return formatDate(CurrentDate);
  };

  const solde = () => {
    return counterpart.translate(`subscriptions.solde`);
  };

  const delay = translator(`subscriptions.${activeSubscription.type}.periods.${activeSubscription.period}.delay`);
  const price = translator(`subscriptions.${activeSubscription.type}.periods.${activeSubscription.period}.periodPrice`);
  const from = date(0);

  const soldePrice = () => {
    var soldeVal = solde() ? solde() : 1;
    var oPrice = price;

    return <span>{(soldeVal * oPrice).toString()} € </span>;
  };

  return (
    <div className="payement">
      <div className="row">
        <div className="col-sm-12">
          <div className={`card ${activeSubscription.type}`}>
            <div className="card-container">
              <div className="col-sm-12 row">
                <div className="col-sm-6 col-md-4">
                  <Translate content="payment.detail.from" component="b" />
                  {from}
                </div>
                <div className="col-sm-6 col-md-4">
                  <Translate content="payment.detail.delay" component="b" />
                  {delay}
                  <Translate content="payment.detail.months" component="b" className="ml-1" />
                </div>
                <div className="col-sm-6 col-md-4">
                  <Translate content="payment.detail.price" component="b" />
                  {soldePrice()}
                  <Translate content="subscription_card.htva" className="ml-1" />
                </div>
              </div>
              <hr />
              <Translate content="subscription_form.first_title" component="h6" />

              {agencyPanel && (
                <div className="mt-4">
                  <div className="row stripe-tabs">
                    <div className="col-6">
                      <div className={`tab ${tab === "visa" ? "active" : ""}`} onClick={() => setTab("visa")}>
                        <i className="fa fa-credit-card mr-1"></i>
                        <Translate content="sepa.card" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={`tab ${tab === "sepa" ? "active" : ""}`} onClick={() => setTab("sepa")}>
                        <i className="fa mr-1 fa-university"></i>
                        <Translate content="sepa.sepa" />
                      </div>
                    </div>
                  </div>

                  {tab === "visa" && (
                    <StripeProvider apiKey={publicKey}>
                      <MyStoreCheckout subscription={activeSubscription} />
                    </StripeProvider>
                  )}

                  {tab === "sepa" && <Sepa apiKey={publicKey} subscription={activeSubscription} />}

                  {!agencyPanel && (
                    <Link to="/sign_up" className="btn btn-info pull-right">
                      <Translate content="authentication.signup" component="b" />
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsForm;
