const axios = require("axios");

export const accountService = { createFolder, getFolders, getFolder, updateFolder, generateDocument, sendInvitation, getAgencyCountry, removeFolder };

const fileName = {
  sell: {
    0: "provenance_du_bien.docx",
    1: "provenance_des_fonds.docx",
    2: "analyse_risque_vendeur.docx",
    3: "analyse_risque_acheteur.docx",
    4: "compromis.docx",
    5: "attestation_notaire.docx",
    6: "facture.xlsx",
    7: "Formulaire_RGPD_proprietaire.docx",
    8: "Formulaire_RGPD_acheteur.docx",
    9: "file_one.docx",
    10: "file_two.docx",
    11: "file_three.docx",
    12: "file_four.docx",
    13: "file_five.docx",
    14: "file_six.docx",
    15: "file_seven.docx",
  },
  rent: {
    0: "provenance_du_bien.docx",
    1: "provenance_des_fonds.docx",
    2: "analyse_risque_proprietaire.docx",
    3: "analyse_risque_locataire.docx",
    4: "bail.docx",
    5: "attestation_notaire.docx",
    6: "facture.xlsx",
    7: "Formulaire_RGPD_proprietaire.docx",
    8: "Formulaire_RGPD_locataire.docx",
    9: "file_one.docx",
    10: "file_two.docx",
    11: "file_three.docx",
    12: "file_four.docx",
    13: "file_five.docx",
    14: "file_six.docx",
    15: "file_seven.docx",
  },
};

function createFolder(values) {
  return axios.post(`/folders/create`, values);
}

function getFolders() {
  return axios.get(`/folders/index`);
}

function getFolder(id) {
  return axios.get(`/folders/show/${id}`);
}

function updateFolder(values) {
  return axios.put(`/folders/update/${values.id}`, values);
}

function removeFolder(id) {
  return axios.delete(`/folders/delete/${id}`);
}

function generateDocument(docIndex, type, idFolder, documentLanguage) {
  const formData = {
    fileType: type,
    idFolder: idFolder,
    fileName: fileName[type][docIndex],
    fileLanguage: documentLanguage,
  };
  return axios.post("/documentGenerator/create", formData, { responseType: "blob" });
}

function sendInvitation(values) {
  return axios.post(`/invitations/create`, values);
}
function getAgencyCountry() {
  return axios.get(`/folders/agency_country`);
}
