import React from "react";
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/actions/authActions';
import ResetPasswordForm from './forms/reset_password_form';
import Translate from 'react-translate-component';

class ResetPassword extends React.Component {

  submitReset = (values) => {
    this.props.resetPassword(values);
  }
  render(){
    let reset_token = this.props.match.params.reset_token;
    return (
      <div className='authentification'>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <Translate content='authentication.reset_title' className='auth-title mt-3 mb-2' component='div' />
            <div className='blue-line' />
            <ResetPasswordForm submitReset={this.submitReset} reset_token={reset_token}/>
          </div>

          <div className='col-md-6 col-sm-12 bg-auth'>
            <div className='img signin'></div>
            <div className='img-bg'></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (values) => dispatch(actionCreator.resetPassword(values)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
