import React from "react";
import CustomInput from "./customInput";

function inputGroup({ agencyCountry, formData, showLot, folderType, handleInputChange, setDate, prefix, values, conditionValue, index }) {
  return (
    <div>
      <div className="row">
        {(formData || []).map((data, i) => (
          <CustomInput
            agencyCountry={agencyCountry}
            key={`${prefix}_${i}_${index}`}
            prefix={prefix}
            index={index}
            data={data}
            folderType={folderType}
            handleInputChange={handleInputChange}
            setDate={setDate}
            value={values[data.name]}
            conditionValue={conditionValue}
            showLot={showLot}
          />
        ))}
      </div>
    </div>
  );
}

export default inputGroup;
