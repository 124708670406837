import React from "react";
import Translate from "react-translate-component";
import counterpart from "counterpart";

function UpdateCurrentOrder({ upgradeSubscription, subscription }) {
  const checkOption = (option) => {
    return counterpart.translate(`subscriptions.${subscription}.${option}.valid`) ? "fa-check" : "fa-times";
  };
  const with_media = () => {
    return counterpart.translate(`subscriptions.${subscription}.option3.valid`);
  };

  const price = (code) => {
    return counterpart.translate(code);
  };

  return (
    <div className="col-xs-12 col-sm-6 col-md-6">
      <div className="update-currentOrder">
        <p> Upgrade you plan </p>
        <div className="price">{price(`subscriptions.${subscription}.periods.twoyears.price`)}€</div>
        <hr />
        <div className="block">
          <div className="option">
            <ul>
              <li>
                <i className={`fa ${checkOption("option1")}`} aria-hidden="true"></i>
                <Translate content={`subscriptions.${subscription}.option1.title`} />
              </li>

              <li>
                <i className={`fa ${checkOption("option2")}`} aria-hidden="true"></i>
                <Translate content={`subscriptions.${subscription}.option2.title`} />
              </li>

              <li>
                <i className={`fa ${checkOption("option3")}`} aria-hidden="true"></i>
                <Translate content={`subscriptions.${subscription}.option3.title`} />
              </li>
            </ul>
          </div>
          <div onClick={() => upgradeSubscription(with_media())} className="upgrade-btn">
            {" "}
            Update subscription
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCurrentOrder;
