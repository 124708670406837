import React from 'react';
import useForm from 'react-hook-form';

export default function ContactForm() {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => console.log(data);
  console.log(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="text" placeholder="Email" name="Email" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
      <input type="text" placeholder="Name" name="name" ref={register({required: true, maxLength: 80})} />

      <textarea type="message" placeholder="Message" name="message" ref={register({required: true})} >
      </textarea>


      <button type="submit"> Send</button>
    </form>
  );
}
