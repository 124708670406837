import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import useAxios from "axios-hooks";

import { Modal } from "react-bootstrap";
import Translate from "react-translate-component";
import Loader from "react-loader-spinner";

function AgencyFile({ lang, name, fileType }) {
  const { handleSubmit, register, errors } = useForm();
  const [values, setValues] = useState({ [name]: "", agencyFileName: "" });
  const [showModal, setShowModal] = useState(false);
  const [linkRef, setLinkRef] = useState(React.createRef());

  const filesName = {
    file_one: "file_one.docx",
    file_two: "file_two.docx",
    file_three: "file_three.docx",
    file_four: "file_four.docx",
    file_five: "file_five.docx",
    file_six: "file_six.docx",
    file_seven: "file_seven.docx",
  };
  const bodyData = { fileName: filesName[name], fileLanguage: lang, fileType: fileType };

  const [{ agencyFileData, agencyFileLoading }, downloadAgencyFile] = useAxios(
    {
      url: "/customFiles/download_file/agency",
      method: "POST",
      data: bodyData,
      responseType: "blob",
    },
    { manual: true, responseType: "blob" }
  );
  const download = () => {
    downloadAgencyFile().then((response) => {
      const href = window.URL.createObjectURL(response.data);
      const a = linkRef.current;
      a.download = filesName[name];
      a.href = href;
      a.click();
      a.href = "";
    });
  };
  const [{ data, loading }, refetch] = useAxios(
    {
      url: "/agencyCustomFiles/check_file",
      method: "POST",
      data: bodyData,
    },
    { manual: true }
  );
  const [{ loading: uploadLoading }, executeUpload] = useAxios(
    {
      url: "/agencyCustomFiles/create",
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateUpload] = useAxios(
    {
      method: "PUT",
      headers: {
        "content-type": "multipart/form-data",
      },
    },
    { manual: true }
  );
  const [{ loading: deleteLoading }, executedelete] = useAxios(
    {
      method: "POST",
    },
    { manual: true }
  );

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    setValues({ ...values, [name]: file });
  };
  const inputChangedHandler = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleDeleteFile = () => {
    executedelete({
      url: "/agencyCustomFiles/delete/" + data.fileResponse.fileId,
      data: bodyData,
    }).then((response) => {
      refetch();
      setValues({ [name]: "" });
    });
    setShowModal(false);
  };

  useEffect(() => {
    refetch().then((response) => {
      setValues({ ...values, agencyFileName: response.data.fileResponse.fileName });
    });
  }, [setValues]);

  const onSubmit = (vals) => {
    const formData = new FormData();
    formData.append("file", values[name]);
    formData.append("defaultFileName", filesName[name]);
    formData.append("agencyFileName", values.agencyFileName);
    formData.append("language", lang);
    formData.append("fileType", fileType);

    if (!data.fileResponse.fileExists) {
      executeUpload({
        data: formData,
      }).then((response) => {
        refetch();
        setValues({ [name]: "" });
      });
    } else {
      updateUpload({
        data: formData,
        url: "/agencyCustomFiles/update/" + data.fileResponse.fileId,
      }).then((response) => {
        refetch().then((response) => {
          setValues({ ...values, agencyFileName: response.data.fileResponse.fileName, [name]: "" });
        });
      });
    }
  };

  return (
    <div className="col-md-4 col-sm-6 col-xs-12">
      <a ref={linkRef} />
      <form className={`file-uploader ${uploadLoading ? "upload-loading" : ""}`} onSubmit={handleSubmit(onSubmit)}>
        {}
        {!loading && data && data.fileResponse.fileExists && <i className="fa fa-paperclip"></i>}
        <div className="file-uploader__message-area">
          <input
            className="text-center"
            type="text"
            ref={register({ required: true })}
            name="agencyFileName"
            defaultValue={values.agencyFileName}
            onChange={(e) => inputChangedHandler(e)}
            placeholder="File name"
          />
          {errors.agencyFileName && <i className="error"> Please choose a name </i>}
        </div>
        <div className="file-chooser">
          {!uploadLoading && (
            <label>
              {values[name] === "" && <Translate content="custom_files.choose_file" />}
              {values[name] !== "" && <span className="valid">{values[name].name}</span>}
              <input className="file-chooser__input" type="file" ref={register({ required: true })} name={name} onChange={(e) => fileChangedHandler(e)} />
              {errors[name] && <Translate content="custom_files.choose_file" component="i" className="error" />}
            </label>
          )}
          {<Loader type="ThreeDots" color="#0575e6" height={50} width={100} visible={uploadLoading} className="upload-spinner" />}
        </div>

        {!loading && data && !data.fileResponse.fileExists && <input className="file-uploader__submit-button" type="submit" value="Upload" />}
        {!loading && data && data.fileResponse.fileExists && (
          <div className="file-uploader__actions-buttons">
            <span className="edit" onClick={handleSubmit(onSubmit)}>
              <i className="fa fa-edit"></i>
            </span>
            <span className="del" onClick={() => setShowModal(true)}>
              <i className="fa fa-trash"></i>
            </span>
            <span className="show" onClick={() => download()}>
              <i className="fa fa-eye"></i>
            </span>
          </div>
        )}
        {loading && (
          <div className="loading">
            <Loader type="ThreeDots" color="#0575e6" height={25} width={50} visible={true} className="spinner" />
          </div>
        )}
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate content="custom_files.modal.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-2">
              <Translate content="custom_files.modal.placeholder" component="p" />
            </div>

            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => handleDeleteFile()}>
                <Translate content="custom_files.modal.delete" />
              </button>
            </div>
            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => setShowModal(false)}>
                <Translate content="custom_files.modal.close" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AgencyFile;
