import React, { useState } from "react";
import Translate from "react-translate-component";
import useForm from "react-hook-form";
import counterpart from "counterpart";

function Invite({ type, sendInvitation }) {
  const { handleSubmit, register, errors } = useForm();
  const [values, setValues] = useState({ email: "", section: "seller" });
  const selectOption = (code) => {
    return counterpart.translate(code);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const onSubmit = (data) => {
    sendInvitation(values);
  };

  return (
    <div className="row">
      <form onSubmit={handleSubmit(onSubmit)} className="col-md-9 col-sm-12 mt-5">
        <div className="card">
          <Translate content="forms.folder.invite.title" className="card-header white-text text-center bg-white blue-border blue-txt py-2" component="h5" />

          <div className="card-body px-lg-5 pt-4 my-4">
            <Translate content="forms.folder.invite.placeholder" className="h6 mb-3" component="h6" />
            <div className="md-form">
              <Translate content="forms.folder.invite.email_label" component="div" />
              <input
                type="text"
                className="invite-input"
                name="email"
                placeholder={counterpart.translate("forms.folder.invite.email_placeholder")}
                onChange={handleInputChange}
                defaultValue={values.email}
                ref={register({
                  required: "forms.signin.validations.email_req",
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "forms.signin.validations.email" },
                })}
              />
              {errors.email && <Translate content={errors.email.message} className="text-warning xs-text" />}
            </div>

            <Translate content="forms.folder.invite.give_access" component="div" />

            <div className="md-form">
              <select className="form-control invite-input" onChange={handleInputChange} defaultValue={values.section} name="section">
                <option value="seller">{selectOption(`navigation.tabs.${type}.seller`)}</option>
                <option value="buyer">{selectOption(`navigation.tabs.${type}.buyer`)}</option>
                {type === "sell" && <option value="act">{selectOption(`navigation.tabs.${type}.act`)}</option>}
              </select>
            </div>

            <div className="input-group-append">
              <button className="btn  btn-block z-depth-0  invite-btn blue-bg" type="submit">
                <Translate content="forms.folder.invite.button_action" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Invite;
