import React from "react";

class Cgu extends React.Component {
  render() {
    return (
      <div className="cgu">
        <p>
          <strong>Mentions l&eacute;gales</strong>
        </p>
        <p>
          <strong>Editeur Responsable</strong>
        </p>
        <p>LINC SA</p>
        <p>
          55 rue de Luxembourg
          <br /> L-4391 Pontpierre
          <br /> Tel.&nbsp;+352 26 57 07 1
        </p>
        <p>RC B143207</p>
        <p>Num&eacute;ro d&rsquo;identification &agrave; la TVA: LU22952280</p>
        <p>H&eacute;bergeur :&nbsp;Microsoft Azure (Microsoft Corporation)</p>
        <p>
          <strong>D&eacute;finitions</strong>
        </p>
        <p>
          &laquo; Le site &raquo;, &laquo;&nbsp;le portail&nbsp;&raquo; ou &laquo;&nbsp;l&rsquo;application&nbsp;&raquo;: l&rsquo;application Internet Orea.immo tel
          qu&rsquo;exploit&eacute;e par LINC SA, dans quelque pays que ce soit et sous quelque extension dns que ce soit.
        </p>
        <p>
          &laquo; L&rsquo;utilisateur &raquo;: de mani&egrave;re g&eacute;n&eacute;rale tout visiteur ou Internaute qui visite et utilise le site en &eacute;tant ou en
          n&rsquo;&eacute;tant pas connect&eacute; &agrave; un compte.
        </p>
        <p>
          &laquo;&nbsp;Le client&nbsp;&raquo; ou &laquo;&nbsp;l&rsquo;agence&nbsp;&raquo;&nbsp;: de mani&egrave;re g&eacute;n&eacute;rale tout professionnel qui s&rsquo;inscrit
          (sous forme gratuite ou d&rsquo;abonnement payant) et qui utilise les fonctionnalit&eacute;s accessible gr&acirc;ce &agrave; sa forme de souscription.
        </p>
        <p>
          &laquo; L&rsquo;utilisateur invit&eacute; &raquo;: de mani&egrave;re g&eacute;n&eacute;rale tout visiteur ou Internaute qui visite et utilise le site moyennant
          cr&eacute;ation d&rsquo;un compte autre qu&rsquo;un compte agence (propri&eacute;taire, locataire, acheteur, notaire&hellip;).
        </p>
        <p>
          <strong>Conditions g&eacute;n&eacute;rales d'utilisation du site et des services qui en d&eacute;coulent</strong>
        </p>
        <p>
          L&rsquo;utilisation de l&rsquo;application n'est soumise &agrave; aucune garantie de la part de Linc SA. En utilisant les services, l&rsquo;utilisateur accepte
          express&eacute;ment les pr&eacute;sents termes, conditions d&rsquo;utilisation et mentions d'avertissement. L&rsquo;utilisateur est conscient des risques li&eacute;s aux
          sp&eacute;cificit&eacute;s techniques du r&eacute;seau Internet en g&eacute;n&eacute;ral et du site en particulier.
        </p>
        <p>
          Les informations, produits et services inclus sur le site peuvent comporter des inexactitudes commerciales ou techniques pour lesquelles Linc SA ne pourra &ecirc;tre
          tenue responsable. Il revient &agrave; l'utilisateur d'utiliser les informations contenues sur le site avec prudence et de proc&eacute;der &agrave; toute
          v&eacute;rification n&eacute;cessaire avant de conclure des engagements.
        </p>
        <p>
          Le site peut contenir des liens vers des sites Internet tiers. Orea.immo SA ne peut exercer un contr&ocirc;le sur le contenu et les pratiques de ces sites, ni assumer la
          responsabilit&eacute; quant &agrave; leur contenu. La pr&eacute;sence de ces liens sur le site ne signifie pas que Linc SA approuve les informations contenues sur ces
          sites et ne peut pas &ecirc;tre tenue responsable pour un litige quelconque survenant entre un site r&eacute;f&eacute;renc&eacute; sur le site et un utilisateur.
        </p>
        <p>
          Linc SA ne peut &ecirc;tre tenu responsable pour les informations obtenues soit directement, soit indirectement sur le site ni pour les relations commerciales
          &eacute;ventuelles entre utilisateurs. Chaque utilisateur demeure seul responsable des engagements qu'il contracte.
        </p>
        <p>
          <u>
            Toutes les donn&eacute;es personnelles et sensibles sont encrypt&eacute;es &agrave; la base. Cet encryptage des donn&eacute;es assure que seule l&rsquo;agence et les
            utilisateurs invit&eacute;s par l'agence acc&egrave;dent aux donn&eacute;es respectives moyennant l'acc&egrave;s avec mot de passe &agrave; leur compte
            s&eacute;curis&eacute;. M&ecirc;me l'exploitant n&rsquo;a aucune possibilit&eacute; d'acc&eacute;der aux informations encrypt&eacute;es.
          </u>
        </p>
        <p>
          Linc SA consacre ses meilleurs efforts &agrave; maintenir accessible le site 7 jours sur 7 et 24 heures sur 24, mais peut interrompre l'acc&egrave;s pour des
          activit&eacute;s de maintenance, de mise &agrave; jour, de d&eacute;veloppement ou pour toutes autres raisons. Linc SA ne saura &ecirc;tre tenu responsable de ces
          interruptions momentan&eacute;es et des cons&eacute;quences qui peuvent en d&eacute;couler, y compris des manques &agrave; gagner, pour l'utilisateur ou tout tiers. Linc
          SA ne peut pas &ecirc;tre tenu responsable pour d'&eacute;ventuelles inaccessibilit&eacute;s temporaires ou permanentes dues &agrave; des &eacute;v&eacute;nements qui
          &eacute;chappent &agrave; son contr&ocirc;le (catastrophe naturelle, virus, hacking,...), ni pour les dommages directs ou indirects qui en d&eacute;coulent, notamment de
          dommages r&eacute;sultant d'une perte de donn&eacute;es ou d'une perte de b&eacute;n&eacute;fices d&eacute;coulant de l'utilisation ou de la non-accessibilit&eacute; du
          site. Malgr&eacute; les meilleurs efforts consacr&eacute;s, Linc SA ne peut garantir ni la confidentialit&eacute; des &eacute;changes, ni l'interception &eacute;ventuelle
          des informations &eacute;chang&eacute;es via le site ou contenues dans la base de donn&eacute;es.
        </p>
        <p>
          Linc SA se r&eacute;serve le droit d&rsquo;utiliser et de partager certaines donn&eacute;es non-personnelles, non-sensibles et non-encrypt&eacute;es &agrave; des pures
          fins d&rsquo;analyses statistiques du march&eacute; immobilier, notamment les attributs factuels li&eacute;s aux biens (p.ex. m2, nombre de chambres, rue (sans le
          num&eacute;ro), nombre d&rsquo;emplacements, prix&hellip;).&nbsp;&nbsp;
        </p>
        <p>
          L'ensemble des &eacute;l&eacute;ments qu&rsquo;ils soient graphiques, textuels ou autres est prot&eacute;g&eacute; par les droits d'auteur. Aucune personne n'est
          autoris&eacute;e &agrave; copier, modifier, reproduire, distribuer ou publier toute information pr&eacute;sente sur le site sans l'accord pr&eacute;alable &eacute;crit de
          Linc SA.
        </p>
        <p>Linc SA se r&eacute;serve le droit de modifier &agrave; tout moment les termes et conditions d'utilisation du site.</p>
        <p>
          En cas d'inex&eacute;cution ou de non-respect par l'utilisateur de l'une des obligations et stipulations pr&eacute;vues par les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales, Linc SA pourra modifier, suspendre, limiter ou supprimer l'acc&egrave;s au service, sans que celui-ci ne puisse r&eacute;clamer aucune
          indemnit&eacute; quelconque.
        </p>
        <p>
          La loi luxembourgeoise a vocation seule &agrave; s&rsquo;appliquer. Toute contestation et/ou difficult&eacute; d'interpr&eacute;tation ou d'ex&eacute;cution des
          pr&eacute;sentes conditions g&eacute;n&eacute;rales rel&egrave;vera des tribunaux comp&eacute;tents au Grand-Duch&eacute; du Luxembourg. En cas de pluralit&eacute;s de
          d&eacute;fendeurs, le Tribunal de Luxembourg Ville est seul comp&eacute;tent.
        </p>
        <p>
          <strong>Protection des donn&eacute;es personnelles et RGPD</strong>
        </p>
        <p>Identit&eacute; du responsable de traitement&nbsp;:</p>
        <p>
          LINC SA, ayant son si&egrave;ge social 55, rue de Luxembourg &agrave; L-4391 Pontpierre et immatricul&eacute;e aupr&egrave;s du Registre de Commerce et des
          Soci&eacute;t&eacute;s, Luxembourg sous le num&eacute;ro B143207 est responsable du traitement de Vos Donn&eacute;es.
        </p>
        <p>Cat&eacute;gories de donn&eacute;es personnelles trait&eacute;es&nbsp;:</p>
        <p>
          La notion de &laquo; donn&eacute;es &agrave; caract&egrave;re personnel &raquo; d&eacute;signe toute information se rapportant &agrave; une personne physique
          identifi&eacute;e ou identifiable. Une personne est &laquo; identifiable &raquo; d&egrave;s lors qu&rsquo;elle peut &ecirc;tre identifi&eacute;e, directement ou
          indirectement, en particulier par r&eacute;f&eacute;rence &agrave; un num&eacute;ro d&rsquo;identification ou &agrave; un ou plusieurs &eacute;l&eacute;ments qui lui sont
          propres.
        </p>
        <p>
          L&rsquo;exploitant ne collecte pas de donn&eacute;es personnelles autres que celles que les utilisateurs saisissent soit directement dans orea.immo soit fournissent
          &agrave; leur agence immobili&egrave;re dans le cadre de la transaction immobili&egrave;re (location/vente) envisag&eacute;e. Dans ce contexte, l&rsquo;exploitant traite
          toutes les informations habituellement n&eacute;cessaires &agrave; une transaction immobili&egrave;re (telles que pr&eacute;nom, nom, statut, coordonn&eacute;es de
          contact, profession, identification du bien immobilier et de la nature de la transaction, d&eacute;tails de la transaction).
        </p>
        <p>
          En tout &eacute;tat de cause, l&rsquo;exploitant veille &agrave; ce que seules soient collect&eacute;es, trait&eacute;es et conserv&eacute;es les donn&eacute;es
          ad&eacute;quates et pertinentes au regard de ce qui est n&eacute;cessaire &agrave; la poursuite des finalit&eacute;s d&eacute;clar&eacute;es.
        </p>
        <p>
          Pour l&rsquo;ex&eacute;cution des services propos&eacute;s par la plateforme, Linc SA doit recueillir certaines informations &agrave; caract&egrave;re personnel
          li&eacute;es au client (agence) et aux acheteurs, vendeurs, bailleurs et preneurs ainsi qu&rsquo;aux transactions immobili&egrave;res m&ecirc;mes. Ces informations sont
          n&eacute;cessaires pour assurer les services propos&eacute;s par le site.
        </p>
        <p>
          En utilisant la plateforme, les utilisateurs marquent leur accord quant &agrave; la collecte au stockage et &agrave; l&rsquo;utilisation des informations les concernant
          par la plateforme pour l&rsquo;ex&eacute;cution des t&acirc;ches couvertes par le plateforme.
        </p>
        <p>
          <u>
            Toutes les informations personnelles ou sensibles des acheteurs, vendeurs, bailleurs et preneurs sont encrypt&eacute;es &agrave; la source et sont uniquement et
            exclusivement accessibles au client (agence) qui les a saisies et aux utilisateurs invit&eacute;s respectifs, moyennant connexion avec login et mot de passe &agrave;
            leur espace personnalis&eacute; s&eacute;curis&eacute; respectif. M&ecirc;me Linc SA n&rsquo;a aucune possibilit&eacute; d&rsquo;acc&eacute;der &agrave; ses
            informations, qui sont encrypt&eacute;es &agrave; la source.{" "}
          </u>
        </p>
        <p>
          <u>
            Il rel&egrave;ve de la responsabilit&eacute; des utilisateurs de ne pas divulguer les acc&egrave;s &agrave; des personnes non-autoris&eacute;es. Il appartient en outre
            &agrave; l&rsquo;agence de demander pr&eacute;alablement l&rsquo;accord aux acheteurs, vendeurs, bailleurs et preneurs de saisir et de g&eacute;rer -y compris dans la
            plateforme - leurs informations personnelles dans le respect des lois en vigueur, notamment la l&eacute;gislation en mati&egrave;re de RGPD. Le client tient dans tous
            les cas Linc SA quitte et indemne &agrave; l&rsquo;&eacute;gard de toute plainte ou r&eacute;clamation en mati&egrave;re de protection des donn&eacute;es personnelles.
          </u>
        </p>
        <p>
          Lorsque vous naviguez sur notre site, sans avoir eu recours &agrave; un espace personnalis&eacute;, nous collectons &eacute;galement certaines informations relatives
          &agrave; vos habitudes de navigation. Les conditions de cette collecte sont consultables dans la partie relative &agrave; l&rsquo;utilisation des cookies.
        </p>
        <p>
          Ces informations &agrave; caract&egrave;re personnel nous sont utiles pour mieux personnaliser les services et contenus et faciliter votre navigation sur notre site. Des
          cookies sont &eacute;galement n&eacute;cessaires au bon fonctionnement de certains services ou encore pour mesurer l&rsquo;audience.
        </p>
        <p>
          Nous ne demanderons jamais de nous fournir des donn&eacute;es relatives aux origines ethniques, aux opinions politiques, philosophiques ou religieuses ou &agrave; une
          &eacute;ventuelle appartenance syndicale, ni de donn&eacute;es relatives &agrave; la sant&eacute; ou &agrave; l&rsquo;orientation sexuelle.
        </p>
        <p>Finalit&eacute;s des traitements&nbsp;et transferts des donn&eacute;es:</p>
        <p>
          La plateforme orea.immo est un outil sp&eacute;cialement con&ccedil;u pour faciliter le travail administratif des agences immobili&egrave;res et am&eacute;liorer la
          communication entre l&rsquo;agence et ses clients. Les donn&eacute;es recueillies sur la plateforme sont utilis&eacute;es essentiellement pour
          l&rsquo;&eacute;tablissement des documents n&eacute;cessaires &agrave; la transaction immobili&egrave;re envisag&eacute;e et &agrave; faciliter la communication et les
          relations d&rsquo;affaires entre l&rsquo;agence et ses clients.
        </p>
        <p>
          Le traitement des donn&eacute;es est n&eacute;cessaire afin de permettre &agrave; l&rsquo;exploitant de vous fournir ces services. Il rel&egrave;ve de la
          responsabilit&eacute; de l&rsquo;agence de demander par &eacute;crit l&rsquo;accord de ses clients pour que leurs informations soient saisies, trait&eacute;es et
          stock&eacute;es dans la plateforme.
        </p>
        <p>
          Les renseignements personnels soumis sont utilis&eacute;s uniquement pour le but d&rsquo;utilisation pour lequel il est pr&eacute;sent&eacute; ou pour toute autres fins
          secondaires qui sont li&eacute;es &agrave; la finalit&eacute; premi&egrave;re, &agrave; moins que nous divulguions d'autres usages dans la pr&eacute;sente politique de
          confidentialit&eacute; ou au moment de la collecte. Aucune Donn&eacute;es personnelles n&rsquo;est transf&eacute;r&eacute;e vers un pays tiers ou une organisation
          internationale par le Responsable du Traitement. Une copie de ces garanties peut &ecirc;tre obtenue par le Client sur simple demande envoy&eacute;e &agrave;
          l&rsquo;exploitant. Sauf disposition contraire, les termes utilis&eacute;s ont la m&ecirc;me d&eacute;finition que celle donn&eacute;e par le R&egrave;glement
          g&eacute;n&eacute;ral sur la protection des donn&eacute;es (UE) 2016/679.
        </p>
        <p>
          L&rsquo;utilisateur a conscience que la communication d&rsquo;informations &agrave; une agence immobili&egrave;re par le biais du site impliquera la r&eacute;ception, le
          traitement et le stockage de ses informations dans les bases de donn&eacute;es de ladite agence immobili&egrave;re qui pourrait ne pas se conformer &agrave; la
          pr&eacute;sente politique de confidentialit&eacute;.
        </p>
        <p>
          Certaines informations qui ont trait aux attributs des biens et de la transaction (p.ex. surface habitable, adresse sans le num&eacute;ro, nombre de chambres,
          prix&hellip;) mais qui ne comportent aucune information personnelle sont utilis&eacute;es &agrave; des fins d&rsquo;analyses statistiques et peuvent dans ce cadre
          &ecirc;tre communiqu&eacute;s &agrave; des partenaires sp&eacute;cifiques actifs dans le domaine des analyses statistiques du march&eacute; immobilier.
        </p>
        <p>Dur&eacute;e de conservation des donn&eacute;es :</p>
        <p>
          L&rsquo;exploitation conserve les donn&eacute;es aussi longtemps qu&rsquo;il en a besoin pour garantir l&rsquo;ex&eacute;cution des finalit&eacute;s pour lesquelles elles
          ont &eacute;t&eacute; collect&eacute;es et/ou aussi longtemps qu&rsquo;il en a besoin pour respecter les dispositions l&eacute;gales et r&eacute;glementaires qui
          s&rsquo;appliquent &agrave; lui.
        </p>
        <p>S&eacute;curit&eacute;&nbsp;:</p>
        <p>
          Les donn&eacute;es personnelles recueillies sont trait&eacute;es par l&rsquo;exploitant, en qualit&eacute; de responsable du traitement, dans le respect de la
          l&eacute;gislation sur la protection des donn&eacute;es applicable. L&rsquo;exploitant s&rsquo;engage &agrave; mettre en &oelig;uvre les mesures de s&eacute;curit&eacute;
          techniques et organisationnelles de nature &agrave; assurer la protection des donn&eacute;es personnelles contre les risques li&eacute;s &agrave; l&rsquo;usage des
          syst&egrave;mes d&rsquo;information.
        </p>
        <p>
          L&rsquo;exploitant consacre ainsi ses meilleurs efforts &agrave; assurer la s&eacute;curit&eacute;, l'int&eacute;grit&eacute; et la confidentialit&eacute; des
          donn&eacute;es et informations personnelles transmises sur/via le site. Pour ce faire, nous passons en revue et nous mettons &agrave; jour nos mesures de
          s&eacute;curit&eacute; en fonction des technologies actuelles. L&rsquo;utilisateur a malgr&eacute; tout conscience qu&rsquo;aucune donn&eacute;e transmise sur Internet ne
          peut &ecirc;tre garantie comme totalement s&eacute;curis&eacute;e.
        </p>
        <p>
          Tout personnel de de l&rsquo;exploitant est tenu de respecter la confidentialit&eacute; des renseignements personnels d&eacute;tenus par nos soins. Cependant, nous ne
          pouvons &ecirc;tre tenus responsables des suites r&eacute;sultant d&rsquo;un acc&egrave;s non-autoris&eacute; aux informations personnelles.
        </p>
        <p>Droit d&rsquo;acc&egrave;s, de modification, de rectification et de suppression&nbsp;:</p>
        <p>
          Vous b&eacute;n&eacute;ficiez du droit d&rsquo;acc&egrave;s &agrave; vos Donn&eacute;es Personnelles ainsi que du droit d&rsquo;obtenir leur rectification ou leur
          effacement de vos Donn&eacute;es Personnelles. Vous avez un droit &agrave; la limitation du traitement de vos Donn&eacute;es Personnelles. Vous avez &eacute;galement le
          droit de vous opposer au traitement ainsi que le droit &agrave; la portabilit&eacute; de vos Donn&eacute;es Personnelles. Ces droits ne peuvent &ecirc;tre exerc&eacute;s
          que dans la limite de toute obligation contractuelle ou l&eacute;gale. Vous avez &eacute;galement le droit d&rsquo;introduire une r&eacute;clamation aupr&egrave;s de
          l&rsquo;autorit&eacute; de contr&ocirc;le luxembourgeoise, la Commission nationale pour la protection des donn&eacute;es (https://cnpd.public.lu/fr.html).
        </p>
        <p>
          Vous pouvez exercer vos droits en contactant le responsable du traitement, &agrave; savoir la soci&eacute;t&eacute; LINC SA, ayant son si&egrave;ge social 55, rue de
          Luxembourg &agrave; L-4391 Pontpierre et immatricul&eacute;e aupr&egrave;s du Registre de Commerce et des Soci&eacute;t&eacute;s, Luxembourg sous le num&eacute;ro
          B219639. :
        </p>
        <p>
          Par courrier
          <br /> Linc SA
          <br /> 55 rue de Luxembourg
          <br /> L-4391 Pontpierre
        </p>
        <p>
          Par e-mail
          <br /> info@Orea.immo.lu
        </p>
        <p>
          <strong>Politique relative &agrave; l'utilisation des cookies</strong>
        </p>
        <ol>
          <li>Qu'est-ce qu'un cookie ?</li>
        </ol>
        <p>
          Un cookie est un fichier de donn&eacute;es susceptible d&rsquo;&ecirc;tre stock&eacute; dans un espace d&eacute;di&eacute; du disque dur de votre ordinateur, &agrave;
          l&rsquo;occasion de la consultation d&rsquo;un produit ou de l&rsquo;utilisation d&rsquo;un service en ligne, gr&acirc;ce &agrave; votre logiciel de navigation. Un
          fichier cookie permet &agrave; son &eacute;metteur d&rsquo;identifier le terminal sur lequel il est enregistr&eacute;, pendant toute sa dur&eacute;e de validit&eacute;.
        </p>
        <ol start="2">
          <li>A quoi servent les cookies &eacute;mis par le site?</li>
        </ol>
        <p>
          Seul l&rsquo;&eacute;metteur d&rsquo;un cookie est susceptible de lire ou modifier les informations qu&rsquo;il contient.
          <br /> Les cookies utilis&eacute;s sur le site permettent d'identifier et de garder en m&eacute;moire, l&rsquo;ensemble de vos recherche et de vos consultations sur le
          site , et de conna&icirc;tre plus g&eacute;n&eacute;ralement votre comportement en mati&egrave;re de visites. Ces informations sont utiles pour mieux personnaliser les
          services et contenus qui apparaissent sur le site et faciliter votre navigation. Des cookies sont &eacute;galement n&eacute;cessaires au bon fonctionnement de certains
          services ou encore pour mesurer l&rsquo;audience.
        </p>
        <p>
          Lorsque vous vous connectez au site, nous pouvons &ecirc;tre amen&eacute;s, sous r&eacute;serve de vos choix, &agrave; installer des cookies dans votre terminal. Ces
          cookies nous permettent de reconna&icirc;tre le navigateur de votre terminal pendant la dur&eacute;e de validit&eacute; du cookie concern&eacute;.
        </p>
        <p>
          Les cookies que nous &eacute;mettons sont utilis&eacute;s aux fins d&eacute;crites ci-dessous. Ils r&eacute;sultent des param&egrave;tres de votre logiciel de navigation
          utilis&eacute; lors de votre visite de notre site.
        </p>
        <p>Les cookies que nous &eacute;mettons nous permettent de faciliter votre navigation sur notre site et d&rsquo;am&eacute;liorer nos services :</p>
        <ul>
          <li>
            en adaptant la pr&eacute;sentation de notre site aux pr&eacute;f&eacute;rences d&rsquo;affichage de votre terminal (langue utilis&eacute;e, r&eacute;solution
            d&rsquo;affichage, syst&egrave;me d&rsquo;exploitation utilis&eacute;, etc.) ;
          </li>
          <li>
            en m&eacute;morisant des informations relatives &agrave; un formulaire que vous avez rempli sur notre site (inscription ou acc&egrave;s &agrave; votre compte) ou
            &agrave; des recherches, services ou informations que vous avez choisi sur notre site (recherche effectu&eacute;e, service souscrit, contenu consult&eacute;,
            r&eacute;servation effectu&eacute;e, etc.) ;
          </li>
          <li>
            en vous permettant d&rsquo;acc&eacute;der &agrave; des espaces r&eacute;serv&eacute;s et personnels de notre site, tels que votre compte, gr&acirc;ce &agrave; des
            identifiants ou des donn&eacute;es que vous nous avez &eacute;ventuellement ant&eacute;rieurement confi&eacute;s ;
          </li>
          <li>en mettant en application des mesures de s&eacute;curit&eacute; ;</li>
          <li>
            en permettant d&rsquo;&eacute;tablir des statistiques et volume de fr&eacute;quentation et d&rsquo;utilisation des divers &eacute;l&eacute;ments composant notre site
            (recherches, pages et contenus visit&eacute;, parcours) afin d&rsquo;am&eacute;liorer l&rsquo;int&eacute;r&ecirc;t et l&rsquo;ergonomie de nos services.
          </li>
        </ul>
        <ol start="3">
          <li>Quel cookie utilisons-nous ?</li>
        </ol>
        <p>Nous vous informons que les cookies suivants sont utilis&eacute;s sur notre site, lors de votre navigation:</p>
        <p>Actuellement aucun cookie n&rsquo;est utilis&eacute;.</p>
        <ol start="4">
          <li>Qu&rsquo;en est-il d&rsquo;un cookie &eacute;mis par un tiers ?</li>
        </ol>
        <p>
          L'&eacute;mission et l'utilisation de cookies par des tiers sont soumises aux politiques de protection de la vie priv&eacute;e de ces tiers. Nous vous informons de
          l'objet des cookies dont nous avons connaissance et des moyens dont vous disposez pour pouvoir &eacute;galement effectuer votre choix &agrave; l'&eacute;gard de ces
          cookies tiers.
        </p>
        <p>
          Nous sommes susceptibles d'inclure sur notre site des applications informatiques &eacute;manant de tiers. Tel est le cas notamment de la plateforme utilis&eacute;e pour
          les paiements en ligne (Stripe.com ou autre) et des boutons "Partager", "J'aime", issus de r&eacute;seaux sociaux tels que Facebook "Twitter", LinkedIn"etc. Le
          r&eacute;seau social fournissant un tel bouton applicatif est susceptible de vous identifier gr&acirc;ce &agrave; ce bouton, m&ecirc;me si vous n'avez pas cliqu&eacute;
          sur ce bouton lors de vos consultations sur notre site et/ou nos applications. En effet, ce type de bouton peut permettre au r&eacute;seau social concern&eacute; de
          suivre votre navigation sur notre site, du seul fait que votre compte au r&eacute;seau social concern&eacute; &eacute;tait activ&eacute; sur votre terminal (session
          ouverte) durant votre navigation sur notre site.
        </p>
        <p>
          Nous ne pouvons pas contr&ocirc;ler les processus employ&eacute;s par les r&eacute;seaux sociaux pour collecter des informations relatives &agrave; votre navigation sur
          notre site, associ&eacute;es aux donn&eacute;es personnelles dont ils disposent. Nous vous invitons &agrave; consulter les politiques de protection de la vie
          priv&eacute;e de ces r&eacute;seaux sociaux afin de prendre connaissance des finalit&eacute;s d'utilisation, notamment publicitaires, des informations de navigation
          qu'ils peuvent recueillir gr&acirc;ce &agrave; ces boutons applicatifs. Ces politiques de protection doivent notamment vous permettre d'exercer vos choix aupr&egrave;s de
          ces r&eacute;seaux sociaux, notamment en param&eacute;trant vos comptes d'utilisation de chacun de ces r&eacute;seaux.
        </p>
        <ol start="5">
          <li>Comment puis-je choisir d&rsquo;utiliser un cookie ?</li>
        </ol>
        <p>
          Il existe plusieurs possibilit&eacute;s pour g&eacute;rer les cookies. Tous les param&eacute;trages que vous pouvez entreprendre sont susceptibles de modifier votre
          navigation sur Internet et notre site ainsi que vos conditions d'acc&egrave;s &agrave; certains services n&eacute;cessitant l'utilisation de cookies. Vous pouvez faire le
          choix &agrave; tout moment d'exprimer et de modifier vos souhaits en mati&egrave;re de cookies, par les moyens d&eacute;crits ci-dessous.
        </p>
        <p>Les choix possibles sur votre navigateur :</p>
        <ul>
          <li>
            Vous pouvez configurer votre navigateur de mani&egrave;re &agrave; ce que les cookies soient enregistr&eacute;s dans votre terminal ou, au contraire, qu'ils soient
            rejet&eacute;s, soit syst&eacute;matiquement, soit selon l&rsquo;&eacute;metteur.
          </li>
          <li>
            Vous pouvez &eacute;galement configurer votre navigateur pour que l'acceptation ou le refus des cookies vous soient propos&eacute;s ponctuellement, avant qu'un cookie
            puisse &ecirc;tre enregistr&eacute; dans votre terminal.
          </li>
        </ul>
        <p>
          Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est diff&eacute;rente. Elle est d&eacute;crite dans le menu Aide de votre navigateur,
          qui vous permettra de savoir de quelle mani&egrave;re modifier vos souhaits en mati&egrave;re de cookies.
        </p>
        <p>Votre accord sur les Cookies :</p>
        <p>
          L&rsquo;enregistrement d&rsquo;un cookie dans un terminal est subordonn&eacute; &agrave; la volont&eacute; de l&rsquo;utilisateur du terminal, que celui-ci peut exprimer
          et modifier &agrave; tout moment, gratuitement &agrave; travers les choix qui lui sont offerts par son navigateur. Si vous avez accept&eacute; dans votre logiciel de
          navigation l&rsquo;enregistrement de cookies, les cookies int&eacute;gr&eacute;s dans les pages et contenus que vous avez consult&eacute; pourront &ecirc;tre
          stock&eacute;s temporairement dans un espace d&eacute;di&eacute; de votre terminal. Ils y seront lisibles uniquement par leur &eacute;metteur.
        </p>
        <p>Votre refus des Cookies :</p>
        <p>
          Si vous refusez l'enregistrement de cookies dans votre terminal, ou si vous supprimez ceux qui y sont enregistr&eacute;s, vous ne pourrez plus b&eacute;n&eacute;ficier
          d'un certain nombre de fonctionnalit&eacute;s n&eacute;cessaires pour acc&eacute;der &agrave; certains espaces du site. Tel sera le cas si vous tentez d'acc&eacute;der
          &agrave; nos contenus ou services qui n&eacute;cessitent de vous identifier. Tel serait &eacute;galement le cas lorsque nous ne pourrons pas reconna&icirc;tre, &agrave;
          des fins de compatibilit&eacute; technique, le type de navigateur utilis&eacute; par votre terminal, ses param&egrave;tres de langue et d'affichage ou le pays depuis
          lequel votre terminal semble connect&eacute; &agrave; Internet.
        </p>
        <p>
          Le cas &eacute;ch&eacute;ant, nous d&eacute;clinons toute responsabilit&eacute; pour les cons&eacute;quences li&eacute;es au fonctionnement d&eacute;grad&eacute; de nos
          services r&eacute;sultant de l'impossibilit&eacute; pour nous d'enregistrer ou de consulter les cookies n&eacute;cessaires &agrave; leur fonctionnement et que vous auriez
          refus&eacute; ou supprim&eacute;.
        </p>
        <p>
          Pour plus d&rsquo;informations concernant les cookies, et plus largement, sur vos droits et devoirs au titre de l&rsquo;usage &agrave; vos donn&eacute;es personnelles,
          nous vous invitons &agrave; consulter la loi du 28 juillet 2011 concernant les t&eacute;moins de connexion sur Internet &laquo; cookies &raquo; et vos garanties de
          transparence et d&rsquo;usage loyal de ces techniques.
        </p>
        <p>
          Si vous avez des questions, n&rsquo;h&eacute;sitez pas &agrave; nous le communiquer via le formulaire de contact avec l&rsquo;intitul&eacute; :&nbsp;
          <strong>cookies utilisateur</strong>.
        </p>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default Cgu;
