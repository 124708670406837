import React from "react";
import Translate from "react-translate-component";
import SellContainer from "../../../components/account/sell/newSellContainer";
import AccessAgency from "./access.agency";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/actions/accountActions";

class NewSell extends React.Component {
  componentDidMount() {
    this.props.getAgencyCountry();
  }

  render() {
    return (
      <div className="Agency">
        <div className="container agency-sell">
          <AccessAgency>
            <AccessAgency>
              <Translate content="sell.title" className="sell-title mb-5" component="div" />
              <SellContainer agencyCountry={this.props.agencyCountry} />
            </AccessAgency>
          </AccessAgency>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agencyCountry: state.accountReducer.agencyCountry,
});

const mapDispatchToProps = (dispatch) => ({
  getAgencyCountry: () => dispatch(actionCreator.getAgencyCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSell);
