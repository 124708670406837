import React, { useState } from "react";
import SubscriptionsList from "./components/subscriptionsList";
import SubscriptionPayment from "./components/subscriptionPayment";

function SubscriptionsForm(props) {
  const subscriptions = {
    sell: {
      type: "sell",
      twoyears: { code: "s_2y", period: "twoyears", type: "sell" },
      sixMonths: { code: "s_6", period: "sixMonths", type: "sell" },
      twelveMonths: { code: "s_12", period: "twelveMonths", type: "sell" },
    },
    rent: {
      type: "rent",
      twoyears: { code: "r_2y", period: "twoyears", type: "rent" },
      sixMonths: { code: "r_6", period: "sixMonths", type: "rent" },
      twelveMonths: { code: "r_12", period: "twelveMonths", type: "rent" },
    },
    sell_rent: {
      type: "sell_rent",
      twoyears: { code: "sr_2y", period: "twoyears", type: "sell_rent" },
      sixMonths: { code: "sr_6", period: "sixMonths", type: "sell_rent" },
      twelveMonths: { code: "sr_12", period: "twelveMonths", type: "sell_rent" },
    },
    sell_rent_media: {
      type: "sell_rent_media",
      twoyears: { code: "srm_2y", period: "twoyears", type: "sell_rent_media" },
      sixMonths: { code: "srm_6", period: "sixMonths", type: "sell_rent_media" },
      twelveMonths: { code: "srm_12", period: "twelveMonths", type: "sell_rent_media" },
    },
  };
  const [activeSubscription, setActiveSubscription] = useState(subscriptions.sell_rent_media.twelveMonths);

  const changeActiveSubscription = (type, period) => {
    setActiveSubscription(subscriptions[`${type}`][`${period}`]);
  };

  return (
    <div className="subscription">
      <SubscriptionsList changeActiveSubscription={changeActiveSubscription} activeSubscription={activeSubscription} subscriptions={subscriptions} />
      {activeSubscription && props.publicKey && <SubscriptionPayment activeSubscription={activeSubscription} agencyPanel={props.agencyPanel} publicKey={props.publicKey} />}
    </div>
  );
}

export default SubscriptionsForm;
