import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import Translate from 'react-translate-component';

function Testimonials(props) {
  return(
    <div className='container'>
      <Translate content='homepage.testimonials.title' className='title' unsafe/>
      <div className="color_border"></div>

      <div className='row mt-3'>
        <Carousel className='offset-md-1 col-md-10 col-sm-12'>

          <Carousel.Item>
            <div className="single_testimonial_area">
              <div className="testimonial_author_name">
                  <Translate content='homepage.testimonials.testimonials_1.name' component='h5' />
                  <Translate content='homepage.testimonials.testimonials_1.profession' component='h6' />
              </div>
              <div className="testimonial_text">
                <Translate content='homepage.testimonials.testimonials_1.body' component='p' />
                <div className="testimonial_author_thumb">
                    <img src="./images/testimonials/tes-3.jpg" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="single_testimonial_area">
              <div className="testimonial_author_name">
                <Translate content='homepage.testimonials.testimonials_2.name' component='h5' />
                <Translate content='homepage.testimonials.testimonials_2.profession' component='h6' />
              </div>
              <div className="testimonial_text">
                <Translate content='homepage.testimonials.testimonials_2.body' component='p' />
                <div className="testimonial_author_thumb">
                  <img src="./images/testimonials/tes-2.jpg" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="single_testimonial_area">
              <div className="testimonial_author_name">
                <Translate content='homepage.testimonials.testimonials_3.name' component='h5' />
                <Translate content='homepage.testimonials.testimonials_3.profession' component='h6' />
              </div>
              <div className="testimonial_text">
                <Translate content='homepage.testimonials.testimonials_3.body' component='p' />
                <div className="testimonial_author_thumb">
                  <img src="./images/testimonials/tes-1.jpg" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>

        </Carousel>



      </div>
    </div>

  )
}

export default Testimonials;
