import React from 'react';
import Translate from 'react-translate-component';
import Fade from 'react-reveal/Fade';


function Usp({index}) {
  return(
    <Fade top cascade>
    <div className='col-md-4 col-sm-6 col-xs-12'>
        <div className='usp-card'>

          <div className='usp-body'>
            <i className='fa fa-cogs' />
            <Translate content={`homepage.usp.usp_${index}.title`} component='h5' className='title' />
            <Translate content={`homepage.usp.usp_${index}.body`} component='p' className='body'/>
          </div>

        </div>
    </div>
    </Fade>
  )
}

export default Usp;
