const axios = require("axios");

export const conversationService = { newConversation, getConversations, getConversation };

function newConversation(values) {
  values.participants = values.participants.split(",");

  return axios.post(`/conversations/create`, values);
}

function getConversations(folderId) {
  return axios.get(`/conversations/show/${folderId}`);
}

function getConversation(conversationId) {
  return axios.get(`/conversations/show/${conversationId}/messages`);
}
