export const propertyData = [
  {
    countries: ["L", "F"],
    klass: "col-md-12",
    type: "select",
    name: "propertyType",
    choices: [
      { code: "ground" },
      { code: "detached_house" },
      { code: "semi_detached_house" },
      { code: "corner_house" },
      { code: "row_house" },
      { code: "studio" },
      { code: "apartment" },
      { code: "duplex" },
      { code: "triplex" },
      { code: "penthouse" },
      { code: "loft" },
      { code: "office" },
      { code: "commerce" },
      { code: "land" },
      { code: "garage" },
    ],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyAddressNumber",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyAddressStreet",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "propertyAddressZipCode",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "propertyAddressLocality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "propertyAddressCountry",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyNumCad",
    choices: [],
    requirements: [2, 2, 0, 0, 0, 0, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyPlaceName",
    choices: [],
    requirements: [2, 2, 0, 0, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyNature",
    choices: [],
    requirements: [2, 2, 0, 0, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyOccupation",
    choices: [],
    requirements: [2, 2, 0, 0, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyLot",
    choices: [],
    requirements: [2, 2, 0, 0, 0, 0, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyAreTer",
    choices: [],
    requirements: [0, 0, 2, 2, 0, 0, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyNrbBedrom",
    choices: [],
    requirements: [0, 0, 2, 2, 2, 0, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertySurHab",
    choices: [],
    requirements: [0, 0, 2, 2, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyBasement",
    choices: [],
    requirements: [0, 0, 2, 2, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyTerBal",
    choices: [],
    requirements: [0, 0, 2, 2, 2, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyCpe",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyEmpInt",
    choices: [],
    requirements: [0, 0, 2, 2, 0, 2, 0],
    required: false,
    condition: false,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "propertyEmpExt",
    choices: [],
    requirements: [0, 0, 2, 2, 0, 2, 0],
    required: false,
    condition: false,
  },
];

export const sellerData = [
  {
    countries: ["L", "F"],
    klass: "col-md-12 text-center no-bg",
    type: "radio",
    name: "sellerType",
    choices: [{ code: "privateSeller" }, { code: "CompanySeller" }],
    requirements: [],
    required: false,
    condition: false,
    labelType: true,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-12 no-bg",
    type: "radio",
    name: "sellerLabel",
    choices: [{ code: "mr" }, { code: "mrs" }],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerFirstName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerLastName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },

  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerAddressNumber",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerAddressStreet",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerAddressZipCode",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerAddressLocality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerAddressCountry",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerPhone",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerEMail",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerIdNum",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerMatSecSoc",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "select",
    name: "sellerCivilStatus",
    choices: [{ code: "single" }, { code: "married" }, { code: "divorced" }, { code: "widowed" }],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerProf",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "sellerBirthDate",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerNationality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerCompanyName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerCompanyForm",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerLegalRep",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerRepAddressNumber",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerRepAddressStreet",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerRepAddressZipCode",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerRepAddressLocality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "sellerRepAddressCountry",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerNumRc",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerNumTva",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 0, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "sellerRepEmail",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 0, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
];

export const buyerData = [
  {
    countries: ["L", "F"],
    klass: "col-md-12 text-center no-bg",
    type: "radio",
    name: "buyerType",
    choices: [{ code: "privateSeller" }, { code: "CompanySeller" }],
    requirements: [],
    required: false,
    condition: false,
    labelType: true,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-12 no-bg",
    type: "radio",
    name: "buyerLabel",
    choices: [{ code: "mr" }, { code: "mrs" }],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerFirstName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerLastName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },

  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerAddressNumber",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerAddressStreet",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerAddressZipCode",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerAddressLocality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerAddressCountry",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerPhone",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerEMail",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerIdNum",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerMatSecSoc",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "select",
    name: "buyerCivilStatus",
    choices: [{ code: "single" }, { code: "married" }, { code: "divorced" }, { code: "widowed" }],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerProf",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "buyerBirthDate",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerNationality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "privateSeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerCompanyName",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerCompanyForm",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerLegalRep",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerRepAddressNumber",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerRepAddressStreet",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerRepAddressZipCode",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerRepAddressLocality",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-4",
    type: "text",
    name: "buyerRepAddressCountry",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerNumRc",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 2, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerNumTva",
    choices: [],
    requirements: [2, 2, 2, 2, 2, 0, 2],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "buyerRepEmail",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 0, 0],
    required: false,
    condition: true,
    conditionValue: "CompanySeller",
  },
];

export const actData = [
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "number",
    name: "actSellingPrice",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 2],
    required: false,
    condition: "",
    labelType: true,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actSellingPriceLetters",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: false,
    labelType: true,
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "number",
    name: "actCharge",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actChargeLettres",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "actEffectiveDate",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "number",
    name: "actRentCommission",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 2],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actBankguaranteeType",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "number",
    name: "actBankguaranteeMonths",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "number",
    name: "actBankguaranteeAmount",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actBankguaranteeAmountLetters",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actBeneficiary",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actBeneficiaryIban",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actBeneficiaryBic",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actEmail",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 0, 0],
    required: false,
    condition: "",
  },
];

export const deletedData = [
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actNotaryLastName",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "text",
    name: "actLocality",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 2, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "actDateAtTheLeast",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 0, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "actDate",
    choices: [],
    requirements: [0, 0, 0, 0, 0, 2, 2],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "date",
    name: "actDeadlineBank",
    choices: [],
    requirements: [0, 0, 0, 0, 2, 0, 0],
    required: false,
    condition: "",
  },
  {
    countries: ["L", "F"],
    klass: "col-md-6",
    type: "radio",
    name: "actAcquisitionResale",
    choices: [{ code: "yes" }, { code: "non" }],
    requirements: [0, 0, 0, 0, 2, 0, 0],
    required: false,
    condition: "",
  },
];
