import { conversationService } from "../services/conversationService";
import Notifications from "react-notification-system-redux";
import counterpart from "counterpart";

const flash = (name) => {
  return counterpart.translate(name);
};

export function newConversation(values) {
  return (dispatch) => {
    conversationService.newConversation(values).then(
      (response) => {
        dispatch(getConversations(values.folderId));
      },
      (error) => {
        console.log("not ok");
      }
    );
  };
}

export function getConversations(folderId) {
  return (dispatch) => {
    conversationService.getConversations(folderId).then(
      (response) => {
        dispatch(display_conversations(response.data.conversations));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function getConversation(conversationId, folderId) {
  return (dispatch) => {
    conversationService.getConversation(conversationId).then(
      (response) => {
        console.log(response.data);

        dispatch(display_conversation(response.data.conversationMessages));
        dispatch(getConversations(folderId));
      },
      (error) => {
        dispatch(Notifications.error({ message: flash("flash.error.general") }));
      }
    );
  };
}

export function display_conversations(conversations) {
  return {
    type: "GET_CONVERSATIONS",
    conversations,
  };
}

export function display_conversation(conversation) {
  return {
    type: "GET_CONVERSATION",
    conversation,
  };
}
