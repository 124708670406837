import React, { useState } from "react";
import Translate from "react-translate-component";
import { FilePond, registerPlugin, setOptions } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import counterpart from "counterpart";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);

function NewDocument({ folder, changeUid }) {
  const [type, setType] = useState();
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [fileType, setFileType] = useState("");
  const [files, setFiles] = useState([]);

  const addOrRemoveSeller = (id) => {
    if (sellers.includes(id)) {
      setSellers(sellers.filter((s_id) => s_id !== id));
    } else {
      setSellers(sellers.concat(id));
    }
  };
  const addOrRemoveBuyer = (id) => {
    if (buyers.includes(id)) {
      setBuyers(buyers.filter((b_id) => b_id !== id));
    } else {
      setBuyers(buyers.concat(id));
    }
  };
  const changeType = (type) => {
    setType(type);
    setFileType("");
    setFiles([]);
  };
  const changeFileType = (ftype) => {
    setFileType(ftype);
  };
  const can_choose_documents = ["transaction", "bank"].includes(type) || (type === "buyer" && buyers.length > 0) || (type === "seller" && sellers.length > 0);
  const can_add_files = can_choose_documents && fileType !== "";

  return (
    <div className="nav-content new-documents">
      <div className="row">
        <div className="col-12 mb-3">
          <Translate content="forms.folder.documents.new.title" className="arima" component="h4" />
        </div>
        <div className="col-12 mb-5">
          <Translate content="forms.folder.documents.new.type.label" className="arima" component="h5" />
          <div className="row">
            <div className="col-3 hover" onClick={() => changeType("seller")}>
              <div className={`selection uni ${type === "seller" ? "selected" : ""}`}></div>
              <Translate content={`forms.folder.documents.new.type.${folder.type}.seller`} />
            </div>
            <div className="col-3 hover" onClick={() => changeType("buyer")}>
              <div className={`selection uni ${type === "buyer" ? "selected" : ""}`}></div>
              <Translate content={`forms.folder.documents.new.type.${folder.type}.buyer`} />
            </div>

            <div className="col-3 hover" onClick={() => changeType("transaction")}>
              <div className={`selection uni ${type === "transaction" ? "selected" : ""}`}></div>
              <Translate content={`forms.folder.documents.new.type.${folder.type}.transaction`} />
            </div>
            <div className="col-3 hover" onClick={() => changeType("bank")}>
              <div className={`selection uni ${type === "bank" ? "selected" : ""}`}></div>
              <Translate content={`forms.folder.documents.new.type.${folder.type}.bank`} />
            </div>
          </div>
        </div>

        {["seller", "buyer"].includes(type) && (
          <div className="col-12 mb-5">
            <Translate content={`forms.folder.documents.new.select.${type}`} className="arima" component="h5" />
            {type === "seller" && (
              <div className="row">
                {folder.sellers.map((seller) => (
                  <div className="col-4 hover" onClick={() => addOrRemoveSeller(seller.id)} key={seller.id}>
                    <div className={`selection ${sellers.includes(seller.id) ? "selected" : ""}`}></div>
                    {seller.sellerType === "privateSeller" && (
                      <span>
                        {seller.sellerLastName} {seller.sellerFirstName}
                      </span>
                    )}
                    {seller.sellerType === "CompanySeller" && <span>{seller.sellerCompanyName}</span>}
                  </div>
                ))}
              </div>
            )}
            {type === "buyer" && (
              <div className="row">
                {folder.buyers.map((buyer) => (
                  <div className="col-4 hover" onClick={() => addOrRemoveBuyer(buyer.id)} key={buyer.id}>
                    <div className={`selection ${buyers.includes(buyer.id) ? "selected" : ""}`}></div>
                    {buyer.buyerType === "privateSeller" && (
                      <span>
                        {buyer.buyerLastName} {buyer.buyerFirstName}
                      </span>
                    )}
                    {buyer.buyerType === "CompanySeller" && <span>{buyer.buyerCompanyName}</span>}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {can_choose_documents && (
          <div className="col-12 mb-5">
            <Translate content="forms.folder.documents.new.choose_document" className="arima" component="h5" />
            <div className="row">
              {["seller", "buyer"].includes(type) && (
                <>
                  <div className="col-3 hover" onClick={() => changeFileType("rgpd")}>
                    <div className={`selection uni ${fileType === "rgpd" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.rgpd" />
                  </div>

                  {type === "seller" && (
                    <div className="col-3 hover" onClick={() => changeFileType("pb")}>
                      <div className={`selection uni ${fileType === "pb" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.pb" />
                    </div>
                  )}

                  {type === "buyer" && (
                    <div className="col-3 hover" onClick={() => changeFileType("pf")}>
                      <div className={`selection uni ${fileType === "pf" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.pf" />
                    </div>
                  )}
                  <div className="col-3 hover" onClick={() => changeFileType("ar")}>
                    <div className={`selection uni ${fileType === "ar" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.ar" />
                  </div>
                  <div className="col-3 hover" onClick={() => changeFileType("cid")}>
                    <div className={`selection uni ${fileType === "cid" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.cid" />
                  </div>
                  <div className="col-3 hover" onClick={() => changeFileType("css")}>
                    <div className={`selection uni ${fileType === "css" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.css" />
                  </div>
                </>
              )}
              {type === "transaction" && (
                <>
                  <div className="col-4 hover" onClick={() => changeFileType("ma")}>
                    <div className={`selection uni ${fileType === "ma" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.ma" />
                  </div>

                  {folder.type === "sell" && (
                    <div className="col-4 hover" onClick={() => changeFileType("co")}>
                      <div className={`selection uni ${fileType === "co" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.co" />
                    </div>
                  )}

                  {folder.type === "rent" && (
                    <div className="col-4 hover" onClick={() => changeFileType("ba")}>
                      <div className={`selection uni ${fileType === "ba" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.ba" />
                    </div>
                  )}
                  {folder.type === "rent" && (
                    <div className="col-4 hover" onClick={() => changeFileType("bo")}>
                      <div className={`selection uni ${fileType === "bo" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.bo" />
                    </div>
                  )}

                  <div className="col-4 hover" onClick={() => changeFileType("fa")}>
                    <div className={`selection uni ${fileType === "fa" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.fa" />
                  </div>
                  {folder.type === "sell" && (
                    <div className="col-4 hover" onClick={() => changeFileType("at")}>
                      <div className={`selection uni ${fileType === "at" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.at" />
                    </div>
                  )}
                  {folder.type === "sell" && (
                    <div className="col-4 hover" onClick={() => changeFileType("ac")}>
                      <div className={`selection uni ${fileType === "ac" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.ac" />
                    </div>
                  )}
                  {folder.type === "sell" && (
                    <div className="col-4 hover" onClick={() => changeFileType("cc")}>
                      <div className={`selection uni ${fileType === "cc" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.cc" />
                    </div>
                  )}
                </>
              )}

              {type === "bank" && (
                <>
                  <div className="col-4 hover" onClick={() => changeFileType("ap")}>
                    <div className={`selection uni ${fileType === "ap" ? "selected" : ""}`}></div>
                    <Translate content="forms.folder.documents.new.file.ap" />
                  </div>
                  {folder.type === "sell" && (
                    <div className="col-4 hover" onClick={() => changeFileType("dp")}>
                      <div className={`selection uni ${fileType === "dp" ? "selected" : ""}`}></div>
                      <Translate content="forms.folder.documents.new.file.dp" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {can_add_files && (
          <div className="col-12 mb-5">
            <Translate content="forms.folder.documents.new.add_file" className="arima" component="h5" />
            <div className="row">
              <div className="col-12">
                <FilePond
                  labelIdle={counterpart.translate("filePond.labelIdle")}
                  files={files}
                  allowMultiple={true}
                  maxFiles={3}
                  maxFileSize="5MB"
                  oninit={() => console.log("inited")}
                  onupdatefiles={(fileItems) => {
                    if (!fileItems.some((fileItem) => typeof fileItem.source.name == "string")) return;
                    setFiles({
                      files: fileItems.map((fileItem) => (fileItem.file.filename === null ? null : fileItem.file)),
                    });
                  }}
                  onprocessfiles={() => {
                    setTimeout(function () {
                      setFileType("");
                      setFiles([]);
                      changeUid(Math.random());
                    }, 2000);
                  }}
                  server={{
                    url: "/folderUploads",
                    process: {
                      url: "/process",
                      method: "POST",
                      headers: {
                        Authorization: `${localStorage.getItem("access_token")}`,
                      },
                      withCredentials: false,
                      onload: (response) => {},
                      onerror: (response) => response.data,
                      ondata: (formData) => {
                        formData.append("documentTarget", type);
                        formData.append("documentSellers", sellers);
                        formData.append("documentBuyers", buyers);
                        formData.append("documentType", fileType);
                        formData.append("folderId", folder.id);
                        return formData;
                      },
                    },
                    restore: null,
                    load: null,
                    fetch: null,
                    revert: null,
                  }}
                ></FilePond>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewDocument;
