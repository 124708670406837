import React from "react";
import Translate from "react-translate-component";
import Loader from "react-loader-spinner";
import AgencyButton from "./agencyButton";
import { connect } from "react-redux";
import * as actionACreator from "../../../store/actions/agencyActions";
import { Link } from "react-router-dom";

class GenerationButtons extends React.Component {
  componentDidMount() {
    this.props.getCurrentOrder();
  }

  render() {
    const { generateDoc, loading, type, currentOrder, documentLanguage, loadingOrder } = this.props;
    const changeLanguage = (lang) => {
      this.props.changeLanguage(lang);
    };

    return (
      <>
        {(currentOrder === null || (!loadingOrder && currentOrder && !currentOrder[type])) && (
          <div className="bg-white  py-3 mb-3">
            <div className="mb-3 custom-access-information">
              <Translate content="access.folder.no_subscription_placeholder" component="h5" />
              <Translate content="access.folder.no_subscription_action" component="h6" />

              <Link to="/agency/Subscriptions">
                <Translate content="access.folder.no_subscription_btn" />
              </Link>
            </div>
          </div>
        )}
        {currentOrder && currentOrder[type] && (
          <>
            <div className="row col-12">
              <Translate content="forms.folder.generation.title" component="h4" />
              <Loader type="ThreeDots" color="#0575e6" height={25} width={50} visible={loading} className="ml-2" />
              <div className="inline-language">
                <label className="radio-container">
                  <span>FR</span>
                  <input type="radio" name="documentLanguage" defaultValue="fr" defaultChecked={"fr" === documentLanguage} onChange={() => changeLanguage("fr")} />
                  <span className={`checkmark ${"fr" === documentLanguage ? "checked" : "unchecked"}`}></span>
                </label>

                <label className="radio-container">
                  <span>EN</span>
                  <input type="radio" name="documentLanguage" defaultValue="fr" defaultChecked={"en" === documentLanguage} onChange={() => changeLanguage("en")} />
                  <span className={`checkmark ${"en" === documentLanguage ? "checked" : "unchecked"}`}></span>
                </label>

                <label className="radio-container">
                  <span>DE</span>
                  <input type="radio" name="documentLanguage" defaultValue="fr" defaultChecked={"de" === documentLanguage} onChange={() => changeLanguage("de")} />
                  <span className={`checkmark ${"de" === documentLanguage ? "checked" : "unchecked"}`}></span>
                </label>
              </div>
            </div>

            {!currentOrder.media && (
              <div className="bg-white py-3 mb-3">
                <div className="custom-access-information">
                  <Translate content="forms.folder.generation.upgrade_placeholder" component="h6" />
                  <Link to="/agency/subscriptions">
                    <Translate content="forms.folder.generation.upgrade_action_call" />
                  </Link>
                </div>
              </div>
            )}

            <div className="row mb-4">
              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(0)}>
                  <Translate content={`documents.${type}.pb`} />
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(1)}>
                  <Translate content={`documents.${type}.pf`} />
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(2)}>
                  <Translate content={`documents.${type}.prv`} />
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(3)}>
                  <Translate content={`documents.${type}.ara`} />
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(4)}>
                  <Translate content={`documents.${type}.c`} />
                </div>
              </div>
              {type === "sell" && (
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="generate-btn" onClick={() => generateDoc(5)}>
                    <Translate content={`documents.${type}.an`} />
                  </div>
                </div>
              )}
              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(6)}>
                  <Translate content={`documents.${type}.f`} />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(7)}>
                  <Translate content={`documents.${type}.rgpdp`} />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-6">
                <div className="generate-btn" onClick={() => generateDoc(8)}>
                  <Translate content={`documents.${type}.rgpdal`} />
                </div>
              </div>

              <AgencyButton lang={documentLanguage} name="file_one.docx" fileType={type} index={9} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_two.docx" fileType={type} index={10} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_three.docx" fileType={type} index={11} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_four.docx" fileType={type} index={12} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_five.docx" fileType={type} index={13} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_six.docx" fileType={type} index={14} generateDoc={generateDoc} />
              <AgencyButton lang={documentLanguage} name="file_seven.docx" fileType={type} index={15} generateDoc={generateDoc} />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentOrder: state.agencyReducer.currentOrder,
  loadingOrder: state.agencyReducer.loadingOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentOrder: () => dispatch(actionACreator.getCurrentOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerationButtons);
