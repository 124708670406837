const initialState = { conversations: [], conversation: false };

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONVERSATIONS":
      return {
        ...state,
        conversations: action.conversations,
      };
    case "GET_CONVERSATION":
      return {
        ...state,
        conversation: action.conversation,
      };
    default:
      return state;
  }
};

export default conversationReducer;
