const axios = require("axios");
export const stripeService = { createToken, createOrder, validateSubsciption, getPublicKey };

export function createToken(stripe, name) {
  return stripe.createToken({ type: "card", name: name });
}

export function createOrder(data) {
  return axios.post(`/subscriptions/subscribe`, data);
}
export function getPublicKey() {
  return axios.get(`/StripeInfo/public_key`);
}

export function validateSubsciption(subscriptionId) {
  const data = {
    subscriptionId: subscriptionId,
  };
  return axios.put(`/subscriptions/validate_subscription`, data);
}
