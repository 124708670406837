import React from "react";
import { Link } from "react-router-dom";
import Authentification from "./authentification";

function Header(props) {
  return (
    <div className="side-bar">
      <Link className="logo" to="/">
        <span className="small-logo"></span>
        <span className="large-logo"></span>
      </Link>

      <div className="navigation-menu">
        <Authentification />
      </div>
    </div>
  );
}

export default Header;
