import React from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";
import * as actionACreator from "../../../store/actions/agencyActions";

class AccessAgencyMedia extends React.Component {
  componentDidMount() {
    this.props.getAgency();
    this.props.getCurrentOrder();
  }
  submitAction = (values) => {};

  render() {
    return (
      <div>
        {!this.props.loadingAgency && (!this.props.agency || this.props.agency.id === 0) && (
          <div className="bg-white py-3 mb-3">
            <div className="custom-access-information">
              <Translate content="access.folder.no_agency_placeholder" component="h5" />
              <Translate content="access.folder.no_agency_action" component="h6" />

              <Link to="/agency/Dashboard">
                <Translate content="access.folder.no_agency_btn" />
              </Link>
            </div>
          </div>
        )}

        {this.props.agency && this.props.agency.id !== 0 && this.props.currentOrder != null && !this.props.currentOrder.media && (
          <div className="bg-white py-3 mb-3">
            <div className="custom-access-information">
              <Translate content="access.folder.no_agency_media" component="h6" />

              <Link to="/agency/subscriptions">
                <Translate content="access.folder.no_agency_media_btn" />
              </Link>
            </div>
          </div>
        )}
        {this.props.agency && this.props.agency.id !== 0 && this.props.currentOrder && this.props.currentOrder.media && this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agency: state.agencyReducer.agency,
  loadingAgency: state.agencyReducer.loadingAgency,
  currentOrder: state.agencyReducer.currentOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getAgency: () => dispatch(actionACreator.getAgency()),
  getCurrentOrder: () => dispatch(actionACreator.getCurrentOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessAgencyMedia);
