import React, { useState } from "react";
import useForm from "react-hook-form";
import useAxios from "axios-hooks";

import { Modal } from "react-bootstrap";
import Translate from "react-translate-component";
import Loader from "react-loader-spinner";

function File({ lang, name, fileType }) {
  const { handleSubmit, register, errors } = useForm();
  const [values, setValues] = useState({ [name]: "" });
  const [showModal, setShowModal] = useState(false);
  const [linkRef, setLinkRef] = useState(React.createRef());
  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    setValues({ ...values, [name]: file });
  };
  const filesName = {
    sell: {
      pb: "provenance_du_bien.docx",
      pf: "provenance_des_fonds.docx",
      prv: "analyse_risque_vendeur.docx",
      ara: "analyse_risque_acheteur.docx",
      c: "compromis.docx",
      an: "attestation_notaire.docx",
      f: "facture.xlsx",
      rgpdp: "Formulaire_RGPD_proprietaire.docx",
      rgpdal: "Formulaire_RGPD_acheteur.docx",
    },
    rent: {
      pb: "provenance_du_bien.docx",
      pf: "provenance_des_fonds.docx",
      prv: "analyse_risque_proprietaire.docx",
      ara: "analyse_risque_locataire.docx",
      c: "bail.docx",
      an: "attestation_notaire.docx",
      f: "facture.xlsx",
      rgpdp: "Formulaire_RGPD_proprietaire.docx",
      rgpdal: "Formulaire_RGPD_locataire.docx",
    },
  };
  const bodyData = { fileName: filesName[fileType][name], fileLanguage: lang, fileType: fileType };
  const [{ data, loading }, refetch] = useAxios({
    url: "/customFiles/check_file",
    method: "POST",
    data: bodyData,
  });

  const [{ agencyFileData, agencyFileLoading }, downloadAgencyFile] = useAxios(
    {
      method: "POST",
      data: bodyData,
      responseType: "blob",
    },
    { manual: true, responseType: "blob" }
  );
  const download = (id) => {
    downloadAgencyFile({
      url: "/customFiles/download_file/" + id,
    }).then((response) => {
      const href = window.URL.createObjectURL(response.data);
      const a = linkRef.current;
      a.download = filesName[fileType][name];
      a.href = href;
      a.click();
      a.href = "";
    });
  };

  const [{ loading: uploadLoading }, executeUpload] = useAxios(
    {
      url: "/customFiles/create",
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
    },
    { manual: true }
  );

  const [{ loading: deleteLoading }, executedelete] = useAxios(
    {
      url: "/customFiles/delete",
      method: "POST",
    },
    { manual: true }
  );

  const handleDeleteFile = () => {
    executedelete({
      data: bodyData,
    }).then((response) => {
      refetch();
      setValues({ [name]: "" });
    });
    setShowModal(false);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", values[name]);
    formData.append("fileName", filesName[fileType][name]);
    formData.append("fileLanguage", lang);
    formData.append("fileType", fileType);
    executeUpload({
      data: formData,
    }).then((response) => {
      refetch();
      setValues({ [name]: "" });
    });
  };

  return (
    <div className="col-md-4 col-sm-6 col-xs-12">
      <a ref={linkRef} />
      <form className={`file-uploader ${uploadLoading ? "upload-loading" : ""}`} onSubmit={handleSubmit(onSubmit)}>
        {!loading && data && data.fileResponse.fileExists && <i className="fa fa-paperclip"></i>}

        <div className="file-uploader__message-area">
          <Translate content={`documents.${fileType}.${name}`} component="p" />
        </div>
        <div className="file-chooser">
          {!uploadLoading && (
            <label>
              {values[name] === "" && <Translate content="custom_files.choose_file" />}
              {values[name] !== "" && <span className="valid">{values[name].name}</span>}
              <input className="file-chooser__input" type="file" ref={register({ required: true })} name={name} onChange={(e) => fileChangedHandler(e)} />
              {errors[name] && <Translate content="custom_files.choose_file" component="i" className="error" />}
            </label>
          )}

          {<Loader type="ThreeDots" color="#0575e6" height={50} width={100} visible={uploadLoading} className="upload-spinner" />}
        </div>

        {!loading && data && !data.fileResponse.fileExists && (
          <>
            <input className="file-uploader__submit-button with-default" type="submit" value="Upload" />
            <span className="default-show" onClick={() => download("default")}>
              <i className="fa fa-eye"></i>
            </span>
          </>
        )}
        {!loading && data && data.fileResponse.fileExists && (
          <div className="file-uploader__actions-buttons">
            <span className="edit" onClick={handleSubmit(onSubmit)}>
              <i className="fa fa-edit"></i>
            </span>
            <span className="del" onClick={() => setShowModal(true)}>
              <i className="fa fa-trash"></i>
            </span>
            <span className="show" onClick={() => download("agency")}>
              <i className="fa fa-eye"></i>
            </span>
          </div>
        )}

        {loading && (
          <div className="loading">
            <Loader type="ThreeDots" color="#0575e6" height={25} width={50} visible={true} className="spinner" />
          </div>
        )}
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate content="custom_files.modal.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-2">
              <Translate content="custom_files.modal.placeholder" component="p" />
            </div>

            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => handleDeleteFile()}>
                <Translate content="custom_files.modal.delete" />
              </button>
            </div>
            <div className="col-6 mb-2">
              <button className="direction float-right" onClick={() => setShowModal(false)}>
                <Translate content="custom_files.modal.close" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default File;
